import React from 'react'
import {Row ,Col,Progress } from 'antd'
import echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/pie'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import styles from './style.module.css'

class TestDataStatistic extends React.Component{

  componentDidMount(){
    this.initChart()
  }
  initChart=()=>{
    let bar1 = echarts.init(document.getElementById('bar1'));
    let bar2 = echarts.init(document.getElementById('bar2'));
    let bar3 = echarts.init(document.getElementById('bar3'));
    let pie1 =echarts.init(document.getElementById('pie1'));
    let pie2 =echarts.init(document.getElementById('pie2'));
    let line1 =echarts.init(document.getElementById('line1'));
    if (bar1) {
      // 绘制图表
      bar1.setOption({
        title:{
          text:'测试项目完成率',
          textStyle:{
            color:'#FFC141',
            fontSize:18
          },
          width:300,
          height:55
        },
        xAxis: {
          name:'项目',
          nameTextStyle:{color:'white'},
          type: 'category',
          data: ['超车', '并道', '跟车', '障碍物', '紧急制动', '联网通讯', '停车'],
          axisLabel:{
            textStyle:{
              color:'#AFBDD1'
            }
          }
        },
        yAxis: {
          name:'%',
          nameTextStyle:{color:'white'},
          type: 'value',
          axisLabel:{
            textStyle:{
              color:'#AFBDD1'
            }
          }
        },
        series: [{
          color:'#03ddfd',
          data: [20, 30, 30, 60, 70, 100, 50],
          type: 'bar',
          barWidth : 20,

        }]
      });
      window.onresize = function(){
        bar1.resize();

      }
    }
    if (bar2) {
      // 绘制图表
      bar2.setOption({
        title:{
          text:'测试项目通过率',
          textStyle:{
            color:'#FFC141',
            fontSize:18
          },
          width:300,
          height:55
        },
        xAxis: {
          name:'项目',
          nameTextStyle:{color:'white'},
          type: 'category',
          data: ['超车', '并道', '跟车', '障碍物', '紧急制动', '联网通讯', '停车'],
          axisLabel:{
            textStyle:{
              color:'#AFBDD1'
            }
          }
        },
        yAxis: {
          name:'%',
          nameTextStyle:{color:'white'},
          type: 'value',
          axisLabel:{
            textStyle:{
              color:'#AFBDD1'
            }
          }
        },
        series: [{
          color:'#11a18d',
          data: [52, 30, 30, 60, 42, 100, 50],
          type: 'bar',
          barWidth : 20
        }]
      });
      window.onresize = function(){
        bar2.resize();

      }
    }

    if (pie1) {
      // 绘制图表
      pie1.setOption({
        title:{
          text:'所有测试车辆',
          textStyle:{
            color:'white',
            fontSize:18
          },
          left: 'center'
        },
        color:['#03cbc3','#13717f'],
        tooltip: {
          trigger: 'item',
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        legend: {
          bottom: 10,
          left: 'left',
          data:['商用车','乘用车'],
          orient:'horizontal',
          textStyle:{
            color:'#ccc',
            fontSize:16
          }
        },
        series: [
          {
            name:'访问来源',
            type:'pie',
            radius: ['58%', '42%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: true,
                formatter: '{b}: {c}({d}%)'
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '30',
                  fontWeight: 'bold'
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data:[
              {value:84, name:'商用车'},
              {value:62, name:'乘用车'}
            ]
          }
        ]
      });
      window.onresize = function(){
        pie1.resize();

      }
    }


    if (pie2) {
      // 绘制图表
      pie2.setOption({
        tooltip: {
          trigger: 'item',
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        color:['#34aa44','#ffce00','#483ac4','#03ddfd'],
        legend: {
          orient:'horizontal',
          bottom: 1,
          left: 'left',
          data:['无人小巴','无人大巴','无人卡车','小型乘用车'],
          textStyle:{
            color:'#ccc',
            fontSize:16
          }
        },
        series: [
          {
            name:'访问来源',
            type:'pie',
            radius: ['58%', '42%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: true,
                formatter: '{b}: {c}({d}%)'
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '30',
                  fontWeight: 'bold'
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data:[
              {value:27, name:'无人小巴'},
              {value:14, name:'无人大巴'},
              {value:4, name:'无人卡车'},
              {value:13, name:'小型乘用车'}
            ]
          }
        ]
      });
      window.onresize = function(){
        pie2.resize();

      }
    }


    if (line1) {
      // 绘制图表
      line1.setOption({
        title:{
          text:'测试时段分析',
          textStyle:{
            color:'#FFC141',
            fontSize:18
          },
          width:300,
          height:55
        },
        xAxis: {
          name:'日期',
          nameTextStyle:{color:'white'},
          type: 'category',
          boundaryGap: false,
          data: ['0', '2', '4', '6', '8', '10', '12','14','16','18','20','22','24','26','28','30'],
          axisLabel:{
            textStyle:{
              color:'#AFBDD1'
            }
          }
        },
        yAxis: {
          name:'辆',
          nameTextStyle:{color:'white'},
          type: 'value',
          axisLabel:{
            textStyle:{
              color:'#AFBDD1'
            }
          }
        },
        series: [{
          data: [2, 4, 6, 34, 32, 12, 12,12,34,45,24,25,24,78,24,25],
          type: 'line',
          areaStyle: {color: '#05c9e6'},
          smooth: true,
          itemStyle: {
            normal: {
              lineStyle: {
                color: '#05c9e6'
              }
            }
          },
        }]
      });
      window.onresize = function(){
        line1.resize();

      }
    }

    if (bar3) {
      // 绘制图表
      bar3.setOption({
        title:{
          text:'累计测试车辆里程分布',
          textStyle:{
            color:'#FFC141',
            fontSize:18
          },
          width:300,
          height:55
        },
        xAxis: {
          nameTextStyle:{color:'white'},
          name: "里程",
          type: 'category',
          data: ['超车', '并道', '跟车', '障碍物', '紧急制动', '联网通讯', '停车'],
          axisLabel:{
            textStyle:{
              color:'#AFBDD1'
            }
          }
        },
        yAxis: {
          nameTextStyle:{color:'white'},
          name: "辆",
          type: 'value',
          axisLabel:{
            textStyle:{
              color:'#AFBDD1'
            }
          }
        },
        series: [{
          color:'#13717F',
          data: [120, 200, 150, 80, 70, 110, 130],
          type: 'bar',
          barWidth : 20
        }]
      });
      window.onresize = function(){
        bar3.resize();

      }
    }


  }

  render(){
    return(
      <div style={{background:'#111111',marginLeft:'10px'}}>
        <Row gutter={10}>
          <Col span={16}>
            <Row gutter={10}>
              <Col span={12}>
                <div className={styles.chartCon}>
                  <div id="bar1" style={{minHeight:'300px'}}>

                  </div>
                </div>
                <div className={styles.chartCon} style={{marginTop:'10px'}}>
                  <div id="bar2" style={{minHeight:'300px'}}>

                  </div>
                </div>

              </Col>
              <Col span={12}>
                <div className={styles.chartCon}>
                  <Row >
                    <Col span={24}>
                      <p className={styles.chartTitle}>测试车辆类型</p>
                      <div id="pie1" style={{minHeight:'300px'}}>

                      </div>
                    </Col>
                    <Col span={24}>
                      <div id="pie2" style={{minHeight:'300px'}}>

                      </div>
                    </Col>
                  </Row>

                </div>

              </Col>
              <Col span={24}  style={{marginTop:'10px'}}>
                <div className={styles.chartCon}>
                <div id="line1" style={{minHeight:'300px'}}>


               </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row gutter={10}>
              <Col span={24}>
                <div className={styles.chartCon}>
                <div id="bar3" style={{minHeight:'300px'}}>

                </div>
                </div>
              </Col>
              <Col span={24} style={{marginTop:'10px'}}>
                <div className={styles.chartCon}>
                  <p className={styles.chartTitle}>场景通过率Top10</p>
                  <table className={styles.statisticTable}>
                    <thead>
                      <tr><th>场景名称</th><th>通过率</th></tr>
                    </thead>
                    <tbody>
                     <tr><td style={{width:'20%'}}>长直路段车车通讯		</td><td style={{width:'33%'}}><Progress strokeLinecap="square" type="line" percent={99}  strokeWidth="15px" /> </td></tr>
                     <tr><td style={{width:'20%'}}>长直路段车路通讯			</td><td style={{width:'33%'}}><Progress strokeLinecap="square" type="line" percent={99}  strokeWidth="15px" /> </td></tr>
                     <tr><td style={{width:'20%'}}>靠路边应急停车			</td><td style={{width:'33%'}}><Progress strokeLinecap="square" type="line" percent={99}  strokeWidth="15px" /> </td></tr>
                     <tr><td style={{width:'20%'}}>停车让行标志	</td><td style={{width:'33%'}}><Progress strokeLinecap="square" type="line" percent={99}  strokeWidth="15px" /> </td></tr>
                     <tr><td style={{width:'20%'}}>限速标志识别及响应	</td><td style={{width:'33%'}}><Progress strokeLinecap="square" type="line" percent={84}  strokeWidth="15px" /> </td></tr>
                     <tr><td style={{width:'20%'}}>行人横穿马路避让		</td><td style={{width:'33%'}}><Progress strokeLinecap="square" type="line" percent={86}  strokeWidth="15px" /> </td></tr>
                     <tr><td style={{width:'20%'}}>稳定跟车行驶		</td><td style={{width:'33%'}}><Progress strokeLinecap="square" type="line" percent={75}  strokeWidth="15px" /> </td></tr>
                     <tr><td style={{width:'20%'}}>邻近车道无车并道		</td><td style={{width:'33%'}}><Progress strokeLinecap="square" type="line" percent={90}  strokeWidth="15px" /> </td></tr>
                     <tr><td style={{width:'20%'}}>前车静止自动紧急制动	</td><td style={{width:'33%'}}><Progress strokeLinecap="square" type="line" percent={95}  strokeWidth="15px" /> </td></tr>
                     <tr><td style={{width:'20%'}}>行人横穿自动紧急制动	</td><td style={{width:'33%'}}><Progress strokeLinecap="square" type="line" percent={86}  strokeWidth="15px" /> </td></tr>
                    </tbody>
                  </table>
                </div>

              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }
}
export default  TestDataStatistic
