import { post } from "../../api/request.js";
import * as types from './actionTypes'

//获取测试场基本信息 /testSite/testField/getTestField
export const getSiteInfo = (id) => dispatch => {
    dispatch({
        type:types.CLEAR_SITE_INFO
    })
    post('/testSite/testField/getTestField', { id }).then(res => {
        if (res.status === 200 && res.data.code === 200) {
            dispatch({
                type: types.GET_SITE_INFO,
                data:res.data.data
            })
        }

    })
}

//获取测试场所有标志物信息 /testSite/testField/getAllTestMarker
export const getAllMarkerInfo = () => dispatch => {
    post('/testSite/testField/getAllTestMarker',{}).then(res => {
        if (res.status === 200 && res.data.code === 200) {
            dispatch({
                type:types.GET_ALL_MARKER_INFO,
                data:res.data.data
            })
        }
    })
}