import React from 'react'
import {Table,Input,Button,Checkbox,Row,Col,Select,message,Modal,Form,DatePicker,Menu, Dropdown ,Icon } from 'antd'
import { withRouter } from "react-router-dom";
import {testPlanAPI} from "../../../api/testPlanAPI";
import ViewTestPlan from './viewTestPlan'
import TestPlanDialog from './testPlanDialog'
import axios from 'axios'
import faker from "faker";
import { format } from '../../../utils/formatTime'
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
import Header from '../../public/component/header'


import searchIcon from './img/search.png'
import deleteIcon from './img/delete.png'
import styles from './style.module.css'
import { sceneInfo } from "../../../api/sceneInfo";
import { schemeAPI } from "../../../api/schemeAPI";

const { Option } = Select;
const confirm = Modal.confirm;
const {  RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD'

@withRouter
class testPlan extends React.Component{
  state={
    planList:[],
    planLen:0,
    viewVisible:false,
    operaVisible:false,
    addOrUpdateTitle:'创建测试计划',
    operaDialogVisible:false,
    viewDialogVisible:false,
    operaType:'',
    updatePlanInfo:null,
    sceneList:[],
    showExpandedColumn:false
  }

  componentDidMount (){
    this.getPlanList({})
    //this.getSceneList()
  }

  getPlanList=(data)=>{
    testPlanAPI.getPlanList(data).then(res=>{
      if(res.data.code === 200){
        this.setState({planList:res.data.data.list,planLen:res.data.data.list.length})
      }
    })
  }

  getSceneList=()=>{
    sceneInfo.getSceneList().then(res=>{
        if(res.data.code === 200){
          this.setState({sceneList:res.data.data})
        }
    })
  }

  viewPlanInfo=(text)=>{
    let data={id:text.id}
    this.getPlanInfoById(data)
    this.setState({viewDialogVisible:true})
  }

  getPlanInfoById=(data)=>{
    let that = this
    testPlanAPI.getPlanById(data).then(res=>{
      if(res.data.code === 200){
        that.setState({updatePlanInfo:res.data.data})
      }
    })
  }

  changePlanStatus=(str,status)=>{
    let that = this
    if(status === 2){
      confirm({
        title: `确定要取消${str}这条测试计划吗?`,
        okText:'确定',
        cancelText:'取消',
        onOk() {
          testPlanAPI.updatePlanStatus({id:str,planStatus:status}).then(res=>{
            if(res.data.code === 200){
              message.success('操作成功!')
              that.getPlanList({})
            }else{
              message.error('操作失败!')
            }
          })
        },
        onCancel() {

        },
      });

    }else{
      testPlanAPI.updatePlanStatus({id:str,planStatus:status}).then(res=>{
        if(res.data.code === 200){
          message.success('操作成功!')
          that.getPlanList({})
        }else{
          message.error('操作失败!')
        }
      })
    }


  }

  exportPlanHandle=(str)=>{
    axios.post('/testSite/record/exportPlan',{id:str},{responseType:'blob'}).then(res=>{
      // 此处有个坑。这里用content保存文件流，最初是content=res，但下载的test.xls里的内容如下图1，
      // 检查了下才发现，后端对文件流做了一层封装，所以将content指向res.data即可
      // 另外，流的转储属于浅拷贝，所以此处的content转储仅仅是便于理解，并没有实际作用=_=
      const content = res.data
      const blob = new Blob([content])// 构造一个blob对象来处理数据
      const fileName = '测试计划.doc'

      // 对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
      // IE10以上支持blob但是依然不支持download
      if ('download' in document.createElement('a')) { // 支持a标签download的浏览器
        const link = document.createElement('a')// 创建a标签
        link.download = fileName// a标签添加属性
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        document.body.appendChild(link)
        link.click()// 执行下载
        URL.revokeObjectURL(link.href) // 释放url
        document.body.removeChild(link)// 释放标签
      } else { // 其他浏览器
        navigator.msSaveBlob(blob, fileName)
      }
    })

  }

  deleteScheme=(text)=>{
    const data ={id:text.id}
    schemeAPI.deleteScheme(data).then(res=>{
      if(res.data.code === 200){
        message.success("删除成功!")
        this.getSchemeInfoList()
      }else{
        message.error("删除失败!")
      }
    })
  }

  addTestPlanHandle=()=>{
    this.setState({addOrUpdateTitle:'创建测试计划',operaDialogVisible:true,operaType:'create'})
  }


  editChange=(flag,schemeInfo)=>{
    this.setState({addOrUpdateTitle:'编辑测试计划',operaDialogVisible:flag,updatePlanInfo:schemeInfo,operaType:'update'})
  }

  changModalVisible=(flag)=>{
    this.getPlanList({})
    this.setState({operaDialogVisible:flag})
  }

  onChange=(e)=> {
    this.setState({showExpandedColumn:e.target.checked})
  }

  searchPlan=()=>{

    this.props.form.validateFields((err, values) => {
      if (!err) {
        let data ={}
        if(values.id){
          data.id=values.id
        }
        if(values.schemeId){
          data.schemeId=values.schemeId
        }
        if(values.planStatus ){
          data.planStatus=values.planStatus
        }
        if(values.timeRange1){
          data.expectStartTime=new Date(values.timeRange1[0].format('YYYY/MM/DD')).getTime()
          data.expectEndTime=new Date(values.timeRange1[1].format('YYYY/MM/DD')).getTime()
        }
        this.getPlanList(data)

      }
    })


  }

  clearSeach=()=>{
    this.props.form.setFieldsValue({"planStatus":undefined,"timeRange1":null,"id":null,"schemeId":null})
  }

  menu=(text)=>{
    return (
      <Menu>
        {(text.planStatus === 0 || text.planStatus === 1 ) && <Menu.Item key="1" ><div onClick={this.changePlanStatus.bind(this,text.id,3)}>完成测试</div></Menu.Item>}
        {(text.planStatus === 0 || text.planStatus === 1 ) && <Menu.Item key="2" ><div onClick={this.changePlanStatus.bind(this,text.id,2)}>取消测试</div></Menu.Item>}
        {(text.planStatus === 0 || text.planStatus === 1 || text.planStatus === 3) && <Menu.Item key="3" ><div onClick={this.exportPlanHandle.bind(this,text.id)}>导出</div></Menu.Item>}
      </Menu>
    )
  }

  render(){

    const {planList} = this.state
    let columns =[{
      title: "测试计划编号",
      dataIndex: 'id',
      key: 'id',
    },
      {
        title:"测试车辆编号",
        dataIndex: 'vutId',
        key: 'vutId'
      },
      {
        title:"计划状态",
        dataIndex: 'planStatus',
        key: 'planStatus',
        render: planStatus => {
          if(planStatus === 0)
            return '未开始'
          else if(planStatus === 1)
            return '测试中'
          else if(planStatus === 2)
            return '测试取消'
          else if(planStatus === 3)
            return '测试完成'

        }
      }, {
        title:"测试时间",
        render:(text)=>{
          return (<div>{format.formatTime(text.expectStartTime).substring(0,10)}-{format.formatTime(text.expectEndTime).substring(0,10)}</div>)
        }
      },{
        title:"测试方案编号",
        dataIndex: 'schemeId',
        key: 'schemeId'
      },{
        title:"负责人",
        dataIndex: 'functionary',
        key: 'functionary'
      }]

    if(this.state.showExpandedColumn)
    {
      columns =columns.concat([{
        title:"创建人",
        dataIndex: 'creater',
        key: 'creater',
      }, {
        title: "创建时间",
        dataIndex: 'createTime',
        key: 'createTime',
        render: createTime => {
          return format.formatTime(createTime)
        }
      },
        {
          title:"最后修改人",
          dataIndex: 'modifier',
          key: 'modifier',
          render: modifier => {
            if(modifier)
              return modifier
            else
              return '--'

          }
        }, {
          title: "最后修改时间",
          dataIndex: 'modifyTime',
          key: 'modifyTime',
          render: modifyTime => {
            if(modifyTime)
              return format.formatTime(modifyTime)
            else
              return '--'

          }
        }])
    }

    columns = columns.concat([{
      title:"操作",
      render:(text)=>{
        return (<div>
          <Dropdown overlay={text.planStatus !== 2 ? this.menu.bind(this,text):<div></div>}>
              <div>
                {(text.planStatus === 0 || text.planStatus === 1 || text.planStatus === 2|| text.planStatus === 3) &&<span ><img src={searchIcon} alt="" title="查看" className={styles.operaIcon} onClick={this.viewPlanInfo.bind(this,text)}/></span>}&nbsp;&nbsp;{text.planStatus !== 2 && <Icon  type="ellipsis" />}
              </div>
            </Dropdown>


        </div>)
      }
    }])



    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <Header title={'测试计划'} ></Header>
          <div className={styles.pageCon}>
          <div className={styles.searchCon}>
            <div>
              <Form style={{display:'inline-block'}} layout='inline'>
              <Form.Item >
                {getFieldDecorator('id', {
                  rules: [{
                    required: false,
                    message: '请输入测试计划编号',
                  }],
                })(
                  <Input placeholder="请输入测试计划编号" className={styles.selectStyle} />
                )}
              </Form.Item>

                <Form.Item >
                  {getFieldDecorator('schemeId', {
                    rules: [{
                      required: false,
                      message: '请输入测试方案编号',
                    }],
                  })(
                    <Input placeholder="请输入测试方案编号" className={styles.selectStyle} />
                  )}
                </Form.Item>

              <Form.Item >
                {getFieldDecorator('timeRange1', {
                  rules: [{
                    required: false,
                    message: '请选择开始时间和结束时间',
                  }],
                })(
                  <RangePicker className={styles.timeStyle}
                               placeholder={["开始日期","结束日期"]}
                    format={dateFormat}
                  />
                )}
              </Form.Item>

                <Form.Item >

                  {getFieldDecorator('planStatus', {
                    rules: [{
                      required: false,
                      message: '计划状态',
                    }],
                  })(
                    <Select  className={styles.selectStyle} placeholder="请选择计划状态"  >
                      <Option value="0" >未开始</Option>
                      <Option value="1"  >测试中</Option>
                      <Option value="2" >测试取消</Option>
                      <Option value="3"  >测试完成</Option>
                    </Select>
                  )}
                </Form.Item>



              <Button className={styles.searchBtn} onClick={this.searchPlan}>查询</Button>
              <Button className={styles.resetBtn} onClick={this.clearSeach}>重置</Button>
              </Form>
            </div>


          </div>

          <div className={styles.dataCon}>
            <div className={styles.operaCon}>
              <Row>
                <Col md={{ span: 24 }} xl={{ span: 12 }}>
                  测试计划<span className={styles.projectNum}>{this.state.planLen}</span>
                </Col>
                <Col md={{ span: 24 }} xl={{ span: 12 }} style={{textAlign:'right'}}>
                  <Checkbox onChange={this.onChange} className={styles.showRecord}>显示操作记录</Checkbox>
                  {/*<Button>导出Excel</Button>*/}
                  <Button icon="plus" className={styles.btnStyle} onClick={this.addTestPlanHandle}>新增测试计划</Button>
                </Col>
              </Row>

            </div>
            <div className={styles.dataTable} >
              <Table rowKey={record=>record.id} dataSource={planList} columns={columns}  />
            </div>

          </div>

              <Modal

              title={<div><span>查看测试计划</span><span> {this.state.updatePlanInfo && this.state.updatePlanInfo.planStatus !== 3 && <Button className="editBtn" onClick={()=>{this.editChange(true,this.state.updatePlanInfo)}}>编辑</Button>}</span></div>}
              visible={this.state.viewDialogVisible}
              footer={null}
              destroyOnClose={true}
              onCancel={()=>{this.setState({viewDialogVisible:false,updatePlanInfo:null})}}
              zIndex="10"
            >
               {this.state.updatePlanInfo && <ViewTestPlan planInfo={this.state.updatePlanInfo} editChange={this.editChange}></ViewTestPlan>}
            </Modal>

            <Modal
              bodyStyle={{paddingTop:'0px'}}
              title={this.state.addOrUpdateTitle}
              visible={this.state.operaDialogVisible}
              footer={null}

              destroyOnClose={true}
              onCancel={()=>{this.setState({operaDialogVisible:false})}}

            >
              <TestPlanDialog operaType={this.state.operaType}  planInfo={this.state.updatePlanInfo} getPlanInfoById={this.getPlanInfoById} changModalVisible={this.changModalVisible} getSchemeInfoById={this.getSchemeInfoById}></TestPlanDialog>


            </Modal>



        </div>
      </div>)
  }
}

const WrapTestPlan = Form.create()(testPlan)
export default WrapTestPlan
