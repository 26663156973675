import {post} from './request'
const reportAPI ={
  getReportList(data){
    return post("/testSite/report/find",data)
  },
  generateReport(data){
    return post("/testSite/report/add",data)
  },
  getReportInfo(data){
    return post("/testSite/report/findReportById",data)
  },
  getStatitus(data){
    return post("/testSite/report/statisByStatus",data)
  },
  getRecordById(data){
    return post("/testSite/record/findRecordById",data)
  }

}
export  {reportAPI}