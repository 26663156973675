import React from 'react'
import {Button,Tree,Icon} from 'antd'
import styles from './style.module.css'
const { TreeNode } = Tree;



class ViewTestScheme extends React.Component{
  state={
    selectSceneNum:0,
    targetList:[],
    targetExpandedKeys:[],
    schemeName:null
  }
  componentDidMount(){
    const {schemeInfo} = this.props
    this.getSchemeInfo(schemeInfo)
  }

  componentWillReceiveProps(nextProps){
    if(this.props.schemeInfo !== nextProps.schemeInfo){
      this.getSchemeInfo(nextProps.schemeInfo)
    }

  }

  getSchemeInfo=(info)=>{
    //console.log(info,"info")
    //console.log(schemeInfo,"22222")
    this.setState({schemeName:info.name,targetList:info.projects})
    let tempArray = []
    for(let i=0;i<info.projects.length;i++){
      tempArray.push(info.projects[i].id)
    }
    this.setState({targetExpandedKeys:tempArray})

  }

  renderTargetNodes=data=>data.map((item)=>{
    if (item.scenes) {
      return (
        <TreeNode title={item.name} key={item.id} dataRef={item} className={styles.pTxt}>
          {item.scenes.map((v)=>{
            return (<TreeNode title={v.name} key={v.id} dataRef={v} className={styles.sTxt}></TreeNode>)
          })}
        </TreeNode>
      );
    }
    return <TreeNode {...item} />;
  })

  render(){
    return (<div>
      {/* <div className={styles.editSceneBtnCon}>
        <Button onClick={()=>{this.props.editChange(true,this.props.schemeInfo)}} className={styles.editSchemeBtn}>编辑</Button>
      </div> */}
      <div className={styles.sceneName}>
        方案名称：{this.state.schemeName}
      </div>
      <div className={styles.targetSceneCon}>
        <Tree
          expandedKeys={this.state.targetExpandedKeys}
          showIcon
          switcherIcon={<Icon type="" />}
        >
          {this.renderTargetNodes(this.state.targetList)}
        </Tree>
      </div>
    </div>)
  }
}
export default ViewTestScheme