import React from 'react'
import {Table,Form,Select,Button,Row,Col,Checkbox,Modal,Icon} from 'antd'
import { withRouter } from "react-router-dom";
import styles from './style.module.css'
import {reportAPI} from "../../../api/reportAPI";
import PreviewDialog from './previewDialog'

import reportIcon from './img/report.png'
import searchIcon from './img/search.png'
import downloadIcon from './img/download.png'
import axios from "axios/index";
import { format } from '../../../utils/formatTime'
import { VUTAPI } from "../../../api/VUTAPI";
import Header from '../../public/component/header'
import faker from "faker";

const { Option } = Select;

@withRouter
class List extends React.Component{
  state={
    VUTIdsList:[],
    reportList:[],
    previewCon:false,
    reportInfo:null,
    undecideLen:0,
    noPassLen:0,
    passLen:0,
    showExpandedColumn:false
  }

  componentDidMount (){
    this.getALLReportList({})
    this.getVUTIdsList()
    this.getTotalReportStatitus()
  }

  getALLReportList=(data)=>{
    reportAPI.getReportList(data).then(res=>{
      if(res.data.code === 200){
          this.setState({reportList:res.data.data.list})
      }
    })
  }

  searchReport=()=>{
    let data ={}
    if(this.props.form.getFieldValue('vutId')){
      data.vutId = this.props.form.getFieldValue('vutId')
    }
    if(this.props.form.getFieldValue('resultStatus') && this.props.form.getFieldValue('resultStatus') !== -2 || this.props.form.getFieldValue('resultStatus') === 0){
      data.resultStatus = this.props.form.getFieldValue('resultStatus')
    }
    this.getALLReportList(data)
  }

  viewReport=(str)=>{

    const data ={id:str}
    this.getReportInfoById(data)
    this.setState({previewCon:true})
  }

  hiddenPreviewCon =() => {
    this.setState({previewCon:false})
  }

  getReportInfoById=(data)=>{
    reportAPI.getReportInfo(data).then(res=>{
      if(res.data.code === 200){
        // console.log(res.data.data,"一条报告")
        this.setState({reportInfo:res.data.data})
      }
    })
  }

  exportReportHandle=(str)=>{
    axios.post('/testSite/report/exportReport',{id:str},{responseType:'blob'}).then(res=>{
      const content = res.data
      const blob = new Blob([content])// 构造一个blob对象来处理数据
      const fileName = '测试报告.docx'

      if ('download' in document.createElement('a')) { // 支持a标签download的浏览器
        const link = document.createElement('a')// 创建a标签
        link.download = fileName// a标签添加属性
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        document.body.appendChild(link)
        link.click()// 执行下载
        URL.revokeObjectURL(link.href) // 释放url
        document.body.removeChild(link)// 释放标签
      } else { // 其他浏览器
        navigator.msSaveBlob(blob, fileName)
      }
    })

  }

  getTotalReportStatitus=()=>{
    let that = this
    reportAPI.getStatitus({}).then(res=>{
      if(res.data.code === 200){
        for(let i=0;i<res.data.data.length;i++){
          if(res.data.data[i].resultStatus === -1){
            that.setState({undecideLen:res.data.data[i].statusNum})
          }else if(res.data.data[i].resultStatus === 0){
            that.setState({noPassLen:res.data.data[i].statusNum})
          }else if(res.data.data[i].resultStatus === 1){
            that.setState({passLen:res.data.data[i].statusNum})
          }
        }
      }
    })
  }

  generateReport =(text)=>{
    localStorage.setItem("reportId",text.id)
    this.props.history.push({pathname:'/testResultReport',reportId:text.id});
  }

  getVUTIdsList=()=>{
    VUTAPI.getVUTIds().then(res=>{
      if(res.data.code === 200){
        this.setState({VUTIdsList:res.data.data.list})
      }
    })
  }

  onChange=(e)=> {
    this.setState({showExpandedColumn:e.target.checked})
  }

  clearSeach=()=>{
    this.props.form.setFieldsValue({"vutId":undefined,"resultStatus":undefined})
  }

  render(){

    let columns =[{
      title: "测试编号",
      dataIndex: 'id',
      key: 'id',
    },
      {
        title:"VUT编号",
        dataIndex: 'vutId',
        key: 'vutId'
      },
      {
        title:"测试状态",
        dataIndex: 'planStatus',
        key: 'planStatus',
        render:planStatus=>{
          if(planStatus === 0)
            return '未开始'
          else if(planStatus === 1)
            return '测试中'
          else if(planStatus === 2)
            return '测试取消'
          else if(planStatus === 3)
            return '测试完成'
          else
            return '--'
        }
      }, {
        title: "测试记录状态",
        dataIndex: 'recordStatus',
        key: 'recordStatus',
        render:recordStatus=>{
          if(recordStatus === 1)
            return '正式记录'
          else if(recordStatus === 0)
            return '草稿'
          else
            return '--'
        }
      },
      {
        title: "测试结果",
        dataIndex: 'resultStatus',
        key: 'resultStatus',
        render:resultStatus=>{
          if(resultStatus === -1)
            return '未判定'
          else if(resultStatus === 1)
            return '测试通过'
          else if(resultStatus === 0)
            return '测试未通过'
          else
            return '--'
        }
      }]

    if(this.state.showExpandedColumn)
    {
      columns =columns.concat([{
        title: "判定人",
        dataIndex: 'creater',
        key: 'creater'
      },{
        title: "判定时间",
        dataIndex: 'createTime',
        key: 'createTime',
        render: createTime => {
          if(createTime)
            return format.formatTime(createTime)
          else
            return '--'
        }
      }, {
        title:"最后修改人",
        dataIndex: 'modifier',
        key: 'modifier',
      }, {
        title: "最后修改时间",
        dataIndex: 'modifyTime',
        key: 'modifyTime',
        render: modifyTime => {
          if(modifyTime)
            return format.formatTime(modifyTime)
          else
            return '--'

        }
      }])
    }

    columns = columns.concat([{
      title:"操作",
      render:(text)=>{
        return (
          <div>
            {text.resultStatus === -1?(<span ><img src={reportIcon} alt="" title="生成测试报告"  className={styles.operaIcon} onClick={this.generateReport.bind(this,text)}/>&nbsp;&nbsp;</span>):
              (<span>
                  <span ><img src={searchIcon} alt="" title="查看" className={styles.operaIcon} onClick={this.viewReport.bind(this,text.id)} />&nbsp;&nbsp;</span>
                  <span ><img src={downloadIcon} alt="" title="下载"  className={styles.operaIcon} onClick={this.exportReportHandle.bind(this,text.id)} />&nbsp;&nbsp;</span>
                </span>)}


          </div>)
      }
    }])


    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <Header title={'测试报告'} />
          <div className={styles.pageCon}>
            <div className={styles.searchCon}>
              <div>
                <Form style={{display:'inline-block'}} layout='inline'>
                  <Form.Item >
                    {getFieldDecorator('vutId', {
                      rules: [{
                        required: false,
                        message: '请选择VUT编号',
                      }],
                    })(
                      <Select  className={styles.selectStyle} placeholder="测试车辆编号"  >
                        {this.state.VUTIdsList.length>0 && this.state.VUTIdsList.map((v)=>{
                          return (<Option value={v} key={v}>{v}</Option>)
                        })}
                      </Select>
                    )}
                  </Form.Item>

                  <Form.Item >
                    {getFieldDecorator('resultStatus', {
                      rules: [{
                        required: false,
                        message: '请输入测试结果',
                      }],
                    })(
                      <Select  className={styles.selectStyle} placeholder="测试结果"  >
                        <Option value={-2} >不限</Option>
                        <Option value={-1} >未判定</Option>
                        <Option value={0} >测试未通过</Option>
                        <Option value={1} >测试通过</Option>
                      </Select>
                    )}
                  </Form.Item>

                  <Button className={styles.searchBtn} onClick={this.searchReport}>查询</Button>
                  <Button className={styles.resetBtn} onClick={this.clearSeach}>重置</Button>
                </Form>
              </div>
            </div>

            <div className={styles.dataCon}>
              <div className={styles.operaCon}>
                <Row>
                  <Col md={{ span: 24 }} xl={{ span: 12 }}>
                    未判定<span className={styles.projectNum}>{this.state.undecideLen}</span>测试不通过<span className={styles.projectNum}>{this.state.noPassLen}</span>
                    测试通过<span className={styles.projectNum}>{this.state.passLen}</span>
                  </Col>
                  <Col md={{ span: 24 }} xl={{ span: 12 }} style={{textAlign:'right'}}>
                    <Checkbox onChange={this.onChange} className={styles.showRecord}>显示操作记录</Checkbox>
                  </Col>
                </Row>

              </div>
              <div className={styles.dataTable} >
                <Table rowKey={record=>record.id} dataSource={this.state.reportList} columns={columns}  />
              </div>

            </div>


            <Modal visible={this.state.previewCon} width="55%" footer={null} onCancel={this.hiddenPreviewCon}>
              {this.state.reportInfo && <PreviewDialog reportInfo={this.state.reportInfo}></PreviewDialog>}
            </Modal>


        </div>
      </div>)
  }
}

const WrapList = Form.create()(List)
export default WrapList