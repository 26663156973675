//时间日期格式化
const format = {
    formatTime(timestap){
        //1535798499000
        let d = new Date(timestap);
        let year = d.getFullYear();
        let month = d.getMonth()+1;
        if(month<10){
            month = "0"+month
        }
        let day = d.getDate();
        if(day<10){
            day = "0"+day
        }
        let h = d.getHours();
        if(h<10){
            h = "0"+h
        }
        let m= d.getMinutes();
        if(m<10){
            m = "0"+m
        }
        let s= d.getSeconds();
        if(s<10){
            s = "0"+s
        }
        // let t = 
        return year+"-"+month+"-"+day+"  "+h+":"+m+":"+s;
      }
}

export  {format}
 