import React from 'react'
import { Map, Marker,Label } from 'rc-bmap';
import sceneIcon from './img/sceneIcon.png'
import styles from './style.module.css'
class MarkMap extends React.Component {


  render(){
    const {testFieldInfo,markList} = this.props
     console.log(markList,"传到地图的内容")
    const pointCenter = JSON.parse(testFieldInfo.axisBdCenter)
    const mapEvents = {
      click: event => {
        //console.log("mapClick", event);
        this.props.getMapPoint(event.point)
      }
    };
    const icon = {
      url: sceneIcon,
      size: {
        width: 58,
        height: 58
      }
    }

    const labelOffset = {
      width: 30,
      height: 0
    };

    const style = {
      backgroundColor: "white",
      color: "#3E3F42",
      padding:'20px 30px',
      fontSize: '12px',
      border:'0px',
      zIndex:'-1'
    };

    return (
      <div style={{height:'640px'}}>
        <Map ak="FAcaea3b7506bd3769515d745c9b4bab" ref="map" scrollWheelZoom={true}
          zoom={14}
          center={new window.BMap.Point(pointCenter.wgLon,pointCenter.wgLat)}
          maxZoom={25}
          highResolution={true}
             events={mapEvents}
             style={{height:'100%',position:'absolute !important'}}
        >
          {this.props.mapPoint && <Marker point={this.props.mapPoint} label={this.props.sceneName?<Label content={this.props.sceneName} style={style} offset={labelOffset} />:null}  icon={icon}/>}
          {markList && markList.length > 0 ?
            markList.map((v)=>{
              return <Marker key={v.markNo} point={{lng:v.longitude,lat:v.latitude}}  icon={v.imgUrl?{url:v.imgUrl,size: { width: 20, height: 20 },opts: { imageSize: { width: 20, height: 20 } }}:null}/>
            }):null
          }

        </Map>
      </div>
    )
  }
}
export default MarkMap