import {post} from './request'
// 测试车辆接口
const VUTAPI ={
  getVUTList(data){
    return post("/testSite/vut/find",data)
  },
  getVUTIds(){
    return post("/testSite/vut/findIds",{})
  },
  getVUTById(data){
    return post("/testSite/vut/findById",data)
  },
  addVUT(data){
    return post("/testSite/vut/add",data)
  },
  updateVUT(data){
    return post("/testSite/vut/update",data)
  },
  deleteVUT(data){
    return post("/testSite/vut/del",data)
  },
  statisticAll(data){
    return post("/testSite/vut/statisByType",data)
  }
}
export  {VUTAPI}