import React from 'react'
import {Row,Col,Form, Input, Button,Select,Radio,Switch,Tabs,Upload, Icon,Modal,message,Tab   } from 'antd'
import MarkMap from './markMap'
import styles from './style.module.css'
import faker from "faker";
import {sceneInfo} from '../../../api/sceneInfo'
import { bd09togcj02, gcj02tobd09, wgs84togcj02, gcj02towgs84 } from '../../../api/latLngTransform';
import { device } from "../../../api/deviceSearch";

const Option = Select.Option;
const { TextArea } = Input;
const formItemLayout = {
  labelCol: { span: 8},
  wrapperCol: { span: 8 },
};

class CameraInfoDialog extends React.Component{
  state={
    mapShow:false,
    previewVisible: false,
    previewImage: '',
    fileList: [],
    allProjectList:[],
    mapPoint:null,
    activeKey:"1",
    imageIdList:[],
    testFieldList:[],
    coordinateMode:1
  }

  componentDidMount(){

    if(this.props.cameraInfo && this.props.cameraInfo.latitude && this.props.cameraInfo.longitude ){
       this.setState({mapPoint:{lng:this.props.cameraInfo.longitude,lat:this.props.cameraInfo.latitude}})


    }

    this.getTestFieldNameList()

  }

  changeCameraType=(value)=>{
    if(value === 3 || value === 4){
      this.props.changeDialogWidth("35%")
      this.setState({mapShow:false})
    }else if(value === 1 || value === 2){
      if(this.state.coordinateMode === 0){
          this.setState({mapShow:true})
          this.props.changeDialogWidth("60%")
          if(this.state.mapPoint){
            this.getMapPoint(this.state.mapPoint)
          }
      }
    }
  }

  coordinateWay=(type)=>{
    if(type === 0){
      this.props.changeDialogWidth("60%")
      this.setState({mapShow:true})
    }else{
      this.props.changeDialogWidth("35%")
      this.setState({mapShow:false})
    }

    this.setState({coordinateMode:type})
  }

  getTestFieldNameList=()=>{
    device.getTestFieldList({}).then(res=>{
      if(res.data.code === 200){
        this.setState({
          testFieldList:res.data.data
        })
      }
    })
  }

  changeLongitudeHandle=(e)=>{
    const value = e.target.value;
    // console.log(value,"经度")
    if(value && this.props.form.getFieldValue("handLatitude")){
      const gcjLatLng = wgs84togcj02(parseFloat(value), parseFloat(this.props.form.getFieldValue("handLatitude")))
      const bdLatLngFromGCJ= gcj02tobd09(gcjLatLng.lng, gcjLatLng.lat)
      this.setState({mapPoint:{lng:bdLatLngFromGCJ.lng,lat:bdLatLngFromGCJ.lat}})

    }
    // this.props.form.getFieldValue({"handLongitude":wgsLatLngFromBD.lng,handLatitude:wgsLatLngFromBD.lat})
    // console.log(e,"eeeeeeee")
  }

  changeLatitudeHandle=(e)=>{
    const value = e.target.value;
    // console.log(value,"纬度")
    if(this.props.form.getFieldValue("handLongitude") && value){
      const gcjLatLng = wgs84togcj02(parseFloat(this.props.form.getFieldValue("handLongitude")),parseFloat(value))
      const bdLatLngFromGCJ= gcj02tobd09(gcjLatLng.lng, gcjLatLng.lat)
      this.setState({mapPoint:{lng:bdLatLngFromGCJ.lng,lat:bdLatLngFromGCJ.lat}})

    }
    // this.props.form.getFieldValue({"handLongitude":wgsLatLngFromBD.lng,handLatitude:wgsLatLngFromBD.lat})
    // console.log(e,"eeeeeeee")
  }






  onChange=(checked)=> {
    this.setState({mapShow:checked})
    if(checked){
      this.props.changeDialogWidth("60%")
    }else{
      this.props.changeDialogWidth("40%")
    }

    //console.log(`switch to ${checked}`);
  }






  handleCancel = () => this.setState({ previewVisible: false })





  getMapPoint=(point)=>{
    // console.log(point,"获取的point")
    // console.log(point.lng, point.lat,"百度地图坐标")
    const gcjLatLng = bd09togcj02(point.lng, point.lat)
    const wgsLatLngFromBD = gcj02towgs84(gcjLatLng.lng, gcjLatLng.lat)
    // console.log(wgsLatLngFromBD.lng, wgsLatLngFromBD.lat,"硬件坐标")
    this.props.form.setFieldsValue({"handLongitude":wgsLatLngFromBD.lng,handLatitude:wgsLatLngFromBD.lat})
    this.setState({mapPoint:point})
  }

  operaCameraInfoHanlde=()=>{
    let that = this

    this.props.form.validateFields((err, values) => {

      if (!err) {
        // console.log('Received values of form: ', values);
        let data = null
        if(values.type ===1 ||values.type ===2){
          data ={id:this.props.cameraInfo.id,latitude:values.handLatitude,longitude:values.handLongitude,testFieldId:values.testFieldId,type:values.type,remarks:values.remarks}
        }
        else{
          data ={id:this.props.cameraInfo.id,testFieldId:values.testFieldId,type:values.type,remarks:values.remarks}
        }
        device.updateCameraInfo(data).then(res=>{
          if(res.data.code === 200){
            message.success('修改成功!')
            this.props.changModalVisible(false)
          }else{
            message.error('修改失败!')
          }
        })

      }
    });
  }

  render(){
    const { getFieldDecorator } = this.props.form;
    const {operaType,cameraInfo} = this.props
     // console.log(cameraInfo,"cameraInfo")
    const gcjLatLng = bd09togcj02(cameraInfo.longitude, cameraInfo.latitude)
    const wgsLatLngFromBD = gcj02towgs84(gcjLatLng.lng, gcjLatLng.lat)
    // console.log(this.state.mapShow,"this.state.mapShow")

    return (<div  className={styles.cameraInfoCon}>
      <Row gutter={20}>

          <Col span={this.state.mapShow ?12:24}>
            <Row gutter={20}>
              <Col span={12}>

                <Form.Item  label="摄像机编号">
                  <span className="ant-form-text">{cameraInfo.deviceSerial}</span>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item  label="所属区域">
                  {getFieldDecorator('testFieldId', {
                    initialValue: cameraInfo.testFieldId,
                    rules: [{
                      required: true,
                      message: '请选择所属区域',
                    }],
                  })(
                    <Select placeholder="请选择所属区域" style={{width:'100%'}}>
                      {this.state.testFieldList.length>0 && this.state.testFieldList.map((item,index) => (
                        <Option key={item.id} value={item.id} >
                          {item.testFieldName}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item  label="视频流地址">
                  <span className="ant-form-text">{cameraInfo.url}</span>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item  label="位置">
                  <span className="ant-form-text">{cameraInfo.address}</span>
                </Form.Item>
              </Col>


              <Col span={12}>
                <Form.Item  label="摄像机类型">
                  {getFieldDecorator('type', {
                    initialValue: cameraInfo.type,
                    rules: [{
                      required: true,
                      message: '请选择摄像头类型',
                    }],
                  })(
                    <Select placeholder="请选择摄像头类型" style={{width:'100%'}} onChange={this.changeCameraType}>
                      <Option value={1} key={faker.name.findName()}>安防监控摄像头</Option>
                      <Option value={2} key={faker.name.findName()}>交通监控摄像头</Option>
                      <Option value={3} key={faker.name.findName()}>车外摄像头</Option>
                      <Option value={4} key={faker.name.findName()}>车内摄像头</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>

              {(this.props.form.getFieldValue('type') === 1|| this.props.form.getFieldValue('type') === 2) &&<div >
                <Col className={styles.pointWay} span={24}>
                  <span className={this.state.coordinateMode ===1?styles.lightSelect:styles.defaultSelect} onClick={()=>this.coordinateWay(1)}>输入坐标</span>
                  <span className={this.state.coordinateMode ===0?styles.lightSelect:styles.defaultSelect} onClick={()=>this.coordinateWay(0)}>地图标注</span>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="硬件经度"
                  >
                    {getFieldDecorator('handLongitude', {
                      initialValue: wgsLatLngFromBD.lng,
                      rules: [{ required: true, message: '请输入硬件经度!' },{ pattern: /^-?(((\d|[1-9]\d|1[0-7]\d|0)\.\d{0,16})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,16}|180)$/, message: '格式不对'}],
                    })(
                      <Input  style={{ width: '100%' }}  placeholder="请输入硬件经度" onChange={this.changeLongitudeHandle} onBlur={this.handleConfirmBlur}/>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="硬件纬度">
                    {getFieldDecorator('handLatitude', {
                      initialValue: wgsLatLngFromBD.lat,
                      rules: [{ required: true, message: '请输入硬件纬度!' },{ pattern: /^-?([0-8]?\d{1}\.\d{0,16}|90\.0{0,16}|[0-8]?\d{1}|90)$/, message: '格式不对' }],
                    })(
                      <Input  style={{ width: '100%' }} placeholder="请输入硬件纬度" onChange={this.changeLatitudeHandle} onBlur={this.handleConfirmBlur} />
                    )}
                  </Form.Item>
                </Col>


              </div>
              }

              <Col span={24}>
                <Form.Item  label="备注">
                  {getFieldDecorator('remarks', {
                    initialValue: cameraInfo.remarks,
                    rules: [{
                      required: false,
                      message: '请输入备注信息',
                    }],
                  })(
                    <TextArea rows={4} placeholder="请输入备注信息" />
                  )}
                </Form.Item>
              </Col>







              <Col span={12} className={styles.sceneCancelBtn} onClick={()=>this.props.changModalVisible(false)}><Button>取消</Button></Col>
              <Col span={12} className={styles.sceneSubmitBtn}><Button  className={styles.btnStyle} onClick={this.operaCameraInfoHanlde} >保存</Button></Col>

            </Row>
          </Col>

        {this.state.mapShow && (this.props.form.getFieldValue('type') === 1|| this.props.form.getFieldValue('type') === 2) && <Col span={12}>
          <Row>
            <Col span={12}>
              <Form.Item label="地图经度" {...formItemLayout}>
              <span className="ant-form-text">{this.state.mapPoint?this.state.mapPoint.lng:null}</span>
            </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="地图经度" {...formItemLayout}>
                <span className="ant-form-text">{this.state.mapPoint?this.state.mapPoint.lat:null}</span>
              </Form.Item>
            </Col>
          </Row>
          <MarkMap mapPoint={this.state.mapPoint} getMapPoint={this.getMapPoint} sceneName={this.props.form.getFieldValue('name')}></MarkMap>
        </Col>}


      </Row>

      <Modal visible={this.state.previewVisible} footer={null} onCancel={this.handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
      </Modal>

    </div>)
  }
}

const WrapCameraInfoDialog = Form.create()(CameraInfoDialog)
export default WrapCameraInfoDialog