import React from 'react'
import styles from './style.module.css'
import {Row,Col} from 'antd'
import faker from "faker";
import { format } from "../../../utils/formatTime";
class PreviewDialog extends React.Component{

  render(){
      const {reportInfo} = this.props
      return (
        <div>
          <div id="pdf" style={{fontVariant:'normal'}} >
            <p style={{textAlign:'center',fontWeight:'bold',fontSize:'24px',fontVariant:'normal'}}>智 能 网 联 测 试 场</p>
            <p style={{textAlign:'center',fontWeight:'bold',fontSize:'24px',fontVariant:'normal'}}>检 测 报 告</p>
            <p style={{textAlign:'center',fontWeight:'bold',fontSize:'18px',fontVariant:'normal'}}>编号：{reportInfo.reportNo ? reportInfo.reportNo:"--"}</p>
            <p className={styles.previewTitle}>一.基本信息</p>
            <div>
              <Row>
                <Col span={24}><span className={styles.previewCarTitle}>委托单位名称:<span className={styles.previewCarData}>XXX汽车厂</span></span></Col>
                <Col span={24}><span className={styles.previewCarTitle}>检测方案:<span className={styles.previewCarData}>S4</span></span></Col>
                <Col span={24}><span className={styles.previewCarTitle}>测试时间：<span className={styles.previewCarData}>{reportInfo.expectStartTime ? (format.formatTime(reportInfo.expectStartTime)).substring(0,10):'--'}~{reportInfo.expectEndTime ? (format.formatTime(reportInfo.expectEndTime)).substring(0,10):'--'}</span></span></Col>
                <Col span={24}><span className={styles.previewCarTitle}>特别声明：</span></Col>
                <Col span={24}><span className={styles.previewCarTitle}><span className={styles.previewCarData}>(1) 检测依据：智能网联汽车自动驾驶功能测试规程（试行）<br/>
                (2) 评判依据：现场测试记录表以及场景测试实时监控记录
                </span></span></Col>
              </Row>
            </div>
            <p className={styles.previewTitle}>二.车辆参数</p>
            <div>
              <Row>
                <Col span={8}><span className={styles.previewCarTitle}>VUT编号:</span> <span className={styles.previewCarItemInfo}>{reportInfo.vutId ? reportInfo.vutId:"--"}</span></Col>
                <Col span={8}><span className={styles.previewCarTitle}>产品品牌:</span> <span className={styles.previewCarItemInfo}>{reportInfo.brand ? reportInfo.brand:"--"}</span> </Col>
                <Col span={8}>
                  <span className={styles.previewCarTitle}>产品型号:</span> <span className={styles.previewCarItemInfo}>{reportInfo.model ? reportInfo.model:"--"}</span>
                </Col>

                <Col span={12}><span className={styles.previewCarTitle}>VIN码:</span> <span className={styles.previewCarItemInfo}>{reportInfo.vinNumber ? reportInfo.vinNumber:"--"}</span></Col>
                <Col span={12}><span className={styles.previewCarTitle}>自动驾驶模式设计最高车速：</span> <span className={styles.previewCarItemInfo}>{reportInfo.adMaxSpeed ? reportInfo.adMaxSpeed:"--"} km/h</span></Col>
                <Col span={12}><span className={styles.previewCarTitle}>是否采用网联:</span> <span className={styles.previewCarItemInfo}>{reportInfo.isV2x ? reportInfo.isV2x:"--"}</span></Col>
                <Col span={12}><span className={styles.previewCarTitle}>是否采用高精地图:</span> <span className={styles.previewCarItemInfo}>{reportInfo.isPreciseMap ? reportInfo.isPreciseMap:"--"}</span></Col>

                <Col span={24}><span className={styles.previewCarTitle}>VT编号:</span> <span className={styles.previewCarItemInfo}>{reportInfo.bindVt ? reportInfo.bindVt:"--"}</span></Col>
                <Col span={24}><span className={styles.previewCarTitle}>OBE：</span> <span className={styles.previewCarItemInfo}>{reportInfo.bindObe ? reportInfo.bindObe:"--"}</span></Col>
                <Col span={24}><span className={styles.previewCarTitle}>车载车内摄像头：</span> <span className={styles.previewCarItemInfo}>{reportInfo.bindInCamera ? reportInfo.bindInCamera:"--"}</span></Col>
                <Col span={24}><span className={styles.previewCarTitle}>车载车外摄像头：</span> <span className={styles.previewCarItemInfo}>{reportInfo.bindOutCamera ? reportInfo.bindOutCamera:"--"}</span></Col>

              </Row>
            </div>
            <p className={styles.previewTitle}>三.检测结果</p>
            <div >
              <table className={styles.resultTable}>
                <thead>
                <tr>
                  <th>序号</th>
                  <th>测试项目</th>
                  <th>测试场景</th>
                  <th>测试结果</th>
                </tr>
                </thead>
                <tbody>
                {reportInfo.schemes[0].projects.map((item,index) => {
                  return (<tr key={faker.name.findName()}>
                    <td>{index+1}</td>
                    <td>{item.name}</td>
                    <td style={{textAlign:'left'}}>
                      {item.scenes.map((scene,n)=>{
                        return (
                          <div key={faker.name.findName()}>
                            <span>{scene.name}</span><br/>
                          </div>
                        )
                      })}
                    </td>
                    <td style={{textAlign:'left'}}>
                      {item.scenes.map((scene,n)=>{
                        return (
                          <div key={faker.name.findName()}>
                            <span>{scene.resultStatus === 1?'合格':(scene.resultStatus === 0?'不合格':'--')}</span><br/>
                          </div>
                        )
                      })}
                    </td>
                  </tr>)
                })}

                </tbody>
              </table>

            </div>
            <p className={styles.previewTitle}>四.检测结论</p>
            <br/>
            <br/>
            <p style={{textAlign:'center'}}>{reportInfo.description ? reportInfo.description:'--'}</p>
            <br/>
            <br/>
            <br/>
            <p className={styles.previewTitle}>五.检测人</p>
            <p style={{textAlign:'center'}}>{reportInfo.testPerson ? reportInfo.testPerson:'--'}</p>
            <br/>
            <br/>
            <br/>
            <p className={styles.shouwei}>测试机构：<span className={styles.shouweiSpace}>（名称和公章）</span></p>

            <p className={styles.shouwei}>发证日期：<span className={styles.shouweiSpace}>年</span><span className={styles.shouweiSpace}>月</span><span className={styles.shouweiSpace}>日</span></p>

          </div>
        </div>
      )
  }
}
export default  PreviewDialog