
import React from 'react'
import { Table, Input, Button, Icon, Checkbox, Dropdown, Menu, Modal } from 'antd'
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import styles from "../../testManagement/testProcessRecord/styles.module.css";
import Header from '../../public/component/header'
import * as recordActions from '../../../reducer/testRecord/actions'
import { post } from '../../../api/request'
import moment from 'moment'
import RecordDetail from '../../testManagement/testProcessRecord/recordDetail.js'

const prjStatusMap = {
    "0": '草稿',
    "1": '已完成'
}

@withRouter
class TestAuditTable extends React.Component {
    state = {
        searchStatu: null,
        vutId: '',
        schemeId: '',
        showOperate: false,
        showModal: false,
        isDetailEdit: false,
        isEditable: false,
        statusNum: {},
        id:''
    }

    componentDidMount() {
        const { dispatch } = this.props
        const { searchStatu, vutId, schemeId, id } = this.state;
        post('/testSite/record/statisRecordByStatus', {}).then(res => {
            if (res.status === 200 && res.data.code === 200) {
                //console.log(res.data.data)
                const data = res.data.data
                const statusNum = {}
                if (data.length) {
                    for (let i in data) {
                        statusNum[data[i].recordStatus] = data[i].statusNum
                    }
                }

                this.setState({
                    statusNum
                })
            }
            //console.log(res)
        }
        )
        dispatch(recordActions.getAllProject(vutId ? vutId : null, schemeId ? schemeId : null, searchStatu, id ? id : null))
    }

    onSearchStatuSelect = (statu) => (e) => {
        this.setState({
            searchStatu: statu
        })
    }

    onQueryClick = () => {
        const { dispatch } = this.props
        const { searchStatu, vutId, schemeId, id } = this.state;

        dispatch(recordActions.getAllProject(vutId ? vutId : null, schemeId ? schemeId : null, searchStatu, id ? id : null))
    }

    getTableMenu = (record) => {
        return (
            <Menu onClick={this.onTableActionClick(record.id, record.recordStatus)}>
                <Menu.Item key="view">
                    查看
                 </Menu.Item>
                {record.isPlayback && <Menu.Item key="playback">
                    回放
                </Menu.Item>}
            </Menu>
        )
    }

    onTableActionClick = (id, statu) => ({ item, key, keyPath }) => {
        const { dispatch, openPlayBack } = this.props
        if (key === 'playback') {
            openPlayBack(id)
        } else if (key === 'view') {
            this.setState({
                showModal: true,
                isEditable: statu === 1 ? false : true
            })
            dispatch(recordActions.getRecordDetail(id))
        }
    }

    setAllSceneState = (scene) => {
        this.setState({ scene })
    }

    modalCancel = () => {
        const { dispatch } = this.props;
        const { searchStatu, vutId, schemeId, id } = this.state;

        this.setState({
            showModal: false,
            isDetailEdit: false,
            isEditable: false
        })
        dispatch(recordActions.clearDeail)

        post('/testSite/record/statisRecordByStatus', {}).then(res => {
            if (res.status === 200 && res.data.code === 200) {
                const data = res.data.data
                const statusNum = {}
                if (data.length) {
                    for (let i in data) {
                        statusNum[data[i].recordStatus] = data[i].statusNum
                    }
                }

                this.setState({
                    statusNum
                })
            }
        }
        )
        dispatch(recordActions.getAllProject(vutId ? vutId : null, schemeId ? schemeId : null, searchStatu, id ? id : null))
    }

    render() {
        const { list, currentView } = this.props
        //console.log(list)
        const columns = [{
            title: "测试车辆编号",
            dataIndex: 'vutId',
            key: 'vutId',
        },
        {
            title: '测试计划编号',
            dataIndex: 'id',
            key: 'cid'
        },
        {
            title: "记录状态",
            key: 'recordStatus',
            dataIndex: 'recordStatus',
            render: (planStatus) => prjStatusMap[planStatus]
        },
        {
            title: "测试方案编号",
            dataIndex: 'schemeId',
            key: 'schemeId'
        }, {
            title: "记录完成率",
            dataIndex: 'recordRatio',
            key: 'recordRatio',
            render: (num) => `${num || "0"}%`
        },
        {
            title: "首次记录人",
            dataIndex: 'creater',
            key: 'creater'
        },
        {
            title: "首次记录时间",
            dataIndex: 'createTime',
            key: 'createTime',
            render: (time) => moment(time).format('YYYY-MM-DD')
        },
        {
            title: "最后修改人",
            dataIndex: 'modifier',
            key: 'modifier'
        },
        {
            title: "最后修改时间",
            dataIndex: 'modifyTime',
            key: 'modifyTime',
            render: (time) => moment(time).format('YYYY-MM-DD')
        },
        {
            title: "操作",
            key: 'action',
            render: (text, record) => {
                return (
                    <Dropdown overlay={this.getTableMenu(record)}>
                        <Icon className={styles.editIcon} type="ellipsis" />
                    </Dropdown>
                )
            }
        }

        ];

        const noShowOptionColumn = [{
            title: "测试车辆编号",
            dataIndex: 'vutId',
            key: 'vutId',
        },
        {
            title: '测试计划编号',
            dataIndex: 'id',
            key: 'cid'
        },
        {
            title: "记录状态",
            key: 'recordStatus',
            dataIndex: 'recordStatus',
            render: (planStatus) => prjStatusMap[planStatus]
        },
        {
            title: "测试方案编号",
            dataIndex: 'schemeId',
            key: 'schemeId'
        }, {
            title: "记录完成率",
            dataIndex: 'recordRatio',
            key: 'recordRatio',
            render: (num) => `${num || "0"}%`
        },
        {
            title: "操作",
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => {
                return (
                    <Dropdown overlay={this.getTableMenu(record)}>
                        <Icon className={styles.editIcon} type="ellipsis" />
                    </Dropdown>
                )
            }
        }]

        return (
            <div style={{ display: currentView === 'table' ? 'block' : 'none' }} className={styles.main}>
                <Header title={'测试审计'}></Header>
                <div className={styles.content}>
                    <section className={styles.searchCard}>

                        <div className={styles.searchInputGroup}>
                            <Input
                                value={this.state.vutId}
                                onChange={(e) => this.setState({ vutId: e.target.value })}
                                style={{ marginRight: 30, width: 130 }}
                                className={`${styles.vutNumInput} ${styles.basicHover} ${styles.basicFocus}`}
                                placeholder="测试车辆编号"
                                allowClear
                            ></Input>
                            <Input
                                value={this.state.schemeId}
                                onChange={(e) => this.setState({ schemeId: e.target.value })}
                                allowClear
                                style={{ marginRight: 30,width: 130 }}
                                className={`${styles.vutNumInput} ${styles.basicHover} ${styles.basicFocus}`}
                                placeholder="测试方案编号"
                            ></Input>
                            <Input
                                value={this.state.id}
                                onChange={(e) => this.setState({ id: e.target.value })}
                                allowClear
                                style={{ width: 130 }}
                                className={`${styles.vutNumInput} ${styles.basicHover} ${styles.basicFocus}`}
                                placeholder="测试计划编号"
                            ></Input>
                        </div>

                        <div className={styles.searchStatuSelect}>
                            <span className={styles.searchStatuTitle}>记录状态</span>
                            <span onClick={this.onSearchStatuSelect(null)} className={`${styles.searchStatuText} ${this.state.searchStatu === null ? styles.searchStatuTextSelect : null}`}>不限</span>
                            <span onClick={this.onSearchStatuSelect(0)} className={`${styles.searchStatuText} ${this.state.searchStatu === 0 ? styles.searchStatuTextSelect : null}`}>草稿</span>
                            <span onClick={this.onSearchStatuSelect(1)} className={`${styles.searchStatuText} ${this.state.searchStatu === 1 ? styles.searchStatuTextSelect : null}`}>已完成</span>
                        </div>

                        <div className={styles.searchButtonGroup}>
                            <Button onClick={this.onQueryClick} className={`${styles.queryButton} ${styles.basicHover} ${styles.buttonActive} ${styles.basicFocus}`}>查询</Button>
                            <Button
                                className={`${styles.redoButton} ${styles.basicHover} ${styles.buttonActive} ${styles.basicFocus}`}
                                onClick={() => {
                                    this.setState({
                                        searchStatu: null,
                                        vutId: '',
                                        schemeId: '',
                                        id:''
                                    })
                                }}
                                icon="undo"
                            >重置</Button>
                        </div>

                    </section>

                    <section className={styles.tableCard}>
                        <div className={styles.tableCardHeader}>
                            <div className={styles.tableStatus}>
                                <span className={styles.tableStatusTitle}>测试审计</span>

                                <div className={styles.tableStatusItem}>
                                    <span className={styles.tableStatusItemTitle}>草稿</span>
                                    <span className={styles.tableStatusItemText}>{this.state.statusNum[0]}</span>
                                </div>

                                <div className={styles.tableStatusItem}>
                                    <span className={styles.tableStatusItemTitle}>已完成</span>
                                    <span className={styles.tableStatusItemText}>{this.state.statusNum[1]}</span>
                                </div>

                                <Checkbox checked={this.state.showOperate} onChange={(e) => { this.setState({ showOperate: e.target.checked }) }} style={{ margin: '0 20px' }}>显示操作记录</Checkbox>
                            </div>
                        </div>

                        <div className={styles.tableCon}>
                            {<Table
                                dataSource={list}
                                columns={this.state.showOperate ? columns : noShowOptionColumn}
                                rowKey="id"
                                pagination={{ defaultPageSize: 8 }}
                            >
                            </Table>}
                        </div>

                    </section>

                    <Modal
                        visible={this.state.showModal}
                        title={
                            <div className={styles.modalHeader}>
                                <span>查看测试过程记录</span>
                            </div>
                        }
                        centered
                        destroyOnClose
                        onCancel={this.modalCancel}
                        footer={
                            <div className={styles.modalFooter}>

                            </div>
                        }
                        width={1080}
                        bodyStyle={{ paddingTop: 0 }}
                    >
                        <RecordDetail
                            setAllSceneState={this.setAllSceneState}
                            isDetailEdit={false}
                            close={this.modalCancel}
                        ></RecordDetail>
                    </Modal>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { allProject } = state.projectRecord;
    const list = allProject.list ? allProject.list : []

    return {
        list
    }
}

export default connect(mapStateToProps)(TestAuditTable)
