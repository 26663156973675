import React from 'react'
import { Map, Marker,Label } from 'rc-bmap';
import faker from "faker";
import sceneIcon from './img/sceneIcon.png'
import { gcj02tobd09, wgs84togcj02 } from "../../../api/latLngTransform";
class BDMap extends React.Component {
  state={
    sceneInfoList:[]
  }

  componentDidMount(){
        const {sceneList} = this.props
        this.initSceneList(sceneList)

  }

  componentWillReceiveProps(nextProps){
    if(this.props.sceneList !== nextProps.sceneList){
      this.initSceneList(nextProps.sceneList)
    }
  }

  initSceneList=(list)=>{
    let tempArray =[]
    for(let i=0;i<list.length;i++){
      // console.log(this.props.sceneInfo.coreLon, this.props.sceneInfo.coreLat,"硬件左边")
      const gcjLatLng = wgs84togcj02(list[i].coreLon, list[i].coreLat)
      const bdLatLngFromGCJ= gcj02tobd09(gcjLatLng.lng, gcjLatLng.lat)
      // console.log(bdLatLngFromGCJ.lng, bdLatLngFromGCJ.lat,"百度坐标")
      // console.log(this.state.mapShow,"地图的显示情况")


      tempArray.push({name:list[i].name,point:{lng: bdLatLngFromGCJ.lng, lat: bdLatLngFromGCJ.lat} })
    }
    this.setState({sceneInfoList:tempArray},()=>{

    })


  }

  render(){
    const icon = {
      url: sceneIcon,
      size: {
        width: 58,
        height: 58
      }
    }
    const labelOffset = {
      width: 30,
      height: 0
    };

    const style = {
      backgroundColor: "white",
      color: "#3E3F42",
      padding:'20px 30px',
      fontSize: '12px',
      border:'0px',
      zIndex:'-1'
    };
    const {sceneInfoList} = this.state
    return (
      <div style={{height:'500px'}}>
        <Map ak="FAcaea3b7506bd3769515d745c9b4bab" ref="map1" scrollWheelZoom={true}
          zoom={13}
          center={sceneInfoList.length>0?new window.BMap.Point(sceneInfoList[0].point.lng, sceneInfoList[0].point.lat):new window.BMap.Point(114.19030353797993, 30.500417893697907)}
          highResolution={true}
        >
          {sceneInfoList.length>0 && sceneInfoList.map((v)=>{
            return (<Marker point={v.point} key={v.id} icon={icon} label={v.name?<Label content={v.name} style={style} offset={labelOffset} />:null}/>)
        })}

        </Map>
      </div>
    )
  }
}
export default BDMap