import SiteMonitor from './realTimeMonitor/siteMonitor';
import SceneMonitor from './realTimeMonitor/sceneMonitor'

import TestScheme from './testManagement/testScheme'
import TestPlan from './testManagement/testPlan'
import TestProcessRecord from './testManagement/testProcessRecord'

import TestAudit from './testAnalysisAudit/testAudit'
import TestReport from './testAnalysisAudit/testResultReport/List'
import TestDataStatistic from './testAnalysisAudit/testDataStatistic'

import SiteConfig from './testFieldConfig/siteConfig'
import SceneConfig from './testFieldConfig/sceneConfig'

import TestVehicleRegister from './vehicleManagement/testVehicleRegister'
import BackgroundVehicleManagement from './vehicleManagement/backgroundVehicleManagement'

import RoadTestDeviceManagement from './deviceManagement/roadTestDeviceManagement'
import VehicleMountedManagement from './deviceManagement/vehicleMountedManagement'


import RealTimeSvg from './public/assets/realTime.svg'
import SiteMonitorSvg from './public/assets/siteMonitor.svg'
import  SceneMonitorSvg from './public/assets/sceneMonitor.svg'

import TestManageSvg from './public/assets/testManage.svg'
import TestSchemeSvg from './public/assets/testScheme.svg'
import TestPlanSvg from './public/assets/testPlan.svg'
import TestRecordSvg from './public/assets/testRecord.svg'

import TestAnalyAuditSvg from './public/assets/testAnalyAudit.svg'
import TestAuditSvg from './public/assets/testAudit.svg'
import TestReportSvg from './public/assets/testReport.svg'
import DataAnalySvg from './public/assets/dataAnaly.svg'

import TestFieldSvg from './public/assets/testField.svg'
import SiteConfigSvg from './public/assets/siteConfig.svg'
import SceneConfigSvg from './public/assets/sceneConfig.svg'

import CarManageSvg from './public/assets/carManage.svg'
import TestCarSvg from './public/assets/testCar.svg'
import BackCarSvg from './public/assets/backCar.svg'

import DeviceManageSvg from './public/assets/deviceManage.svg'
import RoadSideSvg from './public/assets/roadSide.svg'
import CarMountedSvg from './public/assets/carMounted.svg'



export default [
  {
    key: 'realTimeMonitor',
    icon: RealTimeSvg,
    title: '实时监控',
    children: [
      {
        key: 'siteMonitor',
        icon:SiteMonitorSvg,
        title: '测试场实时监控',
        component: SiteMonitor,
        url: 'siteMonitor'
      },
      {
        key: 'sceneMonitor',
        icon:SceneMonitorSvg,
        title: '场景测试实时监控',
        component: SceneMonitor,
        url: 'sceneMonitor'
      }
    ]
  },
  {
    key: 'testManagement',
    icon: TestManageSvg,
    title: '测试管理',
    children: [
      {
        key: 'testScheme',
        icon:TestSchemeSvg,
        title: '测试方案',
        component: TestScheme,
        url: 'testScheme'
      },
      {
        key: 'testPlan',
        icon:TestPlanSvg,
        title: '测试计划',
        component: TestPlan,
        url: 'testPlan'
      },
      {
        key: 'testProcessRecord',
        icon:TestRecordSvg,
        title: '测试过程记录',
        component: TestProcessRecord,
        url: 'testProcessRecord'
      }
    ]
  },
  {
    key: 'testStatistic',
    icon: TestAnalyAuditSvg,
    title: '测试分析与审计',
    children: [
      {
        key: 'testAudit',
        icon:TestAuditSvg,
        title: '测试审计',
        component: TestAudit,
        url: 'testAudit'
      },
      {
        key: 'testReport',
        icon:TestReportSvg,
        title: '测试报告',
        component: TestReport,
        url: 'testReport'
      },{
        key: 'testDataStatistic',
        icon:DataAnalySvg,
        title: '测试数据分析',
        component: TestDataStatistic,
        url: 'testDataStatistic'
      },
    ]
  },
  {
    key: 'deviceSceneConfig',
    icon: TestFieldSvg,
    title: '测试场配置',
    children: [
      {
        key: 'siteConfig',
        icon:SiteConfigSvg,
        title: '场地配置',
        component: SiteConfig,
        url: 'siteConfig'
      },
      {
        key: 'sceneConfig',
        icon:SceneConfigSvg,
        title: '测试场景配置',
        component: SceneConfig,
        url: 'sceneConfig'
      }
    ]
  },
  {
    key: 'vehicleManagement',
    icon: CarManageSvg,
    title: '车辆管理',
    children: [
      {
        key: 'testVehicleRegister',
        icon:TestCarSvg,
        title: '测试车辆登记',
        component: TestVehicleRegister,
        url: 'testVehicleRegister'
      },
      {
        key: 'backgroundVehicleManagement',
        icon:BackCarSvg,
        title: '背景车辆管理',
        component: BackgroundVehicleManagement,
        url: 'backgroundVehicleManagement'
      }
    ]
  },
  {
    key: 'vehicleConfig',
    icon: DeviceManageSvg,
    title: '设备管理',
    children: [
      {
        key: 'roadTestDeviceManagement',
        icon:RoadSideSvg,
        title: '终端设备管理 ',
        component: RoadTestDeviceManagement,
        url: 'roadTestDeviceManagement'
      },
      {
        key: 'vehicleMountedManagement',
        icon:CarMountedSvg,
        title: '摄像机管理',
        component: VehicleMountedManagement,
        url: 'vehicleMountedManagement'
      }
    ]
  }/* ,
  {
    key: 'systemManagement',
    icon: 'car',
    title: '系统管理',
    children: [
      {
        key: 'Management',
        title: '用户管理 ',
        component: TestVehicleRegister,
        url: 'userManagement'
      },
      {
        key: 'authorityManagement',
        title: '权限管理',
        component: TestVehicleRegister,
        url: 'authorityManagement'
      },
      {
        key: 'roleManagement',
        title: '角色管理',
        component: TestVehicleRegister,
        url: 'roleManagement'
      },
      {
        key: 'logManagement',
        title: '日志管理',
        component: TestVehicleRegister,
        url: 'logManagement'
      }
    ]
  } */
]
