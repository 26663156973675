import React, { Component } from 'react'
import {Button,Table} from 'antd'

class ServiceStatus extends React.Component{
    constructor(props){
        super(props)
    }
    render(){
        // 指令列表
        const instructionColumns = [{
            title: '服务名称',
            dataIndex: 'erviceName',
            key: '1',
            className: "column_font"
        },
        {
            title: '服务状态',
            key: '2',
            dataIndex:'status',
            render: (text, record) => (

                <span>
                    {text == 1?<Button type="primary"  size="small">正常</Button>:<Button type="primary" disabled  size="small">异常</Button>}
                </span>
            ),
        }
        ]

        const softInfoColumns=[{
            title: '名称',
            dataIndex: 'name',
            key: '1',
            className: "column_font"
        },
            {
                title: '版本',
                key: '2',
                dataIndex:'value',
            }
        ]


        const instructionData = [
            {
                key: '1',
                instructionName: 'HDLW',
            },
            {
                key: '2',
                instructionName: 'CDD',
            }, {
                key: '3',
                instructionName: 'EEE',
            }, {
                key: '4',
                instructionName: 'DDD',
            }, {
                key: '5',
                instructionName: 'WERT',
            }];


        return(
            <div>
                <Table
                    columns={this.props.type == "soft"?softInfoColumns:instructionColumns}
                    dataSource={this.props.serviceStatusDetail||[]}
                    pagination={false}
                />
            </div>
        )
    }
}
export default ServiceStatus