import React from 'react'
import { Map, Marker,Label } from 'rc-bmap';
import sceneIcon from './img/sceneIcon.png'
class MarkMap extends React.Component {


  render(){
    const mapEvents = {
      click: event => {
        //console.log("mapClick", event);
        this.props.getMapPoint(event.point)
      }
    };
    const icon = {
      url: sceneIcon,
      size: {
        width: 58,
        height: 58
      }
    }

    const labelOffset = {
      width: 30,
      height: 0
    };

    const style = {
      backgroundColor: "white",
      color: "#3E3F42",
      padding:'20px 30px',
      fontSize: '12px',
      border:'0px',
      zIndex:'-1'
    };
   // console.log(this.props.mapPoint,"默认传过来的地图经纬度")


    return (
      <div style={{height:'500px'}}>
        <Map ak="FAcaea3b7506bd3769515d745c9b4bab" ref="map" scrollWheelZoom={true}
          zoom={15}
          center={this.props.mapPoint?new window.BMap.Point(this.props.mapPoint.lng, this.props.mapPoint.lat):new window.BMap.Point(114.19030353797993, 30.500417893697907)}
          maxZoom={25}
          highResolution={true}
             events={mapEvents}

        >
          {this.props.mapPoint && <Marker point={this.props.mapPoint} label={this.props.sceneName?<Label content={this.props.sceneName} style={style} offset={labelOffset} />:null}  icon={icon}/>}
        </Map>
      </div>
    )
  }
}
export default MarkMap