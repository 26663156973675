import React from 'react'
import {Form,Row,Col,Input,Select,DatePicker,Button,message,Radio,InputNumber  } from 'antd'
import styles from './style.module.css'
import {VTAPI} from "../../../api/VTAPI";

import {deviceAPI} from '../../../api/deviceAPI'
import faker from "faker";

import icon1 from './img/1.png'
import icon2 from './img/2.png'

const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;


class BackgroundVehicleDialog extends React.Component{
  state={
    obeList:[],
    carInCameraList:[],
    carOutCameraList:[]
  }

  componentDidMount(){
    this.getOBEListInfo()
    this.getCameraListInfo(3)
    this.getCameraListInfo(4)
  }


  getOBEListInfo=()=>{
    deviceAPI.getOBEList().then(res=>{
      if(res.data.code === 200){
        this.setState({obeList:res.data.data.list})
      }
    })
  }

  getCameraListInfo=(type)=>{
    let data={subType:type}
    deviceAPI.getCameraList(data).then(res=>{
      if(res.data.code === 200){
        if(type === 3)
            this.setState({carOutCameraList:res.data.data.list})
        if(type === 4)
          this.setState({carInCameraList:res.data.data.list})
      }
    })
  }



  clearSelect=(text)=>{
    if(text === "obe"){
      this.props.form.setFieldsValue({"obeId":''})
    }else if(text === "in"){
      this.props.form.setFieldsValue({"inCameras":''})
    }else if(text === "out"){
      this.props.form.setFieldsValue({"outCameras":''})
    }
  }

  saveHandle=()=>{
    const {testVehicleInfo } = this.props
    this.props.form.validateFields((err, values) => {
      if (!err) {

        if(this.props.operaType === "create"){
          let data ={brand:values.brand,model:values.model,licensePlate:values.licensePlate,
                     vinNumber:values.vinNumber,adMaxSpeed:values.adMaxSpeed,
                     isPreciseMap:values.isPreciseMap,
                      isV2x:values.isV2x,
                      type:values.type,
                      obeId:values.obeId,
                      inCameras:values.inCameras,
                      outCameras:values.outCameras
                    }
          VTAPI.addVT(data).then(res=>{
            if(res.data.code === 200){
              message.success("添加成功!")
              this.props.changModalVisible(false)
            }else{
              message.success("添加失败!")
            }
          })
        }else{

          let data ={id:testVehicleInfo.id,brand:values.brand,model:values.model,
            licensePlate:values.licensePlate,vinNumber:values.vinNumber,
            adMaxSpeed:values.adMaxSpeed,
            isPreciseMap:values.isPreciseMap,
            isV2x:values.isV2x,
            type:values.type,
            obeId:values.obeId,
            inCameras:values.inCameras,
            outCameras:values.outCameras
          }
          VTAPI.updateVT(data).then(res=>{
            if(res.data.code === 200){
              message.success("修改成功!")
              this.props.changModalVisible(false)
              this.props.getBackgroundVehicleInfoById({id:testVehicleInfo.id})
            }else{
              message.success("修改失败!")
            }
          })


        }
      }
    })
  }


  render(){
    const { getFieldDecorator } = this.props.form;
    const {operaType,testVehicleInfo} = this.props
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    return (
      <div>
        <Form >
          <div className={styles.planItemCon}>
            <div className={styles.planTitleCon}>
              <img src={icon1}/><span className={styles.planTitle}>基础信息</span>
            </div>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item label="商标:" >
                  {getFieldDecorator('brand', {
                    initialValue:operaType === 'update'?testVehicleInfo.brand:null,
                    rules: [{
                      required: true,
                      message: '请输入商标',
                    }],
                  })(
                    <Input placeholder="请输入商标"  />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="型号"  >
                  {getFieldDecorator('model', {
                    initialValue:operaType === 'update'?testVehicleInfo.model:null,
                    rules: [{
                      required: true,
                      message: '请输入型号',
                    }],
                  })(
                    <Input placeholder="请输入型号"  />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="VIN码(车辆识别代码):"  >
                  {getFieldDecorator('vinNumber', {
                    initialValue:operaType === 'update'?testVehicleInfo.vinNumber:null,
                    rules: [{
                      required: true,
                      message: '请输入VIN码',
                    }],
                  })(
                    <Input placeholder="请输入VIN码"  />
                  )}
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="车牌号码:" >
                  {getFieldDecorator('licensePlate', {
                    initialValue:operaType === 'update'?testVehicleInfo.licensePlate:null,
                    rules: [{
                      required: true,
                      message: '请输入车牌号码',
                    }],
                  })(
                    <Input placeholder="请输入车牌号码"  />
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="车辆类型:" >
                  {getFieldDecorator('type', {
                    initialValue:operaType === 'update'?testVehicleInfo.type:null,
                    rules: [{
                      required: true,
                      message: '请选择车辆类型',
                    }],
                  })(
                    <RadioGroup  >
                      <RadioButton value={0}>乘用车</RadioButton>
                      <RadioButton value={1}>商用车</RadioButton>
                    </RadioGroup>
                  )}
                </Form.Item>
              </Col>

            </Row>
          </div>

          <div className={styles.planItemCon}>
            <div className={styles.planTitleCon}>
              <img src={icon2}/><span className={styles.planTitle}>车身设备</span>
            </div>
            <Row gutter={10}>
              <Col span={16}>
                <Form.Item label="OBE:" >
                  {getFieldDecorator('obeId', {
                    initialValue:operaType === 'update'?testVehicleInfo.obeId:undefined,
                    rules: [{
                      required: true,
                      message: '请选择OBE',
                    }],
                  })(
                    <Select    placeholder="请选择OBE" >
                      {this.state.obeList.length > 0 && this.state.obeList.map((v)=>{

                        return (
                          <Option value={v.deviceId} key={v.deviceId} disabled={v.bindStatus}>{v.deviceId}</Option>
                        )


                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="&nbsp;" colon={false} >
                  <Button className={styles.cancelBindBtn} onClick={this.clearSelect.bind(this,"obe")}>解除绑定</Button>
                </Form.Item>

              </Col>

              <Col span={16}>
                <Form.Item label="车载车内摄像头:" >
                  {getFieldDecorator('inCameras', {
                    initialValue:operaType === 'update'?testVehicleInfo.inCameras:undefined,
                    rules: [{
                      required: true,
                      message: '请选择车载车内摄像头',
                    }],
                  })(
                    <Select    placeholder="请选择车载车内摄像头" >
                      {this.state.carInCameraList.length > 0 && this.state.carInCameraList.map((v)=>{

                        return (
                          <Option value={v.deviceId} key={faker.name.findName()} disabled={v.bindStatus}>{v.deviceId}</Option>
                        )

                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="&nbsp;" colon={false} >
                  <Button className={styles.cancelBindBtn} onClick={this.clearSelect.bind(this,"in")}>解除绑定</Button>
                </Form.Item>

              </Col>

              <Col span={16}>
                <Form.Item label="车载车外摄像头:" >
                  {getFieldDecorator('outCameras', {
                    initialValue:operaType === 'update'?testVehicleInfo.outCameras:undefined,
                    rules: [{
                      required: true,
                      message: '请选择车载车外摄像头',
                    }],
                  })(
                    <Select    placeholder="请选择车载车外摄像头" >
                      {this.state.carOutCameraList.length > 0 && this.state.carOutCameraList.map((v)=>{


                        return (
                          <Option value={v.deviceId} key={faker.name.findName()} disabled={v.bindStatus}>{v.deviceId}</Option>
                        )

                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="&nbsp;" colon={false} >
                  <Button className={styles.cancelBindBtn} onClick={this.clearSelect.bind(this,"out")}>解除绑定</Button>
                </Form.Item>

              </Col>



            </Row>
          </div>


          <div>
              <Row>
                <Col span={12} className={styles.sceneCancelBtn}><Button>取消</Button></Col>
                <Col span={12} className={styles.sceneSubmitBtn}><Button  className={styles.btnStyle} onClick={this.saveHandle} >保存</Button></Col>
              </Row>
          </div>





        </Form>
    </div>)
  }

}


const WrapBackgroundVehicleDialog = Form.create()(BackgroundVehicleDialog)
export default WrapBackgroundVehicleDialog