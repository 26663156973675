import React, { Component } from 'react'
import { connect } from 'react-redux'
import styles from './styles.module.css'
import turnRed from './assets/turn_red.png'
import turnYellow from './assets/turn_yellow.png'
import turnGreen from './assets/turn_green.png'
import turnGray from './assets/turn_gray.png'
import stGreen from './assets/st_green.png'
import stRed from './assets/st_red.png'
import stYellow from './assets/st_yellow.png'
import stGray from './assets/st_gray.png'
import rightRed from './assets/right_red.png'
import rightYellow from './assets/right_yellow.png'
import rightGreen from './assets/right_green.png'
import rightGray from './assets/right_gray.png'
import leftRed from './assets/left_red.png'
import leftGreen from './assets/left_green.png'
import leftYellow from './assets/left_yellow.png'
import leftGray from './assets/left_gray.png'
import circleRed from './assets/circle_red.png'
import circleGreen from './assets/circle_green.png'
import circleYellow from './assets/circle_yellow.png'

const leftIcon = {
  
  "1":leftGreen,
  "2":leftRed,
  "3":leftYellow
}

const stIcon = {
  "1":stGreen,
  "2":stRed,
  "3":stYellow
}

const rightIcon = {
  "1":rightGreen,
  "2":rightRed,
  "3":rightYellow
}

const turnIcon = {
  "1":turnGreen,
  "2":turnRed,
  "3":turnYellow
}

const circleIcon = {
  "1":circleGreen,
  "2":circleRed,
  "3":circleYellow
}

const color = {
  "1":styles.greenNumber,
  "2":styles.redNumber,
  "3":styles.yellowNumber
}

class Signal extends Component {
  state = {
    turnNumber: 30,
    leftNumber: 45,
    stNumber: 60,
    rightNumber: 3
  }

  componentDidMount() {
    /* this.signalInterval = setInterval(() => {
      this.setState({
        turnNumber: this.state.turnNumber > 0 ? this.state.turnNumber - 1 : 30,
        leftNumber: this.state.leftNumber > 0 ? this.state.leftNumber - 1 : 45,
        stNumber: this.state.stNumber > 0 ? this.state.stNumber - 1 : 60,
        rightNumber: this.state.rightNumber > 0 ? this.state.rightNumber - 1 : 3
      })
    }, 1000) */
  }

  componentWillUnmount() {
    clearInterval(this.signalInterval)
  }

  getSignalPic = (light) => {
    if(light.shape === 'arrow'){
      if(light.direction[0] === 'left'){
        return leftIcon[light.status]
      }else if(light.direction[0] === 'straight'){
        return stIcon[light.status]
      }else if(light.direction[0] === 'right'){
        return rightIcon[light.status]
      }else if(light.direction[0] === 'turn'){
        return turnIcon[light.status]
      }
    }else if(light.shape === 'circle'){
      return circleIcon[light.status]
    }
  }

  getColor = statu => {

  }

  render() {
    const { rse } = this.props

    return (
      <div className={styles.lightCon}>
        {/* <div className={styles.lightPane}>
          <div className={styles.signalImgWrapper}>
            <img className={styles.signalImg} src={turnRed} alt="" />
          </div>
          <div className={`${styles.lightNumber} ${styles.redNumber}`}>{this.state.turnNumber}</div>
        </div>

        <div className={styles.lightPane}>
          <div className={styles.signalImgWrapper}>
            <img className={styles.signalImg} src={leftRed} alt="" />
          </div>
          <div className={`${styles.lightNumber} ${styles.redNumber}`}>{this.state.leftNumber}</div>
        </div>

        <div className={styles.lightPane}>
          <div className={styles.signalImgWrapper}>
            <img className={styles.signalImg} src={stGreen} alt="" />
          </div>
          <div className={`${styles.lightNumber} ${styles.greenNumber}`}>{this.state.stNumber}</div>
        </div>

        <div className={styles.lightPane}>
          <div className={styles.signalImgWrapper}>
            <img className={styles.signalImg} src={rightYellow} alt="" />
          </div>
          <div className={`${styles.lightNumber} ${styles.yellowNumber}`}>{this.state.rightNumber}</div>
        </div> */}

        {
          rse && rse.length && rse.map((light,index) =>
            <div key={index} className={styles.lightPane}>
              <div className={styles.signalImgWrapper}>
                {light.status !== 0 && <img className={styles.signalImg} src={this.getSignalPic(light)} alt="" />}
              </div>
              {light.status !== 0 && <div className={`${styles.lightNumber} ${color[light.status]}`}>{light.second}</div>}
            </div>
          )
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { realTimeData } = state.realTime;
  const { rse } = realTimeData;

  return {
    rse
  }
}

export default connect(mapStateToProps)(Signal)