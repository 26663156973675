import React, { Component } from 'react';
import { Map, Marker, Polygon, Label } from 'rc-bmap';
import { connect } from 'react-redux'
import { isEqual } from 'lodash'
import { wgs84togcj02,gcj02tobd09 } from '../../../api/latLngTransform'

class Bmap extends Component {
    state = {
        testMark:[],
    }

    componentDidMount(){
       // console.log(this.refs.map)
    }

    componentWillReceiveProps(nextProps) {
        if (!isEqual(nextProps.axisBdCenter, this.props.axisBdCenter)) {
            if (window.bMapInstance && nextProps.axisBdCenter) {
                const center = JSON.parse(nextProps.axisBdCenter)
                window.bMapInstance.centerAndZoom(new window.BMap.Point(center.wgLon, center.wgLat), 15)
            }
        }

    }

    getMarkerEvent = (marker) => {
        const { onMarkerClick } = this.props
        //console.log(marker.id)
        return {
            click: (e) => {
                //console.log(e)
                onMarkerClick({
                    ...marker
                })
            }
        }
    }

    /**
     *DB保存的是硬件坐标，要转换为百度坐标
     *
     * @memberof Bmap
     */
    getMarkerPoint = (lat,lng) => {
        const wgsTogcj = wgs84togcj02(lng,lat);
        //const gcjToBd = gcj02tobd09(wgsTogcj.lng,wgsTogcj.lat)
        return gcj02tobd09(wgsTogcj.lng,wgsTogcj.lat)
    }


    render() {
        const { axisBd, markerInfo, testMark } = this.props

        const path = axisBd ? JSON.parse(axisBd).map(el => ({ lat: el.wgLat, lng: el.wgLon })) : []

        return (
            <Map
                ak="FAcaea3b7506bd3769515d745c9b4bab"
                ref="map"
                scrollWheelZoom={true}
                zoom={15}
                center={new window.BMap.Point(120.50720302401024, 36.488341499209355)}
                //center={new window.BMap.Point(114.19030353797993, 30.500417893697907)}
                style={{ position: 'relative' }}
                //events={this.getMapEvents()}
                maxZoom={25}
                highResolution={true}
            >
                <Polygon
                    points={path}
                    strokeColor='#2CABE3'
                    strokeWeight={2}
                    strokeOpacity={1}
                    strokeStyle={'solid'}
                    fillColor="rgba(44,171,227,0.3)"
                >
                </Polygon>

                {
                    markerInfo && JSON.stringify(markerInfo) !== '{}' && testMark && testMark.map((marker, index) =>
                        <Marker
                            key={marker.id}
                           // point={new window.BMap.Point(marker.longitude, marker.latitude)}
                           point={this.getMarkerPoint(marker.latitude,marker.longitude)}
                            offset={{ width: 0, height: -10 }}
                            icon={{ url: markerInfo[marker.markType].imgUrl, size: { width: 20, height: 20 }, opts: { imageSize: { width: 20, height: 20 } } }}
                            label={marker.markType === 1 ? <Label
                                style={{
                                    border: 'none',
                                    background: 'transparent',
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '18px',
                                    height: '18px'
                                }}
                                content={marker.param ? JSON.parse(marker.param).speed : 0}
                                offset={{ width: 1, height: 1 }} 
                                /> : null}
                            shadow={{ url: markerInfo[marker.markType].imgUrl, size: { width: 0, height: 0 } }}
                            events={this.getMarkerEvent(marker)}
                        >
                        </Marker>)
                    }

            </Map>
        )
    }
}

const mapStateToProps = state => {
    const { siteInfo, markerInfo } = state.siteConfig;
    const { testField, testMark } = siteInfo;

    if (!markerInfo) {
        return {}
    }

    if (!testField || !testMark) {
        return {}
    }

    const { axisBdCenter, axisBd } = testField;

    return {
        axisBdCenter,
        axisBd,
        markerInfo,
        testMark
    }

}

export default connect(mapStateToProps)(Bmap)