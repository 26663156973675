import React from 'react'
import {Form,Row,Col,Input,Select,DatePicker,Button,message} from 'antd'
import styles from './style.module.css'
import faker from "faker";
import icon1 from './img/1.png'
import icon2 from './img/2.png'
import icon3 from './img/3.png'
import { format } from '../../../utils/formatTime'



class ViewTestPlan extends React.Component{
  state={
    planInformation:null
  }

  componentDidMount(){
    const {planInfo} = this.props
    this.setState({planInformation:planInfo})

  }

  componentWillReceiveProps(nextProps){
    if(this.props.planInfo !== nextProps.planInfo){
      this.setState({planInformation:nextProps.planInfo})
    }

  }




  render(){
    return (
      <div>
        {/* <div className={styles.editSceneBtnCon}>
          <Button onClick={()=>{this.props.editChange(true,this.props.planInfo)}} className={styles.editPlanBtn}>编辑</Button>
        </div> */}
          <div className={styles.planItemCon}>
            <div className={styles.planTitleCon}>
              <img src={icon1}/><span className={styles.planTitle}>基础信息</span>
            </div>
            <Row gutter={10}>
              <Col span={12} className={styles.viewColSpace}>
                <span className={styles.txtBold}>测试方案编号:</span><span>{this.state.planInformation?this.state.planInformation.id:'--'}</span>
              </Col>
              <Col span={12} className={styles.viewColSpace}>
                <span className={styles.txtBold}>测试车辆编号:</span><span>{this.state.planInformation?this.state.planInformation.vutId:'--'}</span>
              </Col>
              <Col span={12} className={styles.viewColSpace}>
                <span className={styles.txtBold}>测试负责人:</span><span>{this.state.planInformation?this.state.planInformation.functionary:'--'}</span>
              </Col>

              <Col span={18} className={styles.viewColSpace}>
                <span className={styles.txtBold}>测试时间:</span><span>{this.state.planInformation?format.formatTime(this.state.planInformation.expectStartTime):'--'}-{this.state.planInformation?format.formatTime(this.state.planInformation.expectEndTime):'--'}</span>
                <div style={{fontSize:'12px'}}>开始时间配置最多配置为今天往前30天以内</div>
              </Col>
              <Col span={6} className={styles.viewColSpace}>
                <span>{this.state.planInformation?parseInt((this.state.planInformation.expectEndTime-this.state.planInformation.expectStartTime) / (1000 * 60 * 60 * 24))+1:0}</span>天
              </Col>
            </Row>
          </div>

          <div className={styles.planItemCon}>
            <div className={styles.planTitleCon}>
              <img src={icon2}/><span className={styles.planTitle}>测试方案</span>
            </div>
            <Row gutter={10} >
              <Col span={24} className={styles.viewColSpace}>
                <span className={styles.txtBold}>测试方案:</span><span>{this.state.planInformation?this.state.planInformation.schemeId:'--'}</span>
              </Col>
              <Col span={24} className={styles.viewColSpace}>
                <span className={styles.txtBold}>测试场景所需要背景车辆:</span><span>{this.state.planInformation?this.state.planInformation.bindVt:'--'}</span>
              </Col>


            </Row>
          </div>


          <div className={styles.planItemCon}>
            <div className={styles.planTitleCon}>
              <img src={icon3}/><span className={styles.planTitle}>车身设备</span>
            </div>
            <Row gutter={10}>
              <Col span={16} className={styles.viewColSpace}>
                <span className={styles.txtBold}>OBE:</span><span>{this.state.planInformation?this.state.planInformation.bindObe:'--'}</span>
              </Col>
              <Col span={8} className={styles.viewColSpace}>


              </Col>

              <Col span={16} className={styles.viewColSpace}>
                <span className={styles.txtBold}>车载车内摄像头:</span><span>{this.state.planInformation?this.state.planInformation.bindInCamera:'--'}</span>
              </Col>
              <Col span={8} className={styles.viewColSpace}>

              </Col>

              <Col span={16} className={styles.viewColSpace}>
                <span className={styles.txtBold}>车载车外摄像头:</span><span>{this.state.planInformation?this.state.planInformation.bindOutCamera:'--'}</span>
              </Col>
              <Col span={8} className={styles.viewColSpace}>


              </Col>



            </Row>
          </div>


    </div>)
  }

}

export default ViewTestPlan