import * as types from './actionTypes'

export const allProject = (state = {},action) => {
    switch(action.type){
        case types.GET_ALL_PROJECT_LIST:
        return action.data;

        case types.CLEAR_ALL_PROJECT_LIST:
        return {};

        default:
        return state
    }
}

export const prjDetail = (state = {
    data:{},
    isFetching:false
},action) => {
    switch(action.type){
        case types.REQUESTING_RECORD_DETAIL:
        return {
            ...state,
            isFetching:true
        };

        case types.REQUEST_RECORD_DETAIL_SUCCESS:
        return {
            ...state,
            data:action.data,
            isFetching:false
        };

        case types.CLEAR_RECORD_DETAIL:
        return{
            ...state,
            data:{}
        }

        default:
        return state
    }
}