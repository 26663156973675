import { post } from "../../api/request.js";
import * as types from './actionTypes'
//获取测试计划列表

export const getAllProject = (vutId,schemeId,recordStatus,id) => dispatch => {
    post('/testSite/record/findRecord',{vutId,schemeId,recordStatus,id}).then(res => {
        if(res.status === 200 && res.data.code === 200){
            dispatch({
                type:types.GET_ALL_PROJECT_LIST,
                data:res.data.data
            })
        }
    })
}

//获取记录详情

export const getRecordDetail = (id) => dispatch => {
    dispatch({
        type:types.REQUESTING_RECORD_DETAIL
    })
    post('/testSite/record/findRecordById',{id}).then(res => {
        if(res.status === 200 && res.data.code === 200){
            dispatch({
                type:types.REQUEST_RECORD_DETAIL_SUCCESS,
                data:res.data.data
            })
        }
    })
}

//清除记录详情
export const clearDeail = {
    type:types.CLEAR_RECORD_DETAIL
}