import {get,post} from "./request.js"
const device={
   //获取设备列表
    getDeviceList(data){
        return post("/testField/devices/getDevicesList",data)
    },

    //获取设备数据
    getDeviceData(data){
        return post("/testField/devices/getDevicesData",data)
    },

    //获取事件数据(obe)
    getEventObeData(data){
        return post("/testField/obe/getObeData",data)
    },
    //下载设备数据
    downLoadDevicesData(data){
        return post("/testField/devices/downLoadDevicesData",data)
    },
    downObeEventData(data){
        return post("/testField/obe/exportObeEventDataExcel",data)
    },

    //获取测试场名称
    getTestFieldName(data){
        return post("/testField/findTestFieldNameList",data)
    },

    //获取测试场列表信息
    getTestFieldList(data){
      return post("/testField/findTestFieldList",data)
    },

    //更新摄像机信息
    updateCameraInfo(data){
      return post("/testField/camera/updateCamera",data)
    },

    //条件查询测试场列表
    getTestFieldByStatus(data){
        return post("/testField/devices/getDevicesList",data)
    },

    //获取事件类型
    getAllEventType(){
        return post("/testField/obe/getAllEventType",{})
    },

    //历史轨迹数据
    getHistoryList(data){
        return post("/testField/obe/getObeTrack",data)
    },

    //获取设备配置信息
    getDeviceConfiguration(data){
        return post("/testField/embDevices/getConfiguration",data)
    },
    getServiceStatusInfo(data){
        return post("/testField/embDevices/getServiceStatus",data)
    },
    //配置设备信息
    configueDeviceInfo(data){
        return post("/testField/embDevices/deviceConfiguration",data)
    },
    //获取设备的升级包信息
    getAllDevicePackageInfo(data){
        return post("/testField/embDevices/getAllDevicePackageInfo",data)
    },
    //设备软件包升级
    deviceSoftPackageUpdate(data){
        return post("/testField/embDevices/softPackageUpdate",data)
    },
    //获取设备指令列表
    getAllActionList(data){
        return post("/testField/embDevices/getAllActionForEmb",data)
    },
    //取消设备未开始执行的指令
    cancelDeviceInstruction(data){
        return post("/testField/embDevices/cancelOrder",data)
    },
    //检查指令是否已经下发过
    checkInProOrder(data){
        return post("/testField/embDevices/checkInProOrder",data)
    },
    //删除不在线的设备信息
    deleteOfflineDevice(data){
        return post("/testField/devices/delDeviceOff",data)
    }

}
export  {device}