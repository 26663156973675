import React from 'react'
import {Row,Col,Icon,Modal} from 'antd'
import styles from './style.module.css'
import CameraInfoDialog from './cameraInfoDialog'

class ImageView extends React.Component{
  state={cameraInfo:null,operaDialogVisible:false,dialogWidth:'35%',}

  editCameraInfo=(text)=>{
    this.setState({operaDialogVisible:true,cameraInfo:text})
  }

  changeDialogWidth=(num)=>{
    this.setState({dialogWidth:num})
  }

  changModalVisible=(flag)=>{

    this.setState({operaDialogVisible:flag})
    this.props.getCameraInfoList()

  }

  playVideo=(text)=>{
    this.props.playVideoHandle(text)
  }

  render(){
    const {cameraList} = this.props
    // console.log(cameraList,"cameraList")
    return (
      <div>
        <Row gutter={20}>
          {cameraList && cameraList.map((v)=>{
          return (
          <Col span={8} key={v.id}>
            <div className={styles.imageCon}>
              <div onClick={this.playVideo.bind(this,v)}><img src={v.snapshotUrl}  /></div>
              <div className={styles.cameraInfo}>
                  <span style={{float:'left'}}>{v.address}</span>
                  <span style={{float:'right'}}>{/* <span style={{marginRight:'20px'}}>{v.isOnline?<span style={{color:'#41d824'}}>在线</span>:<span style={{color:'red'}}>离线</span>}</span> */}<Icon type="edit"  onClick={this.editCameraInfo.bind(this,v)} /></span>
              </div>
            </div>
          </Col>
          )
        })}

        </Row>

        <Modal
          title="编辑摄像机"
          visible={this.state.operaDialogVisible}
          width={this.state.dialogWidth}
          footer={null}
          onCancel={()=>{this.setState({operaDialogVisible:false,dialogWidth:'35%'})}}
          destroyOnClose={true}
        >
          <CameraInfoDialog changeDialogWidth={this.changeDialogWidth} changModalVisible={this.changModalVisible} operaType={this.state.operaType} cameraInfo={this.state.cameraInfo}></CameraInfoDialog>
        </Modal>

      </div>
    )
  }
}

export default  ImageView