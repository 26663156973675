import React from 'react'
import { Input } from 'antd';

export default class NumericInput extends React.Component {
  onChange = (e) => {
    const { value } = e.target;
    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
    if ((!Number.isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      this.props.onChange(value);
    }
  }

  // '.' at the end or only '-' in the input box.
  onBlur = () => {
    const { value, onBlur, onChange } = this.props;
    //console.log(value)
    //console.log(value)
    let _value = value.toString()
    if (_value.charAt(value.length - 1) === '.' || _value === '-') {
        _value = _value.slice(0, -1)  
     onChange(_value);
    }
    if (onBlur) {
        onBlur(_value);
      }
  }

  render() {
    
    return (
        <Input
          {...this.props}
          onChange={this.onChange}
          onBlur={this.onBlur}
          maxLength={25}
        />
    );
  }
}