import React from 'react'
import styles from './fsHeader.module.css'
import { Icon } from 'antd'
import Clock from './digitalClock'

export default class FSHeader extends React.Component {
    state = {
        isFullScreen: false
    }

    render() {
        return (
            <header className={styles.header}>
                {<Icon onClick={this.props.backToTable} className={styles.fullScreenIcon} type="arrow-left" title="返回"/>}
                <span>{this.props.title}</span>
                <div className={styles.digitalClock}>
                    <Clock></Clock>
                </div>
            </header>
        )
    }
}