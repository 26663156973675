import React from 'react'
import {Form,Row,Col,Input,Select,DatePicker,Button,message,Radio,InputNumber  } from 'antd'
import styles from './style.module.css'
import {testPlanAPI} from "../../../api/testPlanAPI";
import {VUTAPI} from "../../../api/VUTAPI";
import {VTAPI} from "../../../api/VTAPI";
import {schemeAPI} from "../../../api/schemeAPI";
import {deviceAPI} from '../../../api/deviceAPI'
import faker from "faker";

import icon1 from './img/1.png'
import icon2 from './img/2.png'
import icon3 from './img/3.png'
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const {  RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD'

class TestVehicleDialog extends React.Component{
  state={
    VUTList:[],
    VTList:[],
    schemeList:[],
    obeList:[],
    cameraList:[]
  }

  componentDidMount(){

  }



  saveHandle=()=>{
    const {testVehicleInfo } = this.props
    this.props.form.validateFields((err, values) => {
      if (!err) {

        if(this.props.operaType === "create"){
          let data ={brand:values.brand,model:values.model,licensePlate:values.licensePlate,
                     vinNumber:values.vinNumber,adMaxSpeed:values.adMaxSpeed,
                     isPreciseMap:values.isPreciseMap,
                      isV2x:values.isV2x,
                      type:values.type
                    }
          VUTAPI.addVUT(data).then(res=>{
            if(res.data.code === 200){
              message.success("添加成功!")
              this.props.changModalVisible(false)
            }else{
              message.success("添加失败!")
            }
          })
        }else{

          let data ={id:testVehicleInfo.id,brand:values.brand,model:values.model,
            licensePlate:values.licensePlate,vinNumber:values.vinNumber,
            adMaxSpeed:values.adMaxSpeed,
            isPreciseMap:values.isPreciseMap,
            isV2x:values.isV2x,
            type:values.type
          }
          VUTAPI.updateVUT(data).then(res=>{
            if(res.data.code === 200){
              message.success("修改成功!")
              this.props.changModalVisible(false)
              this.props.getTestVehicleInfoById({id:testVehicleInfo.id})
            }else{
              message.success("修改失败!")
            }
          })


        }
      }
    })
  }


  render(){
    const { getFieldDecorator } = this.props.form;
    const {operaType,testVehicleInfo} = this.props
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    return (
      <div>
        <Form >
          <div className={styles.planItemCon}>
            <div className={styles.planTitleCon}>
              <img src={icon1}/><span className={styles.planTitle}>基础信息</span>
            </div>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item label="商标:" >
                  {getFieldDecorator('brand', {
                    initialValue:operaType === 'update'?testVehicleInfo.brand:null,
                    rules: [{
                      required: true,
                      message: '请输入商标',
                    }],
                  })(
                    <Input placeholder="请输入商标"  />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="型号"  >
                  {getFieldDecorator('model', {
                    initialValue:operaType === 'update'?testVehicleInfo.model:null,
                    rules: [{
                      required: true,
                      message: '请输入型号',
                    }],
                  })(
                    <Input placeholder="请输入型号"  />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="VIN码(车辆识别代码):"  >
                  {getFieldDecorator('vinNumber', {
                    initialValue:operaType === 'update'?testVehicleInfo.vinNumber:null,
                    rules: [{
                      required: true,
                      message: '请输入VIN码',
                    }],
                  })(
                    <Input placeholder="请输入VIN码"  />
                  )}
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="车牌号码:" >
                  {getFieldDecorator('licensePlate', {
                    initialValue:operaType === 'update'?testVehicleInfo.licensePlate:null,
                    rules: [{
                      required: true,
                      message: '请输入车牌号码',
                    }],
                  })(
                    <Input placeholder="请输入车牌号码"  />
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="车辆类型:" >
                  {getFieldDecorator('type', {
                    initialValue:operaType === 'update'?testVehicleInfo.type:null,
                    rules: [{
                      required: true,
                      message: '请选择车辆类型',
                    }],
                  })(
                    <RadioGroup  >
                      <RadioButton value={0}>乘用车</RadioButton>
                      <RadioButton value={1}>商用车</RadioButton>
                      <RadioButton value={2}>无人小巴</RadioButton>
                      <RadioButton value={3}>无人大巴</RadioButton>
                      <RadioButton value={4}>无人卡车</RadioButton>
                      <RadioButton value={5}>无人驾驶车辆</RadioButton>
                    </RadioGroup>
                  )}
                </Form.Item>
              </Col>

            </Row>
          </div>

          <div className={styles.planItemCon}>
            <div className={styles.planTitleCon}>
              <img src={icon2}/><span className={styles.planTitle}>智能属性</span>
            </div>
            <Row gutter={10}>
              <Col span={24} >
                <Form.Item label="自动驾驶最高车速(km/h):"  {...formItemLayout} labelAlign="left" >
                  {getFieldDecorator('adMaxSpeed', {
                    initialValue:operaType === 'update'?testVehicleInfo.adMaxSpeed:null,
                    rules: [{
                      required: true,
                      message: '请输入自动驾驶最高车速',
                    }],
                  })(
                    <InputNumber placeholder="请输入自动驾驶最高车速" min={1} max={200}  style={{width:'50%'}}  />
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="是否采用网联技术:"  {...formItemLayout} labelAlign="left">
                  {getFieldDecorator('isV2x', {
                    initialValue:operaType === 'update'?testVehicleInfo.isV2x:null,
                    rules: [{
                      required: true,
                      message: '请选择是否采用网联技术',
                    }],
                  })(
                    <RadioGroup >
                      <Radio value={1}>是</Radio>
                      <Radio value={0}>否</Radio>
                    </RadioGroup>
                  )}
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="是否采用高精地图:" {...formItemLayout} >
                  {getFieldDecorator('isPreciseMap', {
                    initialValue:operaType === 'update'?testVehicleInfo.isPreciseMap:null,
                    rules: [{
                      required: true,
                      message: '请选择是否采用高精地图',
                    }],
                  })(
                    <RadioGroup >
                      <Radio value={1}>是</Radio>
                      <Radio value={0}>否</Radio>
                    </RadioGroup>
                  )}
                </Form.Item>
              </Col>

            </Row>
          </div>


          <div>
              <Row>
                <Col span={12} className={styles.sceneCancelBtn}><Button>取消</Button></Col>
                <Col span={12} className={styles.sceneSubmitBtn}><Button  className={styles.btnStyle} onClick={this.saveHandle} >保存</Button></Col>
              </Row>
          </div>





        </Form>
    </div>)
  }

}


const WrapTestVehicleDialog = Form.create()(TestVehicleDialog)
export default WrapTestVehicleDialog