import React, { Component } from "react";
import { Layout, Menu, Icon } from "antd";
import { Link, Switch, Route,withRouter } from "react-router-dom";
import styled from "styled-components";
import { map, isArray } from "lodash";
import config from "./app-frame.config";
import NotFound from "./error/NotFound";
import TestResultReport from './testAnalysisAudit/testResultReport'
import styles from './public/assets/css/main.module.css'
import {TestFieldName} from '../view/public/testFieldInfo'
import LongLogo from './public/assets/longLogo.png'
import ShortLogo from './public/assets/shortLogo.png'


const FullSizeLayout = styled(Layout)`
  height: 100%;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-x:auto;
`;

const { Header, Content, Footer, Sider } = Layout;

const { SubMenu, Item } = Menu;

@withRouter
class AppFrame extends Component {
  state = {
    collapsed: false,
    openKeys: [],
    selectedKeys:[]
  };

  componentDidMount(){
    this.setSelectedMenu()
  }



  setSelectedMenu=()=>{
    const { location } = this.props;
    const url = location.pathname.replace("/","")
    const tempDefaultSelectedKeys = []
    const tempDefaultOpenKeys = []
    for(let i= 0;i< config.length ; i += 1){
      if(config[i].children.length > 0){
        for(let j = 0;j<config[i].children.length;j += 1){
          if(config[i].children[j].key === url){
            tempDefaultSelectedKeys.push(url)
            tempDefaultOpenKeys.push(config[i].key)
            this.setState({openKeys:tempDefaultOpenKeys,selectedKeys:tempDefaultSelectedKeys})
          }
        }
      }
    }
  }

  clickMenuItem=(info)=>{
    // this.setState({selectedKeys:[info.key]})
    for(let i= 0;i< config.length ; i += 1){
      if(config[i].children.length > 0){
        for(let j = 0;j<config[i].children.length;j += 1){
          if(config[i].children[j].key === info.key){
            this.setState({openKeys:[config[i].key],selectedKeys:[info.key]})
            return
          }
        }
      }
    }
  }

  onOpenChange = (openInfoKeys) => {
    const {openKeys } = this.state
    const latestOpenKey = openInfoKeys.find(key => openKeys.indexOf(key) === -1);
    this.setState({openKeys:latestOpenKey  ? [latestOpenKey] : []})
  }



  onCollapse = collapsed => {

    console.log(collapsed,"collapsed")
    this.setState({ collapsed });
  };


  screenSize=()=>{
    window.addEventListener('resize',()=>{
      console.log("123")
      //this.props.history.push({pathname:'/testResultReport',reportId:str});
    })
  }

  static renderSubMenu({ key, icon, title, children }) {
    return (
      <SubMenu
        key={key}
        title={
          <span>
            <Icon component={icon} style={{fontSize:'38px'}} />
            <span>{title}</span>
          </span>
        }

      >
        {map(children, menu => AppFrame.renderMenu(menu))}
      </SubMenu>
    );
  }

  static renderMenu({ key, icon, title, url }) {
    return (
      <Item key={key} >
        <Link to={`/${url}`} >
          {icon ? <Icon component={icon} style={{fontSize:'30px'}} /> : null}
          <span >{title}</span>
        </Link>
      </Item>
    );
  }

  static renderContentHelper(component, key, url) {
    const ContentComponent = component;

    return (
      <Route key={key} exact path={`/${url}`}>
        <ContentComponent />
      </Route>
    );
  }

  static renderContent({ children, component, key, url }) {
    if (isArray(children)) {
      return map(children, child => AppFrame.renderContent(child));
    }

    return AppFrame.renderContentHelper(component, key, url);
  }

  static renderHeader({ children, title, key, url }) {
    if (isArray(children)) {
      return map(children, child => AppFrame.renderHeader(child));
    }

    return AppFrame.renderHeaderHelper(title, key, url);
  }

  static renderHeaderHelper(title, key, url) {
    return (
      <Route key={key} exact path={`/${url}`}>
        {title}
      </Route>
    );
  }





  render() {
    const { collapsed ,openKeys,selectedKeys} = this.state;
    const defaultProps = collapsed?{}:{openKeys}
    return (
      <FullSizeLayout>
        <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse}  width="287px">
          {!this.state.collapsed ?<div className={styles.logo}><img src={LongLogo} className={styles.longLogo}/></div>:<div className={styles.placeDiv}><img src={ShortLogo} className={styles.shortLogo} /></div>}

          <Menu theme="dark"  mode="inline"  selectedKeys={selectedKeys} style={{background:'#1f2a2c'}}
                {...defaultProps}
                onOpenChange={this.onOpenChange} onClick={this.clickMenuItem} inlineCollapsed>
            {map(config, menu => {
              if (isArray(menu.children)) {
                return AppFrame.renderSubMenu(menu);
              }

              return AppFrame.renderMenu(menu);
            })}
          </Menu>
        </Sider>
        <Layout >
          {/* <Header style={{ background: "#fff", padding: 0 }}>
            <Switch>
              {map(config, menu => AppFrame.renderContent(menu))}
              <Route component={NotFound} />
            </Switch>
          </Header> */}
          <Content style={{width:'100%',height:'100%'}}>
            <ContentWrapper>
              <Switch>
                {map(config, menu => AppFrame.renderContent(menu))}
                <Route exact path="/testResultReport" component={TestResultReport} />
                <Route component={NotFound} />

              </Switch>
            </ContentWrapper>
          </Content>
          {/* <Footer style={{ textAlign: "center" }}>Huali</Footer> */}
        </Layout>
      </FullSizeLayout>
    );
  }
}
export default AppFrame;
