import React from 'react'
import {Form,Row,Col,Input,Select,DatePicker,Button,message} from 'antd'
import styles from './style.module.css'
import {testPlanAPI} from "../../../api/testPlanAPI";
import {VUTAPI} from "../../../api/VUTAPI";
import {VTAPI} from "../../../api/VTAPI";
import {schemeAPI} from "../../../api/schemeAPI";
import {deviceAPI} from '../../../api/deviceAPI'
import faker from "faker";

import icon1 from './img/1.png'
import icon2 from './img/2.png'
import icon3 from './img/3.png'
import moment from 'moment';
import 'moment/locale/zh-cn';
import { connect } from "react-redux";
import * as actions from "../../../reducer/siteConfig/actions";
import { QDTestFieldId } from "../../public/testFieldID";
moment.locale('zh-cn');

const Option = Select.Option;
const {  RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD'

class TestPlanDialog extends React.Component{
  state={
    VUTList:[],
    VTList:[],
    schemeList:[],
    obeList:[],
    cameraList:[],
    carInCameraList:[],
    carOutCameraList:[],
    backgroundCarFlag:false
  }

  componentDidMount(){

    this.getVUTList()
    this.getSchemeList()
    this.getVTList()
    this.getOBEListInfo()
    this.getCameraListInfo(3)
    this.getCameraListInfo(4)
    if(this.props.operaType === 'update'){
      const {planInfo} = this.props
      if(planInfo.bindVt)
        this.setState({backgroundCarFlag:true})
    }
  }

  getVUTList=()=>{
    VUTAPI.getVUTIds().then(res=>{
      if(res.data.code === 200){
        this.setState({VUTList:res.data.data.list})
        // console.log(res.data.data,"res.data.data")
      }
    })
  }

  getVTList=()=>{
    VTAPI.getVTIds().then(res=>{
      if(res.data.code === 200){
        this.setState({VTList:res.data.data.list})
        // console.log(res.data.data,"res.data.data")
      }
    })
  }

  getSchemeList=()=>{
    schemeAPI.getSchemeList({}).then(res=>{
      if(res.data.code === 200){
        this.setState({schemeList:res.data.data.list})
      }
    })
  }

  getOBEListInfo=()=>{
    deviceAPI.getOBEList().then(res=>{
      if(res.data.code === 200){
        this.setState({obeList:res.data.data.list})
      }
    })
  }

  getCameraListInfo=(type)=>{
    let data={subType:type}
    deviceAPI.getCameraList(data).then(res=>{
      if(res.data.code === 200){
        if(type === 3)
          this.setState({carOutCameraList:res.data.data.list})
        if(type === 4)
          this.setState({carInCameraList:res.data.data.list})
      }
    })
  }

  changeTime=(dates,dateStrings)=>{
    let  tempDate = new Date(dateStrings[1]) - new Date(dateStrings[0])
   // console.log(tempDate ,"日期")
    // console.log(parseInt(tempDate / (1000 * 60 * 60 * 24)),"多少天")

    this.props.form.setFieldsValue({"dateNum":parseInt(tempDate / (1000 * 60 * 60 * 24))+1})


  }

  disabledDate=(current)=> {
    // Can not select days before today and today
    return current && current < moment().subtract(30, 'days');
  }

  clearSelect=(text)=>{
    if(text === "obe"){
      this.props.form.setFieldsValue({"bindObe":''})
    }else if(text === "in"){
      this.props.form.setFieldsValue({"bindInCamera":''})
    }else if(text === "out"){
      this.props.form.setFieldsValue({"bindOutCamera":''})
    }
  }

  schemeHasBackgroundCar=(data)=>{
    schemeAPI.isBackgroundCar(data).then(res=>{
      if(res.data.code === 200){
        if(res.data.data === 0)
          this.setState({backgroundCarFlag:false})
        else if(res.data.data === 1)
          this.setState({backgroundCarFlag:true})
      }
    })
  }

  checkBackgroundCar=(value)=>{
    this.schemeHasBackgroundCar({id:value})
    console.log(value,"value")
  }

  saveHandle=()=>{
    const {planInfo } = this.props
    this.props.form.validateFields((err, values) => {
      if (!err) {

        if(this.props.operaType === "create"){
          let data ={vutId:values.vutId,functionary:values.functionary,
                      schemeId:values.schemeId,bindObe:values.bindObe,
                      bindInCamera:values.bindInCamera,
                      bindOutCamera:values.bindOutCamera,
                      expectStartTime:values.timeRange[0].format('YYYY-MM-DD'),
                      expectEndTime:values.timeRange[1].format('YYYY-MM-DD')
                    }
          if(this.state.backgroundCarFlag){
            data = Object.assign({},data,{bindVt:values.bindVt})
          }
          testPlanAPI.addPlan(data).then(res=>{
            if(res.data.code === 200){
              message.success("添加成功!")
              this.props.changModalVisible(false)
            }else{
              message.error(res.data.msg)
            }
          })
        }else{

          let data ={id:planInfo.id,vutId:values.vutId,functionary:values.functionary,
            schemeId:values.schemeId,bindObe:values.bindObe,
            bindInCamera:values.bindInCamera,
            bindOutCamera:values.bindOutCamera,
            expectStartTime:values.timeRange[0].format('YYYY-MM-DD'),
            expectEndTime:values.timeRange[1].format('YYYY-MM-DD')
          }
          if(this.state.backgroundCarFlag){
            data = Object.assign({},data,{bindVt:values.bindVt})
          }
          testPlanAPI.editPlan(data).then(res=>{
            if(res.data.code === 200){
              message.success("修改成功!")
              this.props.changModalVisible(false)
              this.props.getPlanInfoById({id:planInfo.id})
            }else{
              message.success(res.data.msg)
            }
          })


        }
      }
    })
  }


  render(){
    const { getFieldDecorator } = this.props.form;
    const {operaType,planInfo} = this.props


    return (
      <div>
        <Form >
          <div className={styles.planItemCon}>
            <div className={styles.planTitleCon}>
              <img src={icon1}/><span className={styles.planTitle}>基础信息</span>
            </div>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item label="测试车辆编号:" >
                  {getFieldDecorator('vutId', {
                    initialValue:this.props.operaType === 'update'?planInfo.vutId:undefined,
                    rules: [{
                      required: true,
                      message: '请选择测试车辆',
                    }],
                  })(
                    <Select   placeholder="请选择测试车辆">
                      {this.state.VUTList.length > 0 && this.state.VUTList.map((v)=>{
                        return (
                          <Option value={v} key={v}>{v}</Option>
                        )
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="测试负责人:" >
                  {getFieldDecorator('functionary', {
                    initialValue:this.props.operaType === 'update'?planInfo.functionary:null,
                    rules: [{
                      required: true,
                      message: '请输入测试负责人',
                    }],
                  })(
                    <Input placeholder="请输入测试负责人"  />
                  )}
                </Form.Item>
              </Col>

              <Col span={18}>
                <Form.Item label="测试时间:"  extra="开始时间配置最多配置为今天往前30天以内">
                  {getFieldDecorator('timeRange', {
                    initialValue:this.props.operaType === 'update'?[moment(planInfo.expectStartTime),moment(planInfo.expectEndTime)]:null,
                    rules: [{
                      required: true,
                      message: '请输入测试时间',
                    }],
                  })(
                    <RangePicker  onChange={this.changeTime}
                                 placeholder={["开始日期","结束日期"]}
                                 format={dateFormat}
                                  disabledDate={this.disabledDate}
                    />
                  )}
                </Form.Item>
                <span></span>
              </Col>
              <Col span={6}>
                <Form.Item label="天" colon={false}>
                  {getFieldDecorator('dateNum', {
                    initialValue:this.props.operaType === 'update'?parseInt((planInfo.expectEndTime-planInfo.expectStartTime) / (1000 * 60 * 60 * 24))+1:null,
                  })(
                    <Input  />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className={styles.planItemCon}>
            <div className={styles.planTitleCon}>
              <img src={icon2}/><span className={styles.planTitle}>测试方案</span>
            </div>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item label="测试方案:" >
                  {getFieldDecorator('schemeId', {
                    initialValue:this.props.operaType === 'update'?planInfo.schemeId:undefined,
                    rules: [{
                      required: true,
                      message: '请选择测试方案',
                    }],
                  })(
                    <Select    placeholder="请选择测试方案"  onChange={this.checkBackgroundCar} >
                      {this.state.schemeList.length > 0 && this.state.schemeList.map((v)=>{
                        return (
                          <Option value={v.id} key={v.id}>{v.name}</Option>
                        )
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              {this.state.backgroundCarFlag && <Col span={12}>
                <Form.Item label="测试场景所需要背景车辆:" >
                  {getFieldDecorator('bindVt', {
                    initialValue:this.props.operaType === 'update'?planInfo.bindVt:undefined,
                    rules: [{
                      required: true,
                      message: '请选择背景车辆',
                    }],
                  })(
                    <Select    placeholder="请选择背景车辆" >
                      {this.state.VTList.length > 0 && this.state.VTList.map((v)=>{
                        return (
                          <Option value={v} key={v}>{v}</Option>
                        )
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>}


            </Row>
          </div>


          <div className={styles.planItemCon}>
            <div className={styles.planTitleCon}>
              <img src={icon3}/><span className={styles.planTitle}>车身设备</span>
            </div>
            <Row gutter={10}>
              <Col span={16}>
                <Form.Item label="OBE:" >
                  {getFieldDecorator('bindObe', {
                    initialValue:this.props.operaType === 'update'?planInfo.bindObe:undefined,
                    rules: [{
                      required: true,
                      message: '请选择OBE',
                    }],
                  })(
                    <Select    placeholder="请选择OBE" >
                      {this.state.obeList.length > 0 && this.state.obeList.map((v)=>{

                          return (
                            <Option value={v.deviceId} key={faker.name.findName()} disabled={v.bindStatus}>{v.deviceId}</Option>
                          )


                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="&nbsp;" colon={false} >
                  <Button className={styles.cancelBindBtn} onClick={this.clearSelect.bind(this,"obe")}>解除绑定</Button>
                </Form.Item>

              </Col>

              <Col span={16}>
                <Form.Item label="车载车内摄像头:" >
                  {getFieldDecorator('bindInCamera', {
                    initialValue:this.props.operaType === 'update'?planInfo.bindInCamera:undefined,
                    rules: [{
                      required: true,
                      message: '请选择车载车内摄像头',
                    }],
                  })(
                    <Select    placeholder="请选择车载车内摄像头" >
                      {this.state.carInCameraList.length > 0 && this.state.carInCameraList.map((v)=>{

                          return (
                            <Option value={v.deviceId} key={faker.name.findName()} disabled={v.bindStatus}>{v.deviceId}</Option>
                          )

                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="&nbsp;" colon={false} >
                  <Button className={styles.cancelBindBtn} onClick={this.clearSelect.bind(this,"in")}>解除绑定</Button>
                </Form.Item>

              </Col>

              <Col span={16}>
                <Form.Item label="车载车外摄像头:" >
                  {getFieldDecorator('bindOutCamera', {
                    initialValue:this.props.operaType === 'update'?planInfo.bindOutCamera:undefined,
                    rules: [{
                      required: true,
                      message: '请选择车载车外摄像头',
                    }],
                  })(
                    <Select    placeholder="请选择车载车外摄像头" >
                      {this.state.carOutCameraList.length > 0 && this.state.carOutCameraList.map((v)=>{


                          return (
                            <Option value={v.deviceId} key={faker.name.findName()} disabled={v.bindStatus}>{v.deviceId}</Option>
                          )

                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="&nbsp;" colon={false} >
                  <Button className={styles.cancelBindBtn} onClick={this.clearSelect.bind(this,"out")}>解除绑定</Button>
                </Form.Item>

              </Col>



            </Row>
          </div>
          <div>
              <Row>
                <Col span={12} className={styles.sceneCancelBtn} onClick={()=>{this.props.changModalVisible(false)}}><Button>取消</Button></Col>
                <Col span={12} className={styles.sceneSubmitBtn}><Button  className={styles.btnStyle} onClick={this.saveHandle} >保存</Button></Col>
              </Row>
          </div>





        </Form>
    </div>)
  }

}

const WrapTestPlanDialog = Form.create()(TestPlanDialog)

export default WrapTestPlanDialog