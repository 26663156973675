import { combineReducers } from "redux";
import  * as siteMonitor from './realTimeMonitor/reducer';
import * as siteConfig from './siteConfig/reducer'
import * as projectRecord from './testRecord/reducer'
import * as playback from './playback/reducer'

const reducer = {
   realTime: combineReducers(siteMonitor),
   siteConfig:combineReducers(siteConfig),
   projectRecord:combineReducers(projectRecord),
   playback:combineReducers(playback)
}

export default combineReducers(reducer);
