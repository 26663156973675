import React from 'react'
import {Table,Input,Button,Checkbox,Row,Col,Select,Form,Popover,Tooltip,Icon  } from 'antd'
import { withRouter } from "react-router-dom";
import {device} from "../../../api/deviceSearch";
import ServiceStatus from './serviceStatus'
import { format } from '../../../utils/formatTime'
import styles from './style.module.css'
import faker from "faker";
import Header from "../../public/component/header";


const { Option } = Select;


@withRouter
class RoadTestDeviceManagement extends React.Component{
  state={
    deviceList:[],
    serviceStatusDetail:[],
    nameList:[],
    selectedNameValue:"",
    selectedTypeValue:"",
    selectedStatusValue:"",
    devicesNumberValue:"",
  }

  componentDidMount (){
    if(localStorage.getItem("selectedNameValue") || localStorage.getItem("selectedTypeValue")||parseInt(localStorage.getItem("selectedTypeValue")) == 0 || localStorage.getItem("selectedStatusValue")|| parseInt(localStorage.getItem("selectedStatusValue")) ==0 || localStorage.getItem("devicesNumberValue") ){
      if(!localStorage.getItem("selectedTypeValue") && parseInt(localStorage.getItem("selectedTypeValue")) != 0){
        this.setState({selectedTypeValue:""})
      }else{
        this.setState({selectedTypeValue:localStorage.getItem("selectedTypeValue")})
      }
      if(!localStorage.getItem("selectedStatusValue") && parseInt(localStorage.getItem("selectedStatusValue")) != 0 ){
        this.setState({selectedStatusValue:""})
      }else{
        this.setState({selectedStatusValue:localStorage.getItem("selectedStatusValue")})
      }
      this.setState({selectedNameValue:localStorage.getItem("selectedNameValue"),
        devicesNumberValue:localStorage.getItem("devicesNumberValue")},()=>{
        this.getTestFieldBy()
      })


    }
    this.getTestFieldNameList()
  }

  getTestFieldBy=()=>{
    let data = {}
    if(this.state.selectedNameValue){
      data.testFieldName=this.state.selectedNameValue
      localStorage.setItem("selectedNameValue",this.state.selectedNameValue)
    }else{
      localStorage.removeItem("selectedNameValue")
    }
    if(this.state.selectedTypeValue){
      data.devicesType=this.state.selectedTypeValue
      localStorage.setItem("selectedTypeValue",this.state.selectedTypeValue)
    }else{
      localStorage.removeItem("selectedTypeValue")
    }
    if(this.state.selectedStatusValue){
      data.devicesStatus=this.state.selectedStatusValue
      localStorage.setItem("selectedStatusValue",this.state.selectedStatusValue)
    }else{
      localStorage.removeItem("selectedStatusValue")
    }
    if(this.state.devicesNumberValue){
      data.devicesNumber=this.state.devicesNumberValue
      localStorage.setItem("devicesNumberValue",this.state.devicesNumberValue)
    }else{
      localStorage.removeItem("devicesNumberValue")
    }
    device.getTestFieldByStatus(data).then(res=>{
      this.setState({
        deviceList:res.data.data
      })
    })
  }

  handleChangeName=(value)=>{
    this.setState({
      selectedNameValue:value
    })
  }

  handleChangeType=(value)=>{
    this.setState({
      selectedTypeValue:value
    })
  }

  handleChangeStatus=(value)=>{
    this.setState({
      selectedStatusValue:value
    })
  }

  inputText=(e)=>{
    this.setState({
      devicesNumberValue:e.target.value
    })
  }

  getTestFieldNameList=()=>{
    device.getTestFieldName({}).then(res=>{
      if(res.data.code === 200){
        this.setState({
          nameList:res.data.data
        })
        if(res.data.data && res.data.data.length > 0){
          let data = {}
          if(!localStorage.getItem("selectedNameValue") && !localStorage.getItem("selectedTypeValue") && parseInt(localStorage.getItem("selectedTypeValue")) !=0 && !localStorage.getItem("selectedStatusValue") && parseInt(localStorage.getItem("selectedStatusValue")) !=0 && !localStorage.getItem("devicesNumberValue")){
            data.testFieldName= res.data.data[0]
            this.setState({
              selectedNameValue:res.data.data[0]
            })
            device.getTestFieldByStatus(data).then(res=>{
              this.setState({
                deviceList:res.data.data,
              })
              localStorage.setItem("selectedNameValue",data.testFieldName)
            })
          }else{
            if(!localStorage.getItem("selectedNameValue")){
              this.setState({selectedNameValue:""})
            }
          }
        }

      }
    })
  }

  clearSearch=()=>{
    this.setState({selectedNameValue:"",selectedTypeValue:"",selectedStatusValue:"",devicesNumberValue:null})
  }


  render(){
    const {deviceList} = this.state
    const columns = [
      {
        title: '区域',
        dataIndex: 'testFieldName',
        key: '1',
        className: "column_font",
      }, {
        title: '设备类型',
        dataIndex: 'devicesType',
        key: '2',
        className: "column_font",
        render: devicesType => {
          return devicesType == "0" ? "OBE" : "RSE"
        }
      }, {
        title: '设备编号',
        dataIndex: 'devicesNumber',
        key: '3',
        className: "column_font",
        render:devicesNumber=>{
          if(devicesNumber){
            return (
              <Tooltip placement="topLeft" title={devicesNumber}>
                { "..."+devicesNumber.substring(devicesNumber.length-10) }
              </Tooltip>
            )

          }
        }
      },{
        title:'软件版本',
        key: '4',
        className: "column_font",
        render: (text) => {
          if (text.embDeviceStatus ) {
            const tempArray = [{name:'osVersion',value:text.embDeviceStatus.osVersion},
              {name:'v2xVersion',value:text.embDeviceStatus.v2xVersion}
            ]

            return (
              <div>
                <Popover content={<div><ServiceStatus serviceStatusDetail={tempArray} type={"soft"}/></div>}>
                  {text.embDeviceStatus.v2xVersion+"..."}
                </Popover>
              </div>

            )


          } else {
            return ('--')
          }
        }
      },
      {
        title: '设备位置',
        dataIndex: 'devicesPosition',
        key: '5',
        className: "column_font",
        render: devicesPosition => {
          if(devicesPosition){
            return (
              <Tooltip placement="topLeft" title={devicesPosition}>
                <span >{"..."+devicesPosition.substring(devicesPosition.length-11)}</span>
              </Tooltip>
            )
          }else{
            return "--"
          }
        }

        ,
      }, {
        title: '在线状态',
        dataIndex: 'devicesStatus',
        key: '6',
        className: "column_font",
        render: devicesStatus => {
          return devicesStatus == "0" ? <span style={{color:'red'}} >离线</span> : <span style={{color:'#41d824'}}>在线</span>
        }
      }, {
        title: '上线时间',
        dataIndex: 'onLineTime',
        key: '7',
        className: "column_font",
        sorter: (a, b) => a.onLineTime- b.onLineTime,
        render: onLineTime => {
          return format.formatTime(onLineTime * 1000)
        }
      }, {
        title:'服务状态',
        key: '8',
        className: "column_font",
        render: (text) => {
          if (text.serviceStatusDetail ) {
            if(text.devicesServiceStatus == 0){
              return (
                <div>
                  <Popover content={<div><ServiceStatus serviceStatusDetail={JSON.parse(text.serviceStatusDetail)}/></div>}>
                    <span style={{color:'red'}}>异常</span>
                  </Popover>
                </div>

              )
            } if(text.devicesServiceStatus == 1){
              return (
                <div>
                  <Popover content={<div><ServiceStatus serviceStatusDetail={this.state.serviceStatusDetail.length > 0 ?this.state.serviceStatusDetail:JSON.parse(text.serviceStatusDetail)}/></div>}>
                    <span style={{color:'#41d824'}}>正常</span>
                  </Popover>
                </div>
              )
            }

          } else {
            return ('--')
          }
        }
      }

    ];

    const { getFieldDecorator } = this.props.form;

    return (
      <div>
          <Header title={'路侧设备管理'}/>
          <div className={styles.pageCon}>
          <div className={styles.searchCon}>
            <div>
              <Select value={this.state.selectedNameValue} onChange={this.handleChangeName}  className={styles.selectStyle} placeholder="请选择计划状态">
                <Option value="">选择区域</Option>
                {this.state.nameList.length>0 && this.state.nameList.map((item,index) => (
                  <Option key={index} value={item} >
                    {item?item:"--"}
                  </Option>
                ))}
              </Select>

              <Select onChange={this.handleChangeType} value={this.state.selectedTypeValue} className={styles.selectStyle}>
                <Option  value="">选择设备类型</Option>
                <Option value="0">OBE</Option>
                <Option value="1">RSE</Option>
              </Select>

              <Select onChange={this.handleChangeStatus} value={this.state.selectedStatusValue} className={styles.selectStyle}>
                <Option value="">选择设备状态</Option>
                <Option value="0">离线</Option>
                <Option value="1">在线</Option>
              </Select>
              <Input onChange={this.inputText} className={styles.selectStyle} placeholder="设备编号" value={this.state.devicesNumberValue} />

              <Button className={styles.searchBtn} onClick={this.getTestFieldBy}>查询</Button>
              <Button className={styles.resetBtn} onClick={this.clearSearch.bind(this)}>重置</Button>

            </div>


          </div>

          <div className={styles.dataCon}>

            <div className={styles.dataTable} >
              <Table rowKey={record=>faker.name.findName()} dataSource={deviceList} columns={columns}  />
            </div>

          </div>
         </div>
      </div>)
  }
}

const WrapRoadTestDeviceManagement = Form.create()(RoadTestDeviceManagement)
export default WrapRoadTestDeviceManagement
