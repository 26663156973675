import {post} from './request'
// 设备的相关接口
const deviceAPI ={
  getOBEList(){
    return post("/testSite/device/findObes",{})
  },
  getCameraList(data){
    return post("/testSite/device/findCameras",data)
  },
  getOBEMonitor (data) {
    return post('/testField/obe/getObeMonitor',data)
  }
}
export  {deviceAPI}