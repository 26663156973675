import * as types from './actionTypes';

export const monitorOBEdata = (state = [], action) => {
    switch (action.type) {
        case types.GET_OBE_LIST_SUCCESS:
            return action.data;

        case types.CLEAR_OBE_LIST:
            return []

        default:
            return state
    }
}

export const monitorRSEdata = (state = [], action) => {
    switch (action.type) {
        case types.GET_RSE_LIST_SUCCESS:
            return action.data;

        case types.CLEAR_RSE_LIST:
            return []

        default:
            return state
    }
}

export const siteMonitorCamList = (state = [], action) => {
    switch (action.type) {
        case types.GET_CAM_LIST:
            return action.data;

        case types.CLEAR_CAM_LIST:
            return [];

        default:
            return state
    }
}

//所有测试中的VUT
export const allTestingVUT = (state = [], action) => {
    switch (action.type) {
        case types.GET_ALL_TESTING_VUT:
            return action.data;

        case types.CLEAR_ALL_TESTING_VUT:
            return [];

        case types.CLEAR_ALL_REALTIME_DATA:
            return [];

        default:
            return state
    }
}

//当前选择的待测车辆和背景车辆信息
export const VUTInfo = (state = [], action) => {
    switch (action.type) {
        case types.GET_CAR_INFO:
            return action.data;

        case types.CLEAR_CAR_INFO:
            return [];

        case types.CLEAR_ALL_REALTIME_DATA:
            return [];

        default:
            return state
    }
}

//获取实时数据
export const realTimeData = (state = {}, action) => {
    switch (action.type) {
        case types.GET_REALTIME_DATA:
            return action.data;

        case types.CLEAR_REALTIME_DATA:
            return {};

        case types.CLEAR_ALL_REALTIME_DATA:
            return {};

        case types.CLEAR_REALTIME_DATA_EXCEPT_INFO:
            return {};    

        default:
            return state
    }
}

//VUT速度历史
export const vutSpeedHistory = (state = [], action) => {
    switch (action.type) {
        case types.UPDATE_VUT_HISTORY:
            return state.concat(action.data);

        case types.CLEAR_VUT_HISTORY:
            return [];

        case types.CLEAR_ALL_REALTIME_DATA:
            return [];
          
        case types.CLEAR_REALTIME_DATA_EXCEPT_INFO:
            return []    

        default:
            return state;
    }
}

//VUT加速度历史
export const vutAccHistory = (state = [], action) => {
    switch (action.type) {
        case types.UPDATE_VUT_ACC_HISTORY:
            return state.concat(action.data);

        case types.CLEAR_VUT_ACC_HISTORY:
            return [];

        case types.CLEAR_ALL_REALTIME_DATA:
            return [];
        
        case types.CLEAR_REALTIME_DATA_EXCEPT_INFO:
            return []    

        default:
            return state;
    }
}

//VT速度历史
export const vtSpeedHistory = (state = [], action) => {
    switch (action.type) {
        case types.UPDATE_VT_HISTORY:
            return state.concat(action.data);

        case types.CLEAR_VT_HISTORY:
            return [];

        case types.CLEAR_ALL_REALTIME_DATA:
            return [];

        case types.CLEAR_REALTIME_DATA_EXCEPT_INFO:
            return [];    

        default:
            return state;
    }
}

//VT加速度历史
export const vtAccHistory = (state = [], action) => {
    switch (action.type) {
        case types.UPDATE_VT_ACC_HISTORY:
            return state.concat(action.data);

        case types.CLEAR_VT_ACC_HISTORY:
            return [];

        case types.CLEAR_ALL_REALTIME_DATA:
            return [];

        case types.CLEAR_REALTIME_DATA_EXCEPT_INFO:
            return [];    

        default:
            return state;
    }
}

export const vutEvent = (state = [], action) => {
    switch (action.type) {
        case types.UPDATE_VUT_EVENT:
            const _newState =  state.concat(action.data);
            return _newState.length <= 300?_newState:_newState.slice(1)

        case types.CLEAR_VUT_EVENT:
            return [];

        case types.CLEAR_ALL_REALTIME_DATA:
            return [];

        case types.CLEAR_REALTIME_DATA_EXCEPT_INFO:
            return []    

        default:
            return state;
    }
}