import React, { Component } from 'react'
import {Route,withRouter,Redirect} from "react-router-dom"

class PrivateRoute extends Component {
  constructor(props){
    super(props)
    this.state={
      isAuth:localStorage.getItem("token")?"true":"false"
    }
  }

  componentWillMount(){
    if(this.state.isAuth==="false"){//是否登录如果未登录跳转到登录页面
      const {history} = this.props;
      setTimeout(() => {
        history.replace("/login");
      }, 1000)
    }
  }

  render() {
    let { component: Component, ...rest} = this.props;
    return this.state.isAuth==="true" ?
      (<Route {...rest} render={(props) => ( <Component {...props} />
      )}/> ) : <Redirect to="/login" />
  }
}

export default withRouter(PrivateRoute);