// @flow
import React from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension'
import { BrowserRouter as Router,Route,Switch } from "react-router-dom";
import reducer from "./reducer";
import AppFrame from "./view/app-frame";
import PrivateRoute from './view/public/component/privateRoute'
import './App.css';
import thunk from 'redux-thunk'
import Login from './view/login'

const middleware = [thunk];

// const store = createStore(reducer, applyMiddleware(...middleware));

const getMiddleware = () => {
  if (process.env.NODE_ENV === "development") {
      return composeWithDevTools(applyMiddleware(...middleware))
  }
      return compose(applyMiddleware(...middleware))

}

const store = createStore(reducer, getMiddleware());

const App = () => (
  <Provider store={store}>
    <Router basename={process.env.NODE_ENV === "development"? null : '/testfield'} >
      <Switch>
        <Route path="/" exact component={Login}></Route>
        <Route path="/login" component={Login}></Route>
        <PrivateRoute  component={AppFrame} />
      </Switch>
    </Router>
  </Provider>
);

export default App;
