import React, { Component } from 'react'
import { DatePicker } from 'antd';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';

moment.locale('zh-cn');

export default class DateRange extends Component {
    state = {
        endOpen: false,
    };

    disabledStartDate = (startValue) => {
        return startValue.valueOf() > moment().valueOf()
    }

    disabledEndDate = (endValue) => {
        const startValue = this.props.startValue;
        //console.log(startValue.startOf('day').format("YYYY-MM-DD HH:mm"))
        if (!endValue || !startValue) {
            return false;
        }
        //console.log(startValue.format("YYYY-MM-DD HH:mm:ss"))
        //console.log(moment(startValue.valueOf() + 60*60*1000).format("YYYY-MM-DD HH:mm:ss"))
        //console.log(moment(startValue.valueOf()).format("YYYY-MM-DD HH:mm:ss"))
        //禁止选择结束日期中小于起始日期零点的日期 禁止选择结束日期中大于当前日期23:59:59的日期
        //return endValue.valueOf() <= startValue.valueOf() || endValue.valueOf() > moment().valueOf() || startValue.valueOf() + 60*60*1000 <= endValue.valueOf()
        if (endValue.clone().endOf('day').valueOf() <= startValue.clone().startOf('day').valueOf()) {
            return true
        } else if (endValue.clone().startOf('day').valueOf() > moment().clone().endOf('day').valueOf()) {
            return true
        } else if (endValue.clone().endOf('day').valueOf() > moment(startValue.valueOf() + 60 * 60 * 1000).clone().endOf('day').valueOf()) { //如果日期大于起始时间后一小时所在的日期
            return true
        }

    }

    onChange = (field, value) => {
        this.setState({
            [field]: value,
        });
    }

    onStartChange = (value) => {
        //this.onChange('startValue', value);
        if (value && value.valueOf() > moment().valueOf()) {
            return;
        }
        this.props.onStartChange(value)
    }

    onEndChange = (value) => {
        //this.onChange('endValue', value);
        /* if (value.valueOf() < this.props.startValue.valueOf() || value.valueOf() > this.props.startValue.valueOf() + 60 * 61 * 1000 || value.valueOf() > moment().valueOf()) {
            console.log(value.valueOf() < this.props.startValue.valueOf(),"value.valueOf() < this.props.startValue.valueOf() ",value.valueOf(),this.props.startValue.valueOf())
            console.log(value.valueOf() > this.props.startValue.valueOf() + 60 * 61 * 1000,
            "value.valueOf() > this.props.startValue.valueOf() + 60 * 61 * 1000",
            value.valueOf(),this.props.startValue.valueOf() + 60 * 61 * 1000
            )
            console.log(value.valueOf() > moment().valueOf(),
            'value.valueOf() > moment().valueOf()',
            value.valueOf(),
            moment().valueOf()
            )
            return;
        } */

        if(value.valueOf() > this.props.startValue.valueOf() + 60 * 61 * 1000 || value.valueOf() > moment().valueOf()){
            this.props.onEndChange(moment(this.props.startValue.valueOf() + 60 * 60 * 1000))
            return ;
        }else if(value.valueOf() < this.props.startValue.valueOf()){
            this.props.onEndChange(this.props.startValue)
        }else{
            this.props.onEndChange(value)
        }
    }

    handleStartOpenChange = (open) => {
        if (!open && !this.props.disableEnd) {
            this.setState({ endOpen: true });
            if(!this.props.endValue || this.props.endValue.valueOf() > this.props.startValue.valueOf() + 60*60*1000 || this.props.startValue.valueOf() > this.props.endValue.valueOf()){
                this.props.onEndChange(this.props.startValue)
            }
        }
    }

    handleEndOpenChange = (open) => {
        if (!this.props.endValue) {
            this.props.onDateClear()
        }
        this.setState({ endOpen: open });
    }


    /**
     *禁用：1.起始时间之前的小时。2.起始时间下一小时之后的小时。3.如果起始日期是当天，禁止大于当前小时的小时
     *
     * @memberof DateRange
     */
    disabledEndHour = () => {
        const { startValue, endValue } = this.props;
        //console.log(endValue)
        if (!endValue) {
            return;
        }

        const hour = Number(moment(startValue).format('HH')); //起始时间的小时
        const disabledHour = [];

        //结束日期和开始日期不是同一天，即开始日期选择当天的23点以后
        if (endValue.format('YYYY-MM-DD') !== startValue.format('YYYY-MM-DD')) {
            for (let i = 1; i < 24; i++) {
                disabledHour.push(i);//因为一小时限制，所以跨日只能选到0点
            }
            return disabledHour;
        }

        //以下默认都是在同一日
        //开始日期和结束日期在同一日
        if (startValue.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {//如果起始日期是今天
            const nowHour = Number(moment().format('HH'))
            for (let i = 0; i < 24; i++) {
                if ((i !== hour && i !== hour + 1) || i > nowHour) {
                    disabledHour.push(i)
                }
            }
        } else {
            for (let i = 0; i < 24; i++) {
                if ((i !== hour && i !== hour + 1)) {
                    disabledHour.push(i)
                }
            }
        }

        return disabledHour
    }

    disableEndTime = () => {
        const { startValue, endValue } = this.props;
        const hour = Number(moment(startValue).format('HH'));
        const minute = Number(moment(startValue).format('mm'));
        const nowHour = Number(moment().format('HH'))

        // const disabledHour = [];

        /* for (let i = 0; i < 24; i++) {
            if ((i !== hour && i !== hour + 1) || i > nowHour) {
                disabledHour.push(i)
            }
        } */

        return {
            disabledHours: this.disabledEndHour,
            disabledMinutes: (selectedHour) => {
              const nowMinute = Number(moment().format('mm'))
                if (selectedHour === hour) { //如果结束时间选择的和开始时间是同一时间
                    const minuteArr = [];

                    // console.log(minute)
                    // console.log(nowMinute)
                    if (endValue.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
                        for (let i = 0; i < 60; i++) {
                            /* if (i < minute || i > nowMinute) {
                                minuteArr.push(selectedHour)
                            } */
                            if (selectedHour === nowHour) {
                                if (i < minute || i > nowMinute) {
                                    minuteArr.push(i)
                                }
                            } else {
                                if (i < minute) {
                                    minuteArr.push(i)
                                }
                            }
                        }
                        return minuteArr;
                    } else {
                        for (let i = 0; i < 60; i++) {
                            if (i < minute) {
                                minuteArr.push(i)
                            }
                        }
                        return minuteArr;
                    }
                } else if (selectedHour === hour + 1 || (hour === 23 && selectedHour === 0)) {
                    const minuteArr = []

                    if (endValue.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
                        for (let i = 0; i < 60; i++) {
                            /* if (i > minute) {
                                minuteArr.push(selectedHour)
                            } */
                            if (selectedHour === nowHour) {
                                if (i > minute || i > nowMinute) {
                                    minuteArr.push(i)
                                }
                            } else {
                                if (i > minute) {
                                    minuteArr.push(i)
                                }
                            }
                        }
                        //console.log(minuteArr)
                        return minuteArr;
                    } else {
                        for (let i = 0; i < 60; i++) {
                            if (i > minute) {
                                minuteArr.push(i)
                            }
                        }
                        //console.log(minuteArr)
                        return minuteArr;
                    }
                } /* else if (selectedHour === 0) {
                    const minuteArr = []
                    if (endValue.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
                        for (let i = 0; i < 60; i++) {
                            if (selectedHour === nowHour) {
                                if (i > minute || i > nowMinute) {
                                    minuteArr.push(i)
                                }
                            } else {
                                if (i > minute) {
                                    minuteArr.push(i)
                                }
                            }
                        }
                    }else{
                        for (let i = 0; i < 60; i++) {
                            if (i > minute) {
                                minuteArr.push(i)
                            }
                        }
                        //console.log(minuteArr)
                        return minuteArr;
                    }
                } */
            }
        }
    }

    render() {
        const { startValue, endValue, disableEnd } = this.props;
        const { endOpen } = this.state;

        return (
            <div>
                <DatePicker
                    //disabledDate={this.disabledStartDate}
                    locale={locale}
                    showTime
                    format="YYYY-MM-DD HH:mm"
                    value={startValue}
                    placeholder="选择开始时间"
                    onChange={this.onStartChange}
                    onOpenChange={this.handleStartOpenChange}
                    showTime={{
                        format: 'HH:mm'
                    }}
                    disabledDate={this.disabledStartDate}
                />
                <span> ~ </span>
                <DatePicker
                    locale={locale}
                    disabledDate={this.disabledEndDate}
                    disabledTime={this.disableEndTime}
                    showTime
                    format="YYYY-MM-DD HH:mm"
                    value={endValue}
                    placeholder="选择结束时间"
                    onChange={this.onEndChange}
                    open={endOpen}
                    onOpenChange={this.handleEndOpenChange}
                    disabled={disableEnd}
                    showTime={{
                        format: 'HH:mm',
                        hideDisabledOptions: true,
                        defaultValue:startValue
                    }}
                />
            </div>
        );
    }
}