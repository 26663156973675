import React from 'react'
import {Table,Input,Button,Checkbox,Row,Col,Select,message,Modal,Form } from 'antd'
import { withRouter } from "react-router-dom";
import {schemeAPI} from "../../../api/schemeAPI";
import ViewTestScheme from './viewTestScheme'
import TestSchemeDialog from './testSchemeDialog'
import faker from "faker";
import searchIcon from './img/search.png'
import deleteIcon from './img/delete.png'
import styles from './style.module.css'
import { sceneInfo } from "../../../api/sceneInfo";
import { format } from '../../../utils/formatTime'
import axios from "axios/index";
import Header from '../../public/component/header'

const Option = Select.Option;
const confirm = Modal.confirm;

@withRouter
class testScheme extends React.Component{
  state={
    schemeList:[],
    schemeLen:0,
    viewVisible:false,
    operaVisible:false,
    addOrUpdateTitle:'',
    operaDialogVisible:false,
    viewDialogVisible:false,
    operaType:'',
    updateSchemeInfo:null,
    sceneList:[],
    showExpandedColumn:false
  }

  componentDidMount (){
    this.getSchemeInfoList({})
    this.getSceneList()
  }

  getSchemeInfoList=(data)=>{
    schemeAPI.getSchemeList(data).then(res=>{
      if(res.data.code === 200){
        this.setState({schemeList:res.data.data.list,schemeLen:res.data.data.list.length})
      }
    })
  }

  getSceneList=()=>{
    sceneInfo.getSceneList().then(res=>{
        if(res.data.code === 200){
          this.setState({sceneList:res.data.data.list})
        }
    })
  }

  viewSchemeInfo=(text)=>{
    let data={id:text.id}
    this.getSchemeInfoById(data)
    this.setState({viewDialogVisible:true})
  }

  getSchemeInfoById=(data)=>{
    let that = this
    schemeAPI.getSchemeInfoById(data).then(res=>{
      if(res.data.code === 200){
        that.setState({updateSchemeInfo:res.data.data})
      }
    })
  }

  deleteScheme=(text)=>{
    let that = this
    confirm({
      title: `确定要删除${text.id}这条记录吗?`,
      okText:'确定',
      cancelText:'取消',
      onOk() {
        const data ={id:text.id}
        schemeAPI.deleteScheme(data).then(res=>{
          if(res.data.code === 200){
            message.success("删除成功!")
            that.getSchemeInfoList({})
          }else{
            message.error("删除失败!")
          }
        })
      },
      onCancel() {

      },
    });


  }

  addTestSchemeHandle=()=>{
    this.setState({addOrUpdateTitle:'创建测试方案',operaDialogVisible:true,operaType:'create'})
  }


  editChange=(flag,schemeInfo)=>{
    this.setState({addOrUpdateTitle:'编辑测试方案',operaDialogVisible:flag,updateSchemeInfo:schemeInfo,operaType:'update'})
  }

  changModalVisible=(flag)=>{
    this.getSchemeInfoList({})
    this.setState({operaDialogVisible:flag})
  }

  searchScheme=()=>{

    this.props.form.validateFields((err, values) => {
      if (!err) {
        let data ={}
        if(values.id){
          data.id=values.id
        }
        if(values.name){
          data.name=values.name
        }
        if(values.sceneId){
          data.sceneId=values.sceneId
        }
        schemeAPI.searchSchemeList(data).then(res=>{
          if(res.data.code === 200){
            this.setState({schemeList:res.data.data.list})
          }
        })
      }
    })


  }

  onChange=(e)=> {
    this.setState({showExpandedColumn:e.target.checked})
  }

  exportSchemeHandle=()=>{
    axios.post('/testSite/scheme/exportExcel',{},{responseType:'blob'}).then(res=>{
      const content = res.data
      const blob = new Blob([content])// 构造一个blob对象来处理数据
      const fileName = '方案信息.xlsx'

      if ('download' in document.createElement('a')) { // 支持a标签download的浏览器
        const link = document.createElement('a')// 创建a标签
        link.download = fileName// a标签添加属性
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        document.body.appendChild(link)
        link.click()// 执行下载
        URL.revokeObjectURL(link.href) // 释放url
        document.body.removeChild(link)// 释放标签
      } else { // 其他浏览器
        navigator.msSaveBlob(blob, fileName)
      }
    })

  }

  clearSeach=()=>{
    this.props.form.setFieldsValue({"sceneId":undefined,"name":null,"id":null})
  }

  render(){
    const {schemeList} = this.state
    let columns =[{
      title: "方案编号",
      dataIndex: 'id',
      key: 'id',
    },
      {
        title:"方案名称",
        dataIndex: 'name',
        key: 'name'
      },
      {
        title:"包含场景",
        dataIndex: 'sceneCount',
        key: 'sceneCount'
      }]

    if(this.state.showExpandedColumn)
    {
      columns =columns.concat([{
        title:"创建人",
        dataIndex: 'creater',
        key: 'creater',
      }, {
        title: "创建时间",
        dataIndex: 'createTime',
        key: 'createTime',
        render: createTime => {
          return format.formatTime(createTime)
        }
      },
        {
          title:"最后修改人",
          dataIndex: 'modifier',
          key: 'modifier',
          render: modifier => {
            if(modifier)
              return modifier
            else
              return '--'

          }
        }, {
          title: "最后修改时间",
          dataIndex: 'modifyTime',
          key: 'modifyTime',
          render: modifyTime => {
            if(modifyTime)
              return format.formatTime(modifyTime)
            else
              return '--'

          }
        }])
    }

    columns = columns.concat([{
      title:"操作",
      render:(text)=>{
        return (<div><span ><img src={searchIcon} alt="" title="查看" className={styles.operaIcon} onClick={this.viewSchemeInfo.bind(this,text)}/></span>&nbsp;&nbsp;<span ><img src={deleteIcon} alt="" title="删除" className={styles.operaIcon} onClick={this.deleteScheme.bind(this,text)}/></span></div>)
      }
    }])


    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <Header title="测试方案" ></Header>
          <div className={styles.pageCon}>
          <div className={styles.searchCon}>
            <div>
              <Form style={{display:'inline-block'}} layout='inline'>
              <Form.Item >
                {getFieldDecorator('id', {
                  rules: [{
                    required: false,
                    message: '请输入方案编号',
                  }],
                })(
                  <Input placeholder="请输入方案编号" className={styles.selectStyle} />
                )}
              </Form.Item>

              <Form.Item >
                {getFieldDecorator('name', {
                  rules: [{
                    required: false,
                    message: '请输入方案名称',
                  }],
                })(
                  <Input placeholder="请输入方案名称" className={styles.selectStyle} />
                )}
              </Form.Item>

                <Form.Item >
                  {getFieldDecorator('sceneId', {
                    rules: [{
                      required: false,
                      message: '选择测试项目',
                    }],
                  })(
                    <Select  className={styles.selectStyle} onChange={this.changeProjectSearch} placeholder="选择测试项目">
                      {this.state.sceneList.length > 0 && this.state.sceneList.map((v)=>{
                        return (
                          <Option value={v.id} key={v.id}>{v.name}</Option>
                        )
                      })}
                    </Select>
                  )}
                </Form.Item>



              <Button className={styles.searchBtn} onClick={this.searchScheme}>查询</Button>
              <Button className={styles.resetBtn} onClick={this.clearSeach}>重置</Button>
              </Form>
            </div>


          </div>

          <div className={styles.dataCon}>
            <div className={styles.operaCon}>
              <Row>
                <Col md={{ span: 24 }} xl={{ span: 12 }}>
                  测试方案<span className={styles.projectNum}>{this.state.schemeLen}</span>
                </Col>
                <Col md={{ span: 24 }} xl={{ span: 12 }} style={{textAlign:'right'}}>
                  <Checkbox onChange={this.onChange} className={styles.showRecord}>显示操作记录</Checkbox>
                  <Button onClick={this.exportSchemeHandle}>导出Excel</Button>
                  <Button icon="plus" className={styles.btnStyle} onClick={this.addTestSchemeHandle}>新增测试方案</Button>
                </Col>
              </Row>

            </div>
            <div className={styles.dataTable} >
              <Table rowKey={record=>record.id} dataSource={schemeList} columns={columns}  />
            </div>

          </div>

             <Modal
               title={<div><span>查看测试方案</span><span> <Button className="editBtn" onClick={()=>{this.editChange(true,this.state.updateSchemeInfo)}}>编辑</Button></span></div>}
              visible={this.state.viewDialogVisible}
              footer={null}
              destroyOnClose={true}
              onCancel={()=>{this.setState({viewDialogVisible:false,updateSchemeInfo:null})}}
              zIndex="10"
            >
               {this.state.updateSchemeInfo && <ViewTestScheme schemeInfo={this.state.updateSchemeInfo} editChange={this.editChange}></ViewTestScheme>}
            </Modal>

            <Modal
              title={this.state.addOrUpdateTitle}
              visible={this.state.operaDialogVisible}
              footer={null}
              width="40%"
              destroyOnClose={true}
              onCancel={()=>{this.setState({operaDialogVisible:false})}}

            >
              <TestSchemeDialog operaType={this.state.operaType}  schemeInfo={this.state.updateSchemeInfo} changModalVisible={this.changModalVisible} getSchemeInfoById={this.getSchemeInfoById}></TestSchemeDialog>


            </Modal>



        </div>
      </div>)
  }
}

const WrapTestScheme = Form.create()(testScheme)
export default WrapTestScheme
