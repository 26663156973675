import {post} from './request'

const testPlanAPI ={
  getPlanList(data){
    return post("/testSite/record/findPlan",data)
  },
  getPlanById(data){
    return post("/testSite/record/findPlanById",data)
  },
  addPlan(data){
    return post("/testSite/record/addPlan",data)
  },
  editPlan(data){
    return post("/testSite/record/updatePlan",data)
  },
  updatePlanStatus(data){
    return post("/testSite/record/updatePlanStatus",data)
  },
  exportPlan(data){
    return post("/testSite/record/exportPlan",data)
  }

}
export  {testPlanAPI}