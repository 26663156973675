import {post} from './request'

const sceneInfo ={
  getSceneList(){
    return post("/testSite/scene/find",{})
  },
  deleteScene(data){
    return post("/testSite/scene/del",data)
  },
  getProjectList(){
    return post("/testSite/project/findIncludeCount",{})
  },
  addProject(data){
    return post("/testSite/project/add",data)
  },
  editProject(data){
    return post("/testSite/project/edit",data)
  },
  deleteProject(data){
    return post("/testSite/project/del",data)
  },
  addScene(data){
    return post("/testSite/scene/add",data)
  },
  getSceneInfoById(data){
    return post("/testSite/scene/findById",data)
  },
  editSceneInfo(data){
    return post("/testSite/scene/edit",data)
  },
  searchSceneById(data){
    return post("/testSite/scene/findById",data)
  },
  searchSceneByProjectId(data){
    return post("/testSite/scene/findByProjectId",data)
  },
  sceneDelImage(data){
    return post("/testSite/scene/delImage",data)
  },
  getMarkByTestFieldId(data){
    return post("/testSite/testField/getMarkByTestFieldId",data)
  }

}
export  {sceneInfo}