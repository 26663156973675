import React from 'react'
import styles from './fsHeader.module.css'
import { Icon } from 'antd'
import Clock from './digitalClock'

export default class FSHeader extends React.Component {
    state = {
        isFullScreen: false
    }

    onFullScreenClick = () => {
        this.setState({
            isFullScreen:true
        })

        if(this.props.fullScreen){
            this.props.fullScreen()
        }
    }

    onFullScreenCancel = () => {
        this.setState({
            isFullScreen:false
        })

        if(this.props.fullScreenCancel){
            this.props.fullScreenCancel()
        }
    }

    render() {
        return (
            <header className={styles.header}>
                {this.state.isFullScreen?<Icon className={styles.fullScreenIcon} onClick={this.onFullScreenCancel} type="shrink" title="返回" />:<Icon onClick={this.onFullScreenClick} className={styles.fullScreenIcon} type="arrows-alt" title="全屏"/>}
                <span>{this.props.title}</span>
                <div className={styles.digitalClock}>
                    <Clock></Clock>
                </div>
            </header>
        )
    }
}