import React from 'react'
import { Icon, Modal } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import Header from '../../public/component/header'
import styles from './styles.module.css'
import * as actions from '../../../reducer/siteConfig/actions'
import moment from 'moment'
import Bmap from './BMap'
import EditPane from './editPane'
import { QDTestFieldId } from '../../public/testFieldID'

const allHeaderTitle = ['测试场编号', '场地名称', '所属部门', '测试场类型', '交通设施', '创建人', '创建时间', '最后修改人', '最后修改时间']
const TableCellWidth = ['10%', '10%', '10%', '10%', '10%', '10%', '15%', '10%', '15%']

const siteType = {
  "0": '封闭测试场',
  "1": "开放测试场"
}

@withRouter
class SiteConfig extends React.Component {
  state = {
    hideRseName: false,
    modalVisible:false,
    modalMode:null,
    markerInfo:null
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(actions.getAllMarkerInfo());
    dispatch(actions.getSiteInfo(QDTestFieldId));
  }

  static getTableContent = (testField) => {
    const { testFieldNo, testFieldName, departmentName, testFieldType, markCount, creater, createTime, modifier, modifierTime } = testField
    const contentArr = [
      testFieldNo,
      testFieldName,
      departmentName,
      siteType[testFieldType],
      `${markCount} 个`,
      creater,
      moment(createTime).format('YYYY-MM-DD HH:mm:ss'),
      modifier,
      moment(modifierTime).format('YYYY-MM-DD HH:mm:ss')
    ]

    return (
      contentArr.map((el, index) => <span
        key={index}
        style={{
          width: TableCellWidth[index],
          color: index === 1 ? '#3E3F42' : '#9EA0A5',
          fontWeight: index === 1 ? 'bold' : 'normal',
        }}
        className={styles.infoTableBodyText}
        title={el}
      >{el}</span>)
    )
  }

  rseNameShowChange = (e) => {
    this.setState({
      hideRseName: e.target.checked
    })
  }

  onAddNewClick = () => {
    this.setState({
      modalVisible:true,
      modalMode:'add'
    })
  }

  modalCancel = () => {
    const { dispatch } = this.props;

    this.setState({
      modalVisible:false,
      modalMode:null,
      markerInfo:null
    })

    //setTimeout(() => {
      dispatch(actions.getSiteInfo(QDTestFieldId));
    //},1000)
  }

  onMarkerClick = (markerInfo) => {
    this.setState({
      modalMode:'view',
      modalVisible:true,
      markerInfo
    })
  }

  render() {

    const { testField } = this.props

    return (
      <div id="sitConfig" className={styles.main}>
        <Header title={'场地配置'} />
        <div className={styles.content}>

          <div className={styles.infoCard}>
            <div className={styles.infoTitle}>智能网联汽车测试场</div>

            <div className={styles.infoTableHeader}>
              {
                allHeaderTitle.map((title, index) => <span key={index} style={{ width: TableCellWidth[index] }} className={styles.infoTableHeaderText}>{title}</span>)
              }
            </div>

            <div className={styles.infoTableBody}>
              {
                testField && SiteConfig.getTableContent(testField)
              }
            </div>
          </div>

          <div className={styles.mapCard}>
            <div className={styles.mapHeader}>

              {testField && <div className={styles.mapHeaderLeft}>
                <div className={styles.headerItemCon}>
                  <span className={styles.headerItemTitle}>测试场编号:</span>
                  <span className={styles.headerItemText}>{testField.testFieldNo}</span>
                </div>
                <div className={styles.headerItemCon}>
                  <span className={styles.headerItemTitle}>测试场名称:</span>
                  <span className={styles.headerItemText}>{testField.testFieldName}</span>
                </div>
                <div className={styles.headerItemCon}>
                  <span className={styles.headerItemTitle}>测试场类型:</span>
                  <span className={styles.headerItemText}>{siteType[testField.testFieldType]}</span>
                </div>
                <div className={styles.headerItemCon}>
                  <span className={styles.headerItemTitle}>测试场面积:</span>
                  <span className={styles.headerItemText}>{testField.area} m²</span>
                </div>

                {/* <Checkbox
                  checked={this.state.hideRseName}
                  onChange={this.rseNameShowChange}
                  style={{
                    fontSize: 12,
                    lineHeight: '40px',
                    width: 'auto',
                    textAlign: 'center'
                  }}
                >隐藏RSE ID</Checkbox> */}
              </div>}

              <div id="siteHeader" className={styles.mapHeaderRight}>
                <button onClick={this.onAddNewClick} className={styles.greenButton} >
                  <Icon style={{ fontSize: 16 }} type="plus" />
                  新增交通设施
                </button>

                {/* <button className={styles.silverButton}>
                  编辑
                </button> */}
              </div>
            </div>

            <div className={styles.mapBodyCon}>
              {!this.state.modalVisible && <Bmap key="siteBmap" onMarkerClick={this.onMarkerClick}></Bmap>}
            </div>

          </div>

        </div>

        <Modal
          title=""
          centered
          footer={null}
          visible={this.state.modalVisible}
          onCancel={this.modalCancel}
          bodyStyle={{minHeight:700,width:'auto',padding:0}}
          closable={false}
          width="auto"
          destroyOnClose
        >
            <EditPane 
              onClose={this.modalCancel} 
              mode={this.state.modalMode}
              markerInfo={this.state.markerInfo}
              ></EditPane>
        </Modal>
        

      </div>)
  }
}

const mapStateToProps = (state) => {
  const { siteInfo } = state.siteConfig;

  return {
    testField: siteInfo.testField
  }
}

export default connect(mapStateToProps)(SiteConfig)