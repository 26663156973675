import React from 'react'
import styles from './style.module.css'
import {
  Table, Input, Button, Popconfirm, Form,Icon,message,Modal,Select
} from 'antd';
import sceneIcon from './img/sceneNum.png'
import deleteIcon from './img/delete.png'
import {sceneInfo} from '../../../api/sceneInfo'

const { Option } = Select;
const FormItem = Form.Item;
const confirm = Modal.confirm;
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);
const projectCategoryList = [{id:0,name:'V2X'},{id:1,name:'AD'}];

class EditableCell extends React.Component {
  state = {
    editing: false,
  }

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  }

  save = () => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  }

  render() {
    const { editing } = this.state;
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      ...restProps
    } = this.props;
    return (
      <td ref={node => (this.cell = node)} {...restProps}>
        {editable ? (
          <EditableContext.Consumer>
            {(form) => {
              this.form = form;
              return (
                editing ? (
                  <FormItem style={{ margin: 0 }}>
                    {form.getFieldDecorator(dataIndex, {
                      rules: [{
                        required: true,
                        message: `${title} is required.`,
                      }],
                      initialValue: record[dataIndex],
                    })(
                      <Input
                        ref={node => (this.input = node)}
                        onPressEnter={this.save}
                        onBlur={this.save}

                      />
                    )}
                  </FormItem>
                ) : (
                  <div
                    className="editable-cell-value-wrap"
                    style={{ paddingRight: 24 }}
                    onClick={this.toggleEdit}
                  >
                    {restProps.children}
                    <Icon type="edit" style={{ color: 'rgba(0,0,0,.25)',marginRight:'20px',float:'right' }} />
                  </div>
                )
              );
            }}
          </EditableContext.Consumer>
        ) : restProps.children}
      </td>
    );
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props);

    this.columns = [
    {
      title: '项目编号',
      dataIndex: 'id',
      width: '30%'
    }, {
      title: '项目名称',
      dataIndex: 'name',
      editable: true,
    }, {
      title: '项目类别',
      dataIndex: 'category',
      //editable: true,
      render: (text, record) => (
        // ( record.category==0)?'V2X':'AD'
        <Select placeholder="请选择项目类别" style={{width:'100%'}} defaultValue={record.category} onChange={(value)=>this.handleChangeProjectCategoryList(value,record)}>
           {
             projectCategoryList.map((v)=>{
               return <Option value={v.id} key={v.id}>{v.name}</Option>
             })
           }
         </Select>
      ),
      // render:(category)=>{
        // if(category==0){
        //   return 'V2X'
        // }else if(category==1){
        //   return 'AD'
        // }else{
        //   return '未知'
        // }
       // <Select placeholder="请选择所属项目" style={{width:'100%'}}>
       //    {
       //      projectCategoryList.map((v)=>{
       //        return <Option value={v.id} key={faker.name.findName()}>{v.name}</Option>
       //      })
       //    }
       //  </Select>
      // }
    },{
      title: '包含场景数量',
      dataIndex: 'sceneCount',
    }, {
      title: '操作',
      dataIndex: 'operation',
      render: (text, record) => (
        this.state.projectList.length >= 1
          ? (
          <img src={deleteIcon} alt="" title="删除" className={styles.operaIcon} onClick={() => this.handleDelete(record)}/>
          ) : null
      ),
    }];

    this.state = {
      projectList:[],
      count: 0,
    };
  }

  componentDidMount(){
    this.getProjectInfo()
  }

  getProjectInfo=()=>{
    sceneInfo.getProjectList().then(res=>{
      if(res.data.code === 200){
        let tempArray =[]
        for(let i=0;i<res.data.data.list.length;i++){
            tempArray.push({...res.data.data.list[i],key:i})
        }
        this.setState({projectList:tempArray,count:res.data.data.list.length})
        //console.log(this.state.projectList,"this.state.projectList")
      }
    })
  }

  editProjectHandle=(data)=>{
    sceneInfo.editProject(data).then(res=>{
      if(res.data.code === 200){
        //message.success("编辑成功!")
        this.getProjectInfo()
      }else{
        message.error(res.data.msg)
      }
    })
  }

  addProjectHandle=(data)=>{
    sceneInfo.addProject(data).then(res=>{
      if(res.data.code === 200){
        //message.success("添加成功!")
        this.getProjectInfo()
      }else{
        message.error(res.data.msg)
      }
    })
  }

  deleteProjectHandle=(data)=>{
    sceneInfo.deleteProject(data).then(res=>{
      if(res.data.code === 200){
        this.getProjectInfo()
      }else{
        message.error("删除失败!")
      }
    })
  }

  handleDelete = (record) => {
    let that = this
    if(record.id){
      confirm({
        title: `确定要取消${record.id}这条记录吗?`,
        okText:'确定',
        cancelText:'取消',
        onOk() {
          const dataSource = [...that.state.projectList];
          that.setState({ projectList: that.state.projectList.filter(item => item.key !== record.key) });
          const data={id:record.id}
          that.deleteProjectHandle(data)
        },
        onCancel() {

        },
      });
    }else{
      const dataSource = [...that.state.projectList];
      that.setState({ projectList: that.state.projectList.filter(item => item.key !== record.key) });
    }

  }

  handleAdd = () => {
    const { count, projectList } = this.state;
    const newData = {
      key:count,
      name: `--`,
      sceneCount: 0
    };
    this.setState({
      projectList: [newData,...projectList],
      count:count+1
    });
  }

  CustomExpandIcon=(props)=> {
    let icon;
    if (props.expanded) {
      icon = "folder-open"
    } else {
      icon = "folder-add"
    }
    return (
      <div>
        <Icon type={icon} onClick={e => props.onExpand(props.record, e)} style={{color:'#9ea0a5',fontSize:'20px'}} />
      </div>
    );
  }

  handleChangeProjectCategoryList =(value,row) =>{
    console.log("row===",row);
    console.log("value===",value);
    // if(value)
    row.category = value
    this.handleSave(row)
  }

  handleSave = (row) => {
    const newData = [...this.state.projectList];
    if(!row.name || row.name ==="" || row.name ==="--")
    {
      message.warning("项目不能为空!")
      return
    }
    const index = newData.findIndex(item => row.key === item.key);

    const item = newData[index];

    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ projectList: newData });
    if(!row.id){
      const data = {name:row.name,category:row.category}
      this.addProjectHandle(data)

    }else{
      const data = {id:row.id,name:row.name,category:row.category}
      this.editProjectHandle(data)
    }
    /* return
    const index = newData.findIndex(item => row.key === item.key);

    const item = newData[index];

    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ projectList: newData }); */
  }

  componentWillUnmount(){
    this.setState({projectList:[]})
  }

  render() {
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    return (
      <div>
        <div style={{ marginBottom: 16,textAlign:'right'}}>
        <Button icon="plus" className={styles.btnStyle} onClick={this.handleAdd} >新增测试项目</Button>
        </div>
        <div className={styles.dataTable}>
        <Table
          pagination={false}
          expandIcon={this.CustomExpandIcon}
          components={components}
          dataSource={this.state.projectList}
          columns={columns}
          expandedRowRender={record => <div>{record.scenes &&
            record.scenes.map((v)=>{
            return (<p key={v.id} style={{marginBottom:'20px'}}><img src={sceneIcon}/><span style={{marginLeft:'10%'}}>{v.name}</span></p>)})
          }</div>}
        />
        </div>
      </div>
    );
  }
}

export default EditableTable
