import React from 'react'
import {Form,Row,Col,Input,Select,DatePicker,Button,message} from 'antd'
import styles from './style.module.css'
import faker from "faker";
import icon1 from './img/1.png'
import icon2 from './img/2.png'
import icon3 from './img/3.png'



class ViewTestVehicle extends React.Component{
  state={
    vehicleInformation:null
  }

  componentDidMount(){

    const {testVehicleInfo} = this.props
    // console.log(testVehicleInfo)
    this.setState({vehicleInformation:testVehicleInfo})

  }

  componentWillReceiveProps(nextProps){
    if(this.props.testVehicleInfo !== nextProps.testVehicleInfo){
      this.setState({vehicleInformation:nextProps.testVehicleInfo})
    }

  }




  render(){
    return (
      <div>
        {/* <div className={styles.editSceneBtnCon}>
          <Button onClick={()=>{this.props.editChange(true,this.props.testVehicleInfo)}} className={styles.editPlanBtn}>编辑</Button>
        </div> */}
          <div className={styles.planItemCon}>
            <div className={styles.planTitleCon}>
              <img src={icon1}/><span className={styles.planTitle}>基础信息</span>
            </div>
            <Row gutter={10}>
              <Col span={12} className={styles.viewColSpace}>
                <span className={styles.txtBold}>商标:</span><span>{this.state.vehicleInformation?this.state.vehicleInformation.brand:'--'}</span>
              </Col>
              <Col span={12} className={styles.viewColSpace}>
                <span className={styles.txtBold}>型号:</span><span>{this.state.vehicleInformation?this.state.vehicleInformation.model:'--'}</span>
              </Col>
              <Col span={24} className={styles.viewColSpace}>
                <span className={styles.txtBold}>VIN码(车辆识别代码):</span><span>{this.state.vehicleInformation?this.state.vehicleInformation.vinNumber:'--'}</span>
              </Col>

              <Col span={12} className={styles.viewColSpace}>
                <span className={styles.txtBold}>车牌号码:</span><span>{this.state.vehicleInformation?this.state.vehicleInformation.licensePlate:'--'}</span>
              </Col>
              <Col span={12} className={styles.viewColSpace}>
                <span className={styles.txtBold}>车辆类型:</span><span>
                {
                  this.state.vehicleInformation && this.state.vehicleInformation.type === 0 &&<span>乘用车</span>
                }
                {
                  this.state.vehicleInformation && this.state.vehicleInformation.type === 1 &&<span>商用车</span>
                }
                {
                  this.state.vehicleInformation && this.state.vehicleInformation.type === 2 &&<span>无人小巴</span>
                }
                {
                  this.state.vehicleInformation && this.state.vehicleInformation.type === 3 &&<span>无人大巴</span>
                }
                {
                  this.state.vehicleInformation && this.state.vehicleInformation.type === 4 &&<span>无人卡车</span>
                }
                {
                  this.state.vehicleInformation && this.state.vehicleInformation.type === 5 &&<span>无人驾驶车辆</span>
                }

                  </span>
              </Col>
            </Row>
          </div>

          <div className={styles.planItemCon}>
            <div className={styles.planTitleCon}>
              <img src={icon2}/><span className={styles.planTitle}>智能属性</span>
            </div>
            <Row gutter={10} >
              <Col span={24} className={styles.viewColSpace}>
                <span className={styles.txtBold}>自动驾驶最高车速:</span><span>{this.state.vehicleInformation?this.state.vehicleInformation.adMaxSpeed:'--'}</span>
              </Col>
              <Col span={24} className={styles.viewColSpace}>
                <span className={styles.txtBold}>是否采用网联技术:</span><span>{this.state.vehicleInformation?(this.state.vehicleInformation.isV2x?'是':'否'):'--'}</span>
              </Col>
              <Col span={24} className={styles.viewColSpace}>
                <span className={styles.txtBold}>是否采用高精地图:</span><span>{this.state.vehicleInformation?(this.state.vehicleInformation.isPreciseMap?'是':'否'):'--'}</span>
              </Col>


            </Row>
          </div>


    </div>)
  }

}

export default ViewTestVehicle