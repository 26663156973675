import {post} from './request'
// 背景车辆接口
const VTAPI ={
  getVTList(data){
    return post("/testSite/vehicle/find",data)
  },
  getVTIds(){
    return post("/testSite/vehicle/findIds",{})
  },
  getVTById(data){
    return post("/testSite/vehicle/findById",data)
  },
  addVT(data){
    return post("/testSite/vehicle/add",data)
  },
  updateVT(data){
    return post("/testSite/vehicle/update",data)
  },
  deleteVT(data){
    return post("/testSite/vehicle/del",data)
  },
  statisticAll(data){
    return post("/testSite/vehicle/statisTotal",data)
  }
}
export  {VTAPI}