import React from 'react'
import { withRouter } from "react-router-dom";
import TableView from './tableView'
import PlayBackView from './playbackView'

@withRouter
class testAudit extends React.Component{
  state={
    currentView:'table',
    playbackId:null
  }

  componentDidMount (){
    
  }

  openPlayBack = (id) => {
    this.setState({
      currentView:'playback',
      playbackId:id
    })
  }

  backToTable = () => {
    this.setState({
      currentView:'table',
      playbackId:null
    })
  }

  render(){
    const { currentView, playbackId } = this.state
    return (
    <div style={{width:'100%',height:'100%'}}>
      
      <TableView
        currentView={currentView}
        openPlayBack={this.openPlayBack}
      >
      </TableView>
      {currentView === 'playback' && <PlayBackView 
        backToTable={this.backToTable}
        playbackId={playbackId}
        >
      </PlayBackView>}     
    </div>)
  }
}
export default testAudit