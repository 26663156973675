import {post} from './request'

const schemeAPI ={
  getSchemeList(data){
    return post("/testSite/scheme/find",data)
  },
  deleteScheme(data){
    return post("/testSite/scheme/del",data)
  },
  getSchemeInfoById(data){
    return post("/testSite/scheme/findByIdWithList",data)
  },
  addScheme(data){
    return post("/testSite/scheme/add",data)
  },
  editScheme(data){
    return post("/testSite/scheme/edit",data)
  },
  searchSchemeList(data){
    return post("/testSite/scheme/findByNameId",data)
  },
  isBackgroundCar(data){
    return post("/testSite/scheme/isBackgroundCar",data)
  }

}
export  {schemeAPI}