import React from 'react'
import {Table,Select,Button,Row,Col,Checkbox,Modal,message  } from 'antd'
import { withRouter } from "react-router-dom";
import EditableTable from './editableTable'
import ViewTestScene from './viewTestScene'
import TestSceneDialog from './testSceneDialog'
import styles from './style.module.css'
import {sceneInfo} from '../../../api/sceneInfo'
import searchIcon from './img/search.png'
import deleteIcon from './img/delete.png'
import BDMap from './bdMap'
import faker from 'faker'
import { format } from '../../../utils/formatTime'
import axios from "axios/index";
import Header from '../../public/component/header'
import { testPlanAPI } from "../../../api/testPlanAPI";

const Option = Select.Option;
const confirm = Modal.confirm;

@withRouter
class SceneConfig extends React.Component{
  state={
    dropDownSceneList:[],
    sceneList:[],
    projectList:[],
    projectDialogShow:false,
    projectDialogTitle:null,
    viewType:1,  //1代表List 0 代表Map,
    dialogWidth:'60%',
    visible:false,
    sceneId:null,
    viewDialogVisible:false,
    operaType:null,
    updateSceneInfo:null,
    sceneInfo:null,
    nowProjectSearchID:undefined,
    nowSceneSearchID:undefined,
    sceneCount:0,
    projectCount:0,
    showExpandedColumn:false
  }

  componentDidMount (){
    //console.log(this.props,"this.props")
    this.getSceneInfo()
    this.getProjectInfo()
  }

  getSceneInfo=()=>{
    sceneInfo.getSceneList().then(res=>{
      if(res.data.code === 200){
        this.setState({sceneList:res.data.data.list,dropDownSceneList:res.data.data.list})
        //console.log(res.data.data,"res.data.data")
      }
    })
  }

  getProjectInfo=()=>{
    sceneInfo.getProjectList().then(res=>{
      if(res.data.code === 200){
        this.setState({projectList:res.data.data.list})
        if(res.data.data.list && res.data.data.list.length > 0){
          this.setState({projectCount:res.data.data.list.length})
          let tmpCount =0
          for(let i=0;i<res.data.data.list.length;i++){
            tmpCount = tmpCount +res.data.data.list[i].sceneCount
          }
          this.setState({sceneCount:tmpCount})

        }

      }
    })
  }



  generateReport =()=>{
    this.props.history.push('/testResultReport');
  }

  changeProjectSearch=(value)=> {
    this.setState({nowProjectSearchID:value})
    // console.log(`selected ${value}`);
  }

  changeSceneSearch=(value)=> {
    this.setState({nowSceneSearchID:value})
    // console.log(`selected ${value}`);
  }

  searchScene=()=>{

    /* let data = null
    if(this.state.nowProjectSearchID && !this.state.nowSceneSearchID){
      data ={projectId:this.state.nowProjectSearchID}
      sceneInfo.searchSceneByProjectId(data).then(res=>{
        if(res.data.code === 200){
            this.setState({sceneList:res.data.data})
        }
      })
    }
    if(this.state.nowProjectSearchID && this.state.nowSceneSearchID || !this.state.nowProjectSearchID && this.state.nowSceneSearchID){
      data ={id:this.state.nowSceneSearchID}
      sceneInfo.searchSceneById(data).then(res=>{
        if(res.data.code === 200){
          let temp =[]
          temp.push(res.data.data)
          this.setState({sceneList:temp})
        }
      })
    } */

    let data = {}
    if(this.state.nowProjectSearchID){
      data.projectId = this.state.nowProjectSearchID
    }
    if(this.state.nowSceneSearchID){
      data.id = this.state.nowSceneSearchID
    }
    sceneInfo.searchSceneByProjectId(data).then(res=>{
      if(res.data.code === 200){
        this.setState({sceneList:res.data.data.list})
      }
    })

  }

  clearSearch=()=>{
      this.setState({nowProjectSearchID:undefined,nowSceneSearchID:undefined})
  }

  onChange=(e)=> {
    this.setState({showExpandedColumn:e.target.checked})
  }

  showProjectDialog=(type)=> {
    if (type === "create") {
      this.setState({ projectDialogShow: true,projectDialogTitle: '创建测试项目' })
      // this.getProjectInfo()
    }else{
      this.setState({ projectDialogShow: true,projectDialogTitle: '查看测试项目' })
      // this.getProjectInfo()
    }

  }

  hiddenDialog=()=>{
    this.setState({ projectDialogShow: false,projectDialogTitle: '' })
  }


  deleteScene=(text)=>{
    let that = this
    confirm({
      title: `确定要删除${text.id}这条记录吗?`,
      okText:'确定',
      cancelText:'取消',
      onOk() {
        const data = {id:text.id}
        sceneInfo.deleteScene(data).then(res=>{
          if(res.data.code === 200) {
            message.success('删除成功！');
            that.getSceneInfo()
            that.getProjectInfo()
          }else{
            message.error('删除失败！');
          }
        })
      },
      onCancel() {

      },
    });
  }

  changeListView=(type)=>{
    this.setState({viewType:type})
    this.searchScene()
  }

  changeDialogWidth=(num)=>{
      this.setState({dialogWidth:num})

  }

  changModalVisible=(flag)=>{
    this.getSceneInfo()
    this.getProjectInfo()
    this.setState({visible:flag})


  }

  editChange=(flag,sceneInfo)=>{
    //console.log(sceneInfo,"12232")
    this.setState({visible:flag,updateSceneInfo:sceneInfo,operaType:'update'})
  }

  viewSceneInfo=(text)=>{
    this.setState({sceneInfo:text,viewDialogVisible:true})
  }

  updateSceneInfoById=(ID)=>{
    let data={id:ID}
    sceneInfo.getSceneInfoById(data).then(res=>{
      if(res.data.code === 200){
        this.setState({sceneInfo:res.data.data})
      }
    })
  }

  exportSceneHandle=()=>{
    axios.post('/testSite/scene/exportExcel',{},{responseType:'blob'}).then(res=>{
      const content = res.data
      const blob = new Blob([content])// 构造一个blob对象来处理数据
      const fileName = '场景信息.xlsx'

      if ('download' in document.createElement('a')) { // 支持a标签download的浏览器
        const link = document.createElement('a')// 创建a标签
        link.download = fileName// a标签添加属性
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        document.body.appendChild(link)
        link.click()// 执行下载
        URL.revokeObjectURL(link.href) // 释放url
        document.body.removeChild(link)// 释放标签
      } else { // 其他浏览器
        navigator.msSaveBlob(blob, fileName)
      }
    })

  }

  render(){
    let columns =[{
      title: "场景编号",
      dataIndex: 'id',
      key: 'id',
    },
      {
        title:"场景名称",
        dataIndex: 'name',
        key: 'name'
      },
      {
        title:"所属项目",
        dataIndex: 'projectName',
        key: 'projectName',
        render:(projectName)=>{
          return (<span onClick={this.showProjectDialog.bind(this,"view")} style={{textDecoration:'underline'}}>{projectName}</span>)
        }
      },
      // {
      //   title:"类别",
      //   dataIndex: 'projectCategory',
      //   key: 'projectCategory',
      //   render:(projectCategory)=>{
      //     if(projectCategory==0){
      //       return 'V2X'
      //     }else if(projectCategory==1){
      //       return 'AD'
      //     }else{
      //       return '未知'
      //     }
      //
      //   }
      // }
    ]

    if(this.state.showExpandedColumn)
    {
      columns =columns.concat([{
        title:"创建人",
        dataIndex: 'creater',
        key: 'creater',
      }, {
        title: "创建时间",
        dataIndex: 'createTime',
        key: 'createTime',
        render: createTime => {
          return format.formatTime(createTime)
        }
      },
        {
          title:"最后修改人",
          dataIndex: 'modifier',
          key: 'modifier',
          render: modifier => {
            if(modifier)
              return modifier
            else
              return '--'

          }
        }, {
          title: "最后修改时间",
          dataIndex: 'modifyTime',
          key: 'modifyTime',
          render: modifyTime => {
            if(modifyTime)
              return format.formatTime(modifyTime)
            else
              return '--'

          }
        }])
    }
    columns = columns.concat([{
      title:"操作",
      key:faker.name.findName(),
      render:(text)=>{
        return (<div><span ><img src={searchIcon} alt="" title="查看" className={styles.operaIcon} onClick={this.viewSceneInfo.bind(this,text)}/></span>&nbsp;&nbsp;<span ><img src={deleteIcon} alt="" title="删除" className={styles.operaIcon} onClick={this.deleteScene.bind(this,text)}/></span></div>)
      }
    }])



    return (
      <div>
          <Header title={'测试场景配置'} ></Header>
          <div className={styles.pageCon}>
          <div className={styles.searchCon}>
            <div>
                <Select  className={styles.selectStyle} value={this.state.nowProjectSearchID} onChange={this.changeProjectSearch} placeholder="选择测试项目">
                  {this.state.projectList.map((v)=>{
                    return (<Option value={v.id} key={faker.name.findName()}>{v.name}</Option>)
                  })}
                </Select>
                <Select  className={styles.selectStyle} value={this.state.nowSceneSearchID} onChange={this.changeSceneSearch} placeholder="选择测试场景">
                  {this.state.dropDownSceneList.map((v)=>{
                    return (<Option value={v.id} key={faker.name.findName()}>{v.name}</Option>)
                  })}
                </Select>
                <Button className={styles.searchBtn} onClick={this.searchScene}>查询</Button>
                <Button className={styles.resetBtn} onClick={this.clearSearch.bind(this)}>重置</Button>
            </div>
            <div className={styles.viewWay}>
              <span className={styles.viewTitle}>查看方式</span>
              <span className={this.state.viewType ===1?styles.listViewSelect:styles.listViewDisabled} onClick={()=>this.changeListView(1)}>列表视角</span>
              <span className={this.state.viewType ===0?styles.mapViewSelect:styles.mapViewDisabled} onClick={()=>this.changeListView(0)}>地图视角</span>
            </div>


          </div>

          <div className={styles.dataCon}>
            <div className={styles.operaCon}>
              <Row>
                <Col md={{ span: 24 }} xl={{ span: 12 }}>
                  测试项目<span className={styles.projectNum}>{this.state.projectCount}</span>测试场景<span className={styles.projectNum}>{this.state.sceneCount}</span>
                </Col>
                <Col md={{ span: 24 }} xl={{ span: 12 }} style={{textAlign:'right'}}>
                  {this.state.viewType ===1 && <Checkbox onChange={this.onChange}  checked={this.state.showExpandedColumn} className={styles.showRecord}>显示操作记录</Checkbox>}
                  <Button onClick={this.exportSceneHandle}>导出Excel</Button>
                  <Button icon="plus" className={styles.btnStyle} onClick={this.showProjectDialog.bind(this,"create")}>创建测试项目</Button>
                  <Button icon="plus" className={styles.btnStyle} onClick={()=>{this.setState({visible:true,operaType:'create'})}}>创建测试场景</Button>
                </Col>
              </Row>

            </div>
            {this.state.viewType === 1 &&<div className={styles.dataTable} >
              <Table rowKey={record=>record.id} dataSource={this.state.sceneList} columns={columns}  />
            </div>}

            {this.state.viewType === 0 && <div ><BDMap sceneList={this.state.sceneList}></BDMap></div>}

          </div>

          <Modal
            title={this.state.projectDialogTitle}
            visible={this.state.projectDialogShow}
            width="50%"

            footer={null}
            onCancel={this.hiddenDialog}
            destroyOnClose={true}
          >
            <EditableTable  ></EditableTable>
          </Modal>



          <Modal
            title={<div><span>查看测试场景</span><span> <Button className="editBtn" onClick={()=>{this.editChange(true,this.state.sceneInfo)}}>编辑</Button></span></div>}
            visible={this.state.viewDialogVisible}
            footer={null}
            destroyOnClose={true}
            onCancel={()=>{this.setState({viewDialogVisible:false})}}
            zIndex="100"
          >
            <ViewTestScene editChange={this.editChange} sceneInfo={this.state.sceneInfo}></ViewTestScene>
          </Modal>

          <Modal
            title={this.state.operaType === "create"?"创建测试场景":"编辑测试场景"}
            visible={this.state.visible}
            width={this.state.dialogWidth}
            height='100%'
            footer={null}
            onCancel={()=>{this.setState({visible:false,dialogWidth:'60%'})}}
            destroyOnClose={true}
          >
            <TestSceneDialog changeDialogWidth={this.changeDialogWidth} changModalVisible={this.changModalVisible} operaType={this.state.operaType} updateSceneInfoById={this.updateSceneInfoById} sceneInfo={this.state.updateSceneInfo}></TestSceneDialog>
          </Modal>
        </div>
      </div>
    )
  }
}
export default SceneConfig
