import React from 'react'
import { connect } from 'react-redux'
import greenCar from '../sceneMonitor/assets/greenCar.png'
import rseIcon from './assets/RSE.png'
import camIcon from './assets/CAM.png'
import { wgs84togcj02, gcj02tobd09 } from '../../../api/latLngTransform'

import { Marker, Label } from 'rc-bmap'
import { isEqual } from "lodash";
import { deviceAPI } from "../../../api/deviceAPI";
import { QDTestFieldId } from "../../public/testFieldID";

const Fragment = React.Fragment


class Childs extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      obeMonitor:[]
    }
  }

  getOBEMonitorTimeout=(data)=>{
    setTimeout(()=>{
      deviceAPI.getOBEMonitor(data).then(res=>{
        console.log(res.data.data,"res")
        this.setState({obeMonitor:res.data.data})
        this.getOBEMonitorTimeout(data)
      })
    },1500)

  }

  componentDidMount() {

    //this.getOBEMonitorTimeout({id:QDTestFieldId, mapType:1})
  }


    getMarkerPoint = (lat, lng) => {
        const wgsTogcj = wgs84togcj02(lng, lat);
        //const gcjToBd = gcj02tobd09(wgsTogcj.lng,wgsTogcj.lat)
        return gcj02tobd09(wgsTogcj.lng, wgsTogcj.lat)
    }

  componentWillReceiveProps(nextProps) {

  }


    render() {
        const { monitorOBEdata, monitorRSEdata, markerInfo, testMark, siteMonitorCamList } = this.props

        return (
            <Fragment>
                {monitorOBEdata && monitorOBEdata.obeList && monitorOBEdata.obeList.map((obe, index) => {
                    return (
                        <div key={obe.id}>
                            <Marker
                                rotation={Number(obe.heading)}
                                point={{ lat: obe.latitude, lng: obe.longitude }}
                                icon={{ url: greenCar, size: { width: 10.8, height: 20.4 }, opts: { imageSize: { width: 10.8, height: 20.4 } } }}
                                shadow={{ url: greenCar, size: { width: 0, height: 0 } }}
                            >
                            </Marker>
                        </div>
                    )
                })}

                {
                    monitorRSEdata && monitorRSEdata.rseList && monitorRSEdata.rseList.map((rse, index) => {
                        return (
                            <div key={rse.id}>
                                <Marker
                                    shadow={{ url: rseIcon, size: { width: 0, height: 0 } }}
                                    icon={{ url: rseIcon, size: { width: 30, height: 30 }, opts: { imageSize: { width: 30, height: 30 } } }}
                                    point={{ lat: rse.latitude, lng: rse.longitude }}
                                    offset={{ width: 0, height: -12 }}
                                    title={rse.rseId}
                                />

                            </div>
                        )
                    })
                }

                {
                    markerInfo && JSON.stringify(markerInfo) !== '{}' && testMark && testMark.map((marker, index) =>
                        <Marker
                            key={marker.id}
                            point={this.getMarkerPoint(marker.latitude, marker.longitude)}
                            offset={{ width: 0, height: -10 }}
                            icon={{ url: markerInfo[marker.markType].imgUrl, size: { width: 20, height: 20 }, opts: { imageSize: { width: 20, height: 20 } } }}
                            label={marker.markType === 1 ? <Label
                                style={{
                                    border: 'none',
                                    background: 'transparent',
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '18px',
                                    height: '18px'
                                }}
                                content={marker.param ? JSON.parse(marker.param).speed : 0}
                                offset={{ width: 1, height: 1 }} /> : null}
                            shadow={{ url: markerInfo[marker.markType].imgUrl, size: { width: 0, height: 0 } }}
                        // events={this.getMarkerEvent(marker)}
                        >
                        </Marker>)
                }

                {
                    siteMonitorCamList && siteMonitorCamList.map(cam => <Marker
                        key={cam.id}
                        point={new window.BMap.Point(cam.longitude, cam.latitude)}
                        icon={{ url: camIcon, size: { width: 30, height: 30 }, opts: { imageSize: { width: 30, height: 30 } } }}
                        shadow={{ url: camIcon, size: { width: 0, height: 0 } }}
                    />)
                }
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    const { monitorOBEdata, monitorRSEdata, siteMonitorCamList } = state.realTime;
    const { siteInfo, markerInfo } = state.siteConfig;
    const { testField, testMark } = siteInfo;

    if (!markerInfo) {
        return {}
    }

    if (!testField || !testMark) {
        return {}
    }

    return {
        monitorOBEdata,
        monitorRSEdata,
        markerInfo,
        testMark,
        siteMonitorCamList
    };
}

export default connect(mapStateToProps)(Childs)