import React from 'react'
import {Input,Transfer,Tree,Icon,Row,Col,Button,message,Form  } from 'antd'
import styles from './style.module.css'
import testScheme from "./index";
import {sceneInfo} from '../../../api/sceneInfo'
import {schemeAPI} from '../../../api/schemeAPI'
const { TreeNode } = Tree;

class TestSchemeDialog extends React.Component{

  state = {
    resourceList:[],
    targetList:[],
    defaultExpandedKeys:[],
    targetExpandedKeys:[],
    defaultExpandAll:false,
    expandedKeys:[],
    allSceneNum:0,
    selectSceneNum:0,
    checkedKeys:[]
  }

  componentDidMount() {
    this.getAllProjectList()
    // 编辑的时候需调用的
    if(this.props.operaType === "update"){
      this.getSelectedKeys()
    }

  }

  getSelectedKeys=()=>{
    const {schemeInfo } = this.props

    let tempArray = []
    let tempTargetExpandedKeys = []

    for(let i=0;i<schemeInfo.projects.length;i++){
      tempTargetExpandedKeys.push(schemeInfo.projects[i].id)
      for(let j=0;j<schemeInfo.projects[i].scenes.length;j++){
        tempArray.push(schemeInfo.projects[i].scenes[j].id)
      }

    }
    this.setState({checkedKeys:tempArray,targetList:schemeInfo.projects,targetExpandedKeys:tempTargetExpandedKeys})
    // console.log(tempArray,"tempArray")
  }

  getAllProjectList(){
    sceneInfo.getProjectList().then(res=>{
      if(res.data.code === 200){
        this.setState({resourceList:res.data.data.list})
        let tempArray =[]
        for(let i=0;i<res.data.data.list.length;i++){
          tempArray.push(res.data.data.list[i].id)
          this.setState({allSceneNum:this.state.allSceneNum+res.data.data.list[i].sceneCount})
        }
        this.setState({expandedKeys:tempArray})
      }
    })
  }

  onSelect = (selectedKeys, info) => {
    // console.log('selected', selectedKeys, info);
  }

  onCheck = (checkedKeys, info) => {
    this.setState({checkedKeys:checkedKeys})

    // console.log('onCheck', checkedKeys,info);
    let tempArray = []
    for(let i=0;i<info.checkedNodes.length;i++){
      if(info.checkedNodes[i].key.indexOf("S") !== -1){
          tempArray.push({...info.checkedNodes[i].props.dataRef})
      }
    }
    // console.log(tempArray,"选中的值")
    this.setState({selectSceneNum:tempArray.length})
    let map = {}
    let  dest = []
    let tempExpandedKeys =[]
    for(let i = 0; i < tempArray.length; i++) {
      let ai = tempArray[i];
      if (!map[ai.projectId]) {
        dest.push({
          id: ai.projectId,
          name:ai.projectName,
          scenes: [ai]
        });
        tempExpandedKeys.push(ai.projectId)
        map[ai.projectId] = ai;
      } else {
        for (let j = 0; j < dest.length; j++) {
          let dj = dest[j];
          if (dj.id === ai.projectId) {
            dj.scenes.push(ai);
            break;
          }
        }
      }

    }
    this.setState({targetList:dest,targetExpandedKeys:tempExpandedKeys})
    // console.log(dest,"targetList");

  }


  renderTreeNodes = data => data.map((item) => {
    if (item.scenes) {
      return (
        <TreeNode title={item.name} key={item.id} dataRef={item} className={styles.pTxt}>
          {item.scenes.map((v)=>{
            return (<TreeNode title={v.name} key={v.id} dataRef={v} className={styles.sTxt}></TreeNode>)
          })}
        </TreeNode>
      );
    }
      return <TreeNode {...item} />;

  })

  renderTargetNodes=data=>data.map((item)=>{
    if (item.scenes) {
      return (
        <TreeNode title={item.name} key={item.id} dataRef={item} className={styles.pTxt}>
          {item.scenes.map((v)=>{
            return (<TreeNode title={v.name} key={v.id} dataRef={v} className={styles.sTxt}></TreeNode>)
          })}
        </TreeNode>
      );
    }
    return <TreeNode {...item} />;
  })

  saveHandle=()=>{
    let that = this
    const {schemeInfo } = this.props
    // console.log(schemeInfo,"schemeInfo")

    this.props.form.validateFields((err, values) => {
      if (!err) {
          if(this.state.targetList.length < 1){
            message.warning("请选择场景类型")
            return
          }

          // console.log(values,"values")
        let tempSceneIdList =[]
        for(let i =0; i<this.state.targetList.length;i++){
            for(let j=0;j<this.state.targetList[i].scenes.length;j++){
              tempSceneIdList.push(this.state.targetList[i].scenes[j].id)
            }

        }

        if(this.props.operaType === "create"){
          let data ={name:values.name,sceneIdList:tempSceneIdList}
          schemeAPI.addScheme(data).then(res=>{
            if(res.data.code === 200){
              message.success("创建成功!")
              this.props.changModalVisible(false)
            }else{
              message.error(res.data.msg)
            }
          })
        }else{
          let data ={id:schemeInfo.id,name:values.name,sceneIdList:tempSceneIdList}
          schemeAPI.editScheme(data).then(res=>{
            if(res.data.code === 200){
              message.success("修改成功!")
              this.props.changModalVisible(false)

              this.props.getSchemeInfoById({id:schemeInfo.id})
            }else{
              message.error(res.data.msg)
            }
          })
        }


      }
    })
  }


  render(){
    const { getFieldDecorator } = this.props.form;
    const {operaType,schemeInfo} = this.props
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    return(
      <div>
        <Form {...formItemLayout}>
          <div className={styles.sceneName}>

            <Form.Item label="方案名称:" >
              {getFieldDecorator('name', {
                initialValue: operaType === "update"?schemeInfo.name:null,
                rules: [{
                  required: true,
                  message: '请输入方案名称',
                }],
              })(
                <Input placeholder="请输入方案名称" className={styles.selectStyle} />
              )}
            </Form.Item>

          </div>
          <div>
            <Row gutter={20}>
              <Col span={12}>
                <div className={styles.selectSceneTitle}>全部场景:</div>
                <div className={styles.allSceneCon}>
                  <div className={styles.totalTips}>共{this.state.allSceneNum}项</div>
                <Tree
                  checkable
                  showIcon
                  expandedKeys={this.state.expandedKeys}
                  switcherIcon={<Icon type="" />}
                  onSelect={this.onSelect}
                  onCheck={this.onCheck}
                  checkedKeys={this.props.operaType === 'update'?this.state.checkedKeys:this.state.checkedKeys}

                >
                  {this.renderTreeNodes(this.state.resourceList)}
                </Tree>
                </div>
              </Col>
              <Col span={12}>
                <div className={styles.selectSceneTitle}>所选场景:</div>
                <div className={styles.targetSceneCon}>

                  {this.state.targetList.length>0?
                    <div>
                      <div className={styles.totalTips}>共{this.state.selectSceneNum}项</div>
                      <Tree
                        expandedKeys={this.state.targetExpandedKeys}
                        showIcon
                        switcherIcon={<Icon type="" />}
                      >
                        {this.renderTargetNodes(this.state.targetList)}
                      </Tree>
                    </div>: <span className={styles.noSceneTip}>暂无数据</span>
                  }

                </div>
              </Col>


              <Col span={12} className={styles.sceneCancelBtn} onClick={()=>{this.props.changModalVisible(false)}}><Button>取消</Button></Col>
              <Col span={12} className={styles.sceneSubmitBtn}><Button  className={styles.btnStyle} onClick={this.saveHandle} >保存</Button></Col>
            </Row>
          </div>
        </Form>
      </div>
    )
  }
}

const WrapTestSchemeDialog = Form.create()(TestSchemeDialog)
export default WrapTestSchemeDialog
