import * as types from './actionTypes';

//场景数据
export const sceneData = (state = [], action) => {
    switch(action.type){
        case types.GET_SCENE_DATA:
        return action.data;

        case types.CLEAR_SCENE_DATA:
        return [];

        default:
        return state
    }
}