import { post } from "../../api/request.js";
import * as types from './actionTypes';

/* 场地监控 与 场景监控共用 */

//获取监控OBE坐标
const getOBEListSuccess = data => ({
    type: types.GET_OBE_LIST_SUCCESS,
    data
})

export const getOBEList = (id, mapType) => dispatch => {
    post('/testField/obe/getObeMonitor', { id, mapType }).then(res => {
        if (res.status === 200 && res.data.code === 200 && res.data.data) {
            dispatch(getOBEListSuccess(res.data.data))
        }
    })
}

//获取监控RSE坐标
const getRSEListSuccess = data => ({
    type: types.GET_RSE_LIST_SUCCESS,
    data
})

export const getRSEList = (id, mapType) => dispatch => {
    post('/testField/rse/getRseMonitor', { id, mapType }).then(res => {
        if (res.status === 200 && res.data.code === 200 && res.data.data) {
            dispatch(getRSEListSuccess(res.data.data))
        }
    })
}

//获取测试场所有摄像头

export const getCamList = (testFieldId,mapType) => dispatch => {
    post('/testField/camera/getCameraMonitor',{ testFieldId, mapType }).then(res => {
        if (res.status === 200 && res.data.code === 200 && res.data.data){
            dispatch({
                type:types.GET_CAM_LIST,
                data:res.data.data
            })
        }
    })
}

/* 场景监控 */
//获取所有正在测试的车辆
export const getAllVut = () => dispatch =>  {
    post('/testSite/monitor/getMonitorVut',{}).then(res => {
        if (res.status === 200 && res.data.code === 200 && res.data.data){
            dispatch({
                type:types.GET_ALL_TESTING_VUT,
                data:res.data.data
            })
        }
    })
}

//获取车辆基本信息
export const getCarInfo = (id) => dispatch => {
    post('/testSite/monitor/getMonitorInfo',{id}).then(res => {
        if(res.status === 200 && res.data.code === 200 && res.data.data){
            dispatch({
                type:types.GET_CAR_INFO,
                data:res.data.data
            })
        }
    })
}

//获取车辆实时信息
export const getRealTimeData = (id,isFirstReq) => dispatch => {
    post('/testSite/monitor/getMonitorScene',{id,isFirstReq}).then(res => {
        if(res.status === 200 && res.data.code === 200 && res.data.data){
            const data = res.data.data;
            const vutSpeed = data.info && data.info.vutMoitor && data.info.vutMoitor.speed !== undefined && data.info.vutMoitor.speed !== null?data.info.vutMoitor.speed:null;
            const vutAcc = data.info && data.info.vutMoitor && data.info.vutMoitor.acceleration !== undefined && data.info.vutMoitor.acceleration !== null?data.info.vutMoitor.acceleration:null;
            const vtSpeed = data.info && data.info.vtMoitor && data.info.vtMoitor.speed !== undefined && data.info.vtMoitor.speed !== null?data.info.vtMoitor.speed:null;
            const vtAcc = data.info && data.info.vtMoitor && data.info.vtMoitor.acceleration !== undefined && data.info.vtMoitor.acceleration !== null?data.info.vtMoitor.acceleration:null;
            const event = data.veh && data.veh.vutData && data.veh.vutData.warnDetail && data.veh.vutData.warnType && data.veh.vutData.utcTime?
                {
                    warnType:data.veh.vutData.warnType,
                    warnDetail:data.veh.vutData.warnDetail,
                    time:data.veh.vutData.utcTime * 1000,
                    /* speed:vutSpeed,
                    acc:vutAcc */
                }:
                null

            if(vutSpeed !== null){
                dispatch({
                    type:types.UPDATE_VUT_HISTORY,
                    data:vutSpeed
                })
            }

            if(vutAcc !== null){
                dispatch({
                    type:types.UPDATE_VUT_ACC_HISTORY,
                    data:vutAcc
                })
            }

            if(vtSpeed !== null){
                dispatch({
                    type:types.UPDATE_VT_HISTORY,
                    data:vtSpeed
                })
            }

            if(vtAcc !== null){
                dispatch({
                    type:types.UPDATE_VT_ACC_HISTORY,
                    data:vtAcc
                })
            }

            /* dispatch({
                type:types.UPDATE_VUT_EVENT,
                data:event
            }) */

            dispatch({
                type:types.GET_REALTIME_DATA,
                data:data
            })
        }
    })
}

export const getSpecialEventsData = (postdata) => dispatch => {
    // console.log(id,isFirstReq,"123")
  post('/testSite/monitor/getSpecialEvents',postdata).then(res => {
    if(res.status === 200 && res.data.code === 200 && res.data.data){
      const data = res.data.data;

      const event = data.warnDetail && data.warnType && data.utcTime?
        {
          warnType:data.warnType,
          warnDetail:data.warnDetail,
          time:data.utcTime * 1000,
        }:
        null

      if(event !== null){
        dispatch({
          type:types.UPDATE_VUT_EVENT,
          data:event
        })
      }

    }
  })
}

export const getSpecialEvents = (id,isFirstReq) => dispatch => {
    // console.log(id,isFirstReq,"123")
  post('/testSite/monitor/getSpecialEvents',{id,isFirstReq}).then(res => {
    if(res.status === 200 && res.data.code === 200 && res.data.data){
      const data = res.data.data;

      const event = data.warnDetail && data.warnType && data.utcTime?
        {
          warnType:data.warnType,
          warnDetail:data.warnDetail,
          time:data.utcTime * 1000,
        }:
        null

      if(event !== null){
        dispatch({
          type:types.UPDATE_VUT_EVENT,
          data:event
        })
      }

    }
  })
}


export const clearSpecialEventsData = {
    type:types.CLEAR_VUT_EVENT,
}


//清除所有实时数据
export const clearAllRealTimeData = {
    type:types.CLEAR_ALL_REALTIME_DATA,
}

//清空除车辆信息外的所有实时数据
export const clearRealTimeDataExceptInfo = {
    type:types.CLEAR_REALTIME_DATA_EXCEPT_INFO
}
