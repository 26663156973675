import React from 'react'
import {Form,Input,Button,Icon,message} from 'antd'
import styles from './style.module.css'
import {loginAPI} from '../../api/login'
import videoURL from './img/1.mp4'
import playBtn from './img/play.png'
import Poster from './img/poster.jpg'

class Login extends React.Component{

  state={videoPlay:false}

  loginHandle=()=>{
    this.props.form.validateFields((err, values) => {
      if (!err) {

        loginAPI.login(values).then(res=>{
          if(res.data.code === 200){
            //console.log(res.data.data,"登录接口返回的值")
            this.props.history.push('/siteMonitor');
          }else{
            message.error(res.data.msg)
          }
        })
      }
    })
  }

  operaVideo=()=>{
    this.refs["video"].play()
    this.setState({videoPlay:true})
  }

    render(){
      const { getFieldDecorator } = this.props.form;
      return(
        <div style={{height:'100%'}}>
          <div className={styles.leftCon}>
            <video loop  ref="video" poster={Poster} >
              <source src={videoURL} type="video/mp4"></source>
            </video>
            {!this.state.videoPlay && <img src={playBtn} className={styles.playBtn}  onClick={this.operaVideo} />}

          </div>
          <div className={styles.rightCon}>
            <div className={styles.formCon}>
              <h1>智能网联测试场平台</h1>
              <p className={styles.notice}>满足测试场地和场景的实时监控；在线集中对设备及场景配置；待测车辆登记管理，测试全过程记录、回放及测试结果的判定</p>
              <Form >

                <Form.Item >
                  {getFieldDecorator('name', {
                    rules: [{
                      required: true,
                      message: '请输入用户名',
                    }],
                  })(
                    <Input placeholder="请输入用户名"  prefix={<Icon type="user" style={{ color: '#b4b4b4' }} />}  className={styles.loginInput} />
                  )}
                </Form.Item>

                <Form.Item >
                  {getFieldDecorator('password', {
                    rules: [{
                      required: true,
                      message: '请输入密码',
                    }],
                  })(
                    <Input placeholder="请输入密码"  type="password" prefix={<Icon type="lock" style={{ color: '#b4b4b4' }} />} className={styles.loginInput}/>
                  )}
                </Form.Item>

                <Button  htmlType="submit" block className={styles.subBtn} onClick={this.loginHandle}>
                  登录
                </Button>

              </Form>
            </div>

          </div>
       </div>
      )
    }
}

const WrapLogin = Form.create()(Login)
export default WrapLogin