import React from 'react'
import {Table,Form,Select,Button,Input,Row,Col,Checkbox,Modal,message} from 'antd'
import { withRouter } from "react-router-dom";
import styles from './style.module.css'
import TestVehicleDialog from './testVehicleDialog'
import ViewTestVehicle from './viewTestVehicle'
import {VUTAPI} from "../../../api/VUTAPI"
import searchIcon from './img/search.png'
import deleteIcon from './img/delete.png'
import { schemeAPI } from "../../../api/schemeAPI";
import axios from "axios/index";
import { format } from '../../../utils/formatTime'
import { reportAPI } from "../../../api/reportAPI";
import Header from "../../public/component/header";
import { VTAPI } from "../../../api/VTAPI";

const { Option } = Select;
const confirm = Modal.confirm;

@withRouter
class TestVehicleRegister extends React.Component{
  state={
    VUTList:[],
    operaType:'',
    viewDialogVisible:false,
    updateTestVehicleInfo:null,
    addOrUpdateTitle:'创建测试车辆',
    operaDialogVisible:false,
    showExpandedColumn:false,
    car1:0,
    car2:0,
    car3:0,
    car4:0,
    car5:0,
    car6:0
  }

  componentDidMount (){
    this.getVUTInfoList({})
    this.getTotalCarStatitus()
  }

  getVUTInfoList=(data)=>{
    VUTAPI.getVUTList(data).then(res=>{
      if(res.data.code === 200){
        this.setState({VUTList:res.data.data.list})
      }
    })
  }

  searchPlan=()=>{
    this.props.form.validateFields((err, values) => {
        let data ={}
        if(values.id){
          data.id=values.id
        }
        if(values.brand){
          data.brand=values.brand
        }
        if(values.isV2x || values.isV2x === 0){
          data.isV2x=values.isV2x
        }
        if(values.isPreciseMap || values.isPreciseMap === 0){
          data.isPreciseMap=values.isPreciseMap
        }
        this.getVUTInfoList(data)

    })


  }



  addTestVehicleHandle=()=>{
    this.setState({addOrUpdateTitle:'创建测试车辆',operaDialogVisible:true,operaType:'create'})
  }

  changModalVisible=(flag)=>{
    this.getVUTInfoList({})
    this.getTotalCarStatitus()
    this.setState({operaDialogVisible:flag})
  }

  viewTestVehicleInfo=(text)=>{
    let data={id:text.id}
    this.getTestVehicleInfoById(data)
    this.setState({viewDialogVisible:true})
  }

  getTestVehicleInfoById=(data)=>{
    let that = this
    VUTAPI.getVUTById(data).then(res=>{
      if(res.data.code === 200){
        that.setState({updateTestVehicleInfo:res.data.data})
      }
    })
  }



  deleteTestVehicle=(text)=>{
    let that = this
    confirm({
      title: `确定要删除${text.id}这条记录吗?`,
      okText:'确定',
      cancelText:'取消',
      onOk() {
        const data ={id:text.id}
        VUTAPI.deleteVUT(data).then(res=>{
          if(res.data.code === 200){
            message.success("删除成功!")
            that.getVUTInfoList({})
            that.getTotalCarStatitus()
          }else{
            message.error("删除失败!")
          }
        })
      },
      onCancel() {

      },
    });

  }

  editChange=(flag,testVehicleInfo)=>{
    this.setState({addOrUpdateTitle:'修改测试车辆',operaDialogVisible:flag,updateTestVehicleInfo:testVehicleInfo,operaType:'update'})
  }


  exportVUTHandle=()=>{
    axios.post('/testSite/vut/export',{},{responseType:'blob'}).then(res=>{
      const content = res.data
      const blob = new Blob([content])// 构造一个blob对象来处理数据
      const fileName = '测试车辆信息.xlsx'

      if ('download' in document.createElement('a')) { // 支持a标签download的浏览器
        const link = document.createElement('a')// 创建a标签
        link.download = fileName// a标签添加属性
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        document.body.appendChild(link)
        link.click()// 执行下载
        URL.revokeObjectURL(link.href) // 释放url
        document.body.removeChild(link)// 释放标签
      } else { // 其他浏览器
        navigator.msSaveBlob(blob, fileName)
      }
    })

  }

  onChange=(e)=> {
    this.setState({showExpandedColumn:e.target.checked})
  }

  getTotalCarStatitus=()=>{
    let that = this
    VUTAPI.statisticAll({}).then(res=>{
      if(res.data.code === 200){
        for(let i=0;i<res.data.data.length;i++){
          if(res.data.data[i].type === 0){
            that.setState({car1:res.data.data[i].vutNum})
          }else if(res.data.data[i].type === 1){
            that.setState({car2:res.data.data[i].vutNum})
          }else if(res.data.data[i].type === 2){
            that.setState({car3:res.data.data[i].vutNum})
          }else if(res.data.data[i].type === 3){
            that.setState({car4:res.data.data[i].vutNum})
          }else if(res.data.data[i].type === 4){
            that.setState({car5:res.data.data[i].vutNum})
          }else if(res.data.data[i].type === 5){
            that.setState({car6:res.data.data[i].vutNum})
          }
        }
      }
    })
  }

  clearSeach=()=>{
    this.props.form.setFieldsValue({"id":null,"brand":null,"isV2x":undefined,"isPreciseMap":undefined})
  }



  render(){

    let columns =[{
      title: "VUT编号",
      dataIndex: 'id',
      key: 'id',
    },
      {
        title:"VIN码",
        dataIndex: 'vinNumber',
        key: 'vinNumber'
      },
      {
        title:"商标",
        dataIndex: 'brand',
        key: 'brand'
      }, {
        title: "车辆类型",
        dataIndex: 'type',
        key: 'type',
        render:type=>{
          if(type === 0)
            return '乘用车'
          else if(type === 1)
            return '商用车'
          else if(type === 2)
            return '无人小巴'
          else if(type === 3)
            return '无人大巴'
          else if(type === 4)
            return '无人卡车'
          else if(type === 5)
            return '无人驾驶车辆'
        }
      },
      {
        title: "是否网联式",
        dataIndex: 'isV2x',
        key: 'isV2x',
        render:isV2x=>{
          if(isV2x)
            return '是'
          else
            return '否'
        }
      },
      {
        title: "是否高精地图",
        dataIndex: 'isPreciseMap',
        key: 'isPreciseMap',
        render:isPreciseMap=>{
          if(isPreciseMap)
            return '是'
          else
            return '否'
        }
      }]


    if(this.state.showExpandedColumn)
    {
      columns =columns.concat([{
        title:"创建人",
        dataIndex: 'creater',
        key: 'createPeople',
      }, {
        title: "创建时间",
        dataIndex: 'createTime',
        key: 'createTime',
        render: createTime => {
          if(createTime)
            return format.formatTime(createTime)
          else
            return '--'
        }
      },
        {
          title:"最后修改人",
          dataIndex: 'modifier',
          key: 'modifier',
        }, {
          title: "最后修改时间",
          dataIndex: 'modifyTime',
          key: 'modifyTime',
          render: modifyTime => {
            if(modifyTime)
              return format.formatTime(modifyTime)
            else
              return '--'

          }
        }])
    }

    columns = columns.concat([{
      title:"操作",
      render:(text)=>{
        return (<div><span ><img src={searchIcon} alt="" title="查看" className={styles.operaIcon} onClick={this.viewTestVehicleInfo.bind(this,text)} /></span>&nbsp;&nbsp;<span ><img src={deleteIcon} alt="" title="删除" onClick={this.deleteTestVehicle.bind(this,text)} className={styles.operaIcon} /></span></div>)
      }
    }])


    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <Header title={'测试车辆登记'} />
        <div className={styles.pageCon}>
          <div className={styles.searchCon}>
            <div>
              <Form style={{display:'inline-block'}} layout='inline'>
                <Form.Item >
                  {getFieldDecorator('id', {
                    rules: [{
                      required: false,
                      message: '请输入车辆编号',
                    }],
                  })(
                    <Input placeholder="请输入车辆编号" className={styles.selectStyle} />
                  )}
                </Form.Item>

                <Form.Item >
                  {getFieldDecorator('brand', {
                    rules: [{
                      required: false,
                      message: '请输入车辆商标',
                    }],
                  })(
                    <Input placeholder="请输入车辆商标" className={styles.selectStyle} />
                  )}
                </Form.Item>

                <Form.Item >
                  {getFieldDecorator('isV2x', {
                    rules: [{
                      required: false,
                      message: '请选择采用网联',
                    }],
                  })(
                    <Select  className={styles.selectStyle} placeholder="采用网联"  >
                      <Option value={0} >否</Option>
                      <Option value={1} >是</Option>
                    </Select>
                  )}
                </Form.Item>

                <Form.Item >
                  {getFieldDecorator('isPreciseMap', {
                    rules: [{
                      required: false,
                      message: '请选择采用高精地图',
                    }],
                  })(
                    <Select  className={styles.selectStyle} placeholder="采用高精度地图"  >
                      <Option value={0} >否</Option>
                      <Option value={1} >是</Option>
                    </Select>
                  )}
                </Form.Item>



                <Button className={styles.searchBtn} onClick={this.searchPlan}>查询</Button>
                <Button className={styles.resetBtn} onClick={this.clearSeach}>重置</Button>
              </Form>
            </div>


          </div>

          <div className={styles.dataCon}>
            <div className={styles.operaCon}>
              <Row>
                <Col md={{ span: 24 }} xl={{ span: 16 }}>
                  <div style={{marginTop:'8px'}}>
                  乘用车<span className={styles.projectNum}>{this.state.car1}</span>商用车<span className={styles.projectNum}>{this.state.car2}</span>
                  无人小巴<span className={styles.projectNum}>{this.state.car3}</span>无人大巴<span className={styles.projectNum}>{this.state.car4}</span>
                  无人卡车<span className={styles.projectNum}>{this.state.car5}</span>无人驾驶车辆<span className={styles.projectNum}>{this.state.car6}</span>
                  </div>
                </Col>
                <Col md={{ span: 24 }} xl={{ span: 8 }} style={{textAlign:'right'}}>
                  <Checkbox onChange={this.onChange} className={styles.showRecord}>显示操作记录</Checkbox>
                  <Button onClick={this.exportVUTHandle}>导出Excel</Button>
                  <Button icon="plus" className={styles.btnStyle} onClick={this.addTestVehicleHandle}>新增测试车辆</Button>
                </Col>
              </Row>

            </div>
            <div className={styles.dataTable} >
              <Table rowKey={record=>record.id} dataSource={this.state.VUTList} columns={columns}  />
            </div>

          </div>

          <Modal
            title={<div><span>查看测试车辆</span><span>  <Button className="editBtn" onClick={()=>{this.editChange(true,this.state.updateTestVehicleInfo)}}>编辑</Button></span></div>}
            visible={this.state.viewDialogVisible}
            footer={null}
            destroyOnClose={true}
            onCancel={()=>{this.setState({viewDialogVisible:false,updatePlanInfo:null})}}
            zIndex="10"
          >
            {this.state.updateTestVehicleInfo && <ViewTestVehicle testVehicleInfo={this.state.updateTestVehicleInfo} editChange={this.editChange}></ViewTestVehicle>}
          </Modal>

          <Modal
            bodyStyle={{paddingTop:'0px'}}
            title={this.state.addOrUpdateTitle}
            visible={this.state.operaDialogVisible}
            footer={null}
            width="35%"
            destroyOnClose={true}
            onCancel={()=>{this.setState({operaDialogVisible:false})}}

          >
            <TestVehicleDialog operaType={this.state.operaType}  testVehicleInfo={this.state.updateTestVehicleInfo} getTestVehicleInfoById={this.getTestVehicleInfoById} changModalVisible={this.changModalVisible} ></TestVehicleDialog>


          </Modal>
        </div>
      </div>)
  }
}

const WrapTestVehicleRegister = Form.create()(TestVehicleRegister)
export default WrapTestVehicleRegister