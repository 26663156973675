import React, { Component } from 'react'
import { connect } from 'react-redux'
import styles from './modal.module.css'
import mainStyles from './styles.module.css'
import moment from 'moment';
import { post } from '../../../api/request'
import { Select, Button, DatePicker, message, Modal,Tooltip } from 'antd'
import locale from 'antd/lib/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import CustomRangePicker from './customRangePicker'

moment.locale('zh-cn');

const confirm = Modal.confirm;

const { RangePicker } = DatePicker;

const Fragment = React.Fragment
const Option = Select.Option;

const isV2XMap = {
    "0": "否",
    "1": "是"
}

const isPreciseMapMap = {
    "0": "否",
    "1": "是"
}

const carTypeMap = {
    "0": "乘用车",
    "1": "商用车",
    "2": "无人小巴",
    "3": "无人大巴",
    "4": "无人卡车",
    "5": "无人驾驶车辆"
}

const statuClassMap = {
    "0": styles.planStatuRed,
    "1": styles.planStatuTesting,
    "2": styles.planStatuRed,
    "3": styles.planStatuDone
}

const statuMap = {
    "0": "待测试",
    "1": "测试中",
    "2": "测试取消",
    "3": "测试完成"
}

class RecordDetail extends Component {
    state = {
        showWarning: [],
        obe: null,
        inCam: null,
        outCam: [],

        obeRequesting: false,
        obeList: [],
        camRequesting: false,
        camList: [],

        allScenes: {}
    }

    componentDidMount() {
        this.alive = true;
        this.setState({
            obeRequesting: true,
            camRequesting: true
        })
        post('/testSite/device/findObes', {}).then(res => {
            if (!this.alive) { return; }
            if (res.status === 200 && res.data.code === 200) {
                this.setState({
                    obeList: res.data.data && res.data.data.list ? res.data.data.list : [],
                    obeRequesting: false
                })
                //console.log(res.data.data)
            }
        })

        post('/testSite/device/findCameras', {}).then(res => {
            if (!this.alive) { return; }
            if (res.status === 200 && res.data.code === 200) {
                this.setState({
                    camList: res.data.data && res.data.data.list ? res.data.data.list : [],
                    camRequesting: false
                })
            }
        })
    }

    componentWillUnmount() {
        this.alive = false;
    }

    componentWillReceiveProps(nextProps) {
        const { setAllSceneState, allScenes, data, isDetailEdit } = nextProps
        if (isDetailEdit !== this.props.isDetailEdit) {
            this.setState({
                showWarning: []
            })
        }

        if (data) {
            this.setState({
                obe: data.bindObe,
                inCam: data.bindInCamera,
                outCam: data.bindOutCamera.split(",")
            })
        }

        if (allScenes && JSON.stringify(allScenes) !== '{}') {
            //setAllSceneState(allScenes)
            this.setState({
                allScenes
            })
        }
    }

    onSaveDraftClick = () => {
        const warnings = []
        const { data, close } = this.props
        const { obe, inCam, outCam, allScenes } = this.state

        if (!data) {
            return;
        }

        if (!obe) {
            warnings.push('OBE')
        }

        if (!inCam) {
            warnings.push('incam')
        }

        if (!outCam.length) {
            warnings.push('outcam')
        }

        this.setState({
            showWarning: warnings
        })

        if (warnings.length) {
            return;
        }

        const scenes = []

        for (let i in allScenes) {
            scenes.push(allScenes[i])
        }

        const updateData = {
            id: data.id,
            bindObe: obe,
            bindInCamera: inCam,
            bindOutCamera: `${outCam}`,
            bindVt: data.bindVt,
            recordStatus: 0,
            scenes
        }

        post('/testSite/record/updateRecord', updateData).then(res => {
            if (res.status === 200 && res.data.code === 200) {
                message.success('保存草稿成功');
                setTimeout(() => {
                    close()
                }, 800)
            }
        })
    }

    /**
     *提交为正式记录，场景的时间范围可以为空
     *
     * @memberof RecordDetail
     */
    onSubmitClick = () => {
        const warnings = []
        const { data, close } = this.props
        const { obe, inCam, outCam, allScenes } = this.state

        if (!data) {
            return;
        }

        if (data.planStatus !== 3) {
            return;
        }

        if (!obe) {
            warnings.push('OBE')
        }

        if (!inCam) {
            warnings.push('incam')
        }

        if (!outCam.length) {
            warnings.push('outcam')
        }

        this.setState({
            showWarning: warnings
        })

        if (warnings.length) {
            return;
        }

        confirm({
            title: '',
            content: '一旦提交，永久保存该测试过程记录，无法修改确认提交吗？',
            onOk: () => {
                const scenes = []
                for (let i in allScenes) {
                    scenes.push(allScenes[i])
                }

                const updateData = {
                    id: data.id,
                    bindObe: obe,
                    bindInCamera: inCam,
                    bindOutCamera: `${outCam}`,
                    bindVt: data.bindVt,
                    recordStatus: 1,
                    scenes
                }

                post('/testSite/record/updateRecord', updateData).then(res => {
                    if (res.status === 200 && res.data.code === 200) {
                        message.success('保存正式记录成功')
                        setTimeout(() => {
                            close()
                        }, 800)
                    }
                })
            }
        })
    }

    outCamSelect = (value) => {
        if (value.length <= 4) {
            this.setState({
                outCam: value
            })
        }
    }

    rangePickerSelect = (recordSceneId) => (value) => {
        const { allScenes } = this.state;
        if (!value.length) {
            this.setState({
                allScenes: {
                    ...allScenes,
                    [recordSceneId]: {
                        ...allScenes[recordSceneId],
                        startTime: null,
                        endTime: null
                    }
                }
            })
        } else {
            this.setState({
                allScenes: {
                    ...allScenes,
                    [recordSceneId]: {
                        ...allScenes[recordSceneId],
                        startTime: value[0].valueOf(),
                        endTime: value[1].valueOf()
                    }
                }
            })
        }
    }

    /* 自定义rangePicker */

    rangeStartChange = (recordSceneId) => (value) => {
        const { allScenes } = this.state;
        if(value){
            this.setState({
                allScenes: {
                    ...allScenes,
                    [recordSceneId]: {
                        ...allScenes[recordSceneId],
                        startTime: value.valueOf(),
                    }
                }
            })
        }else{
            this.setState({
                allScenes: {
                    ...allScenes,
                    [recordSceneId]: {
                        ...allScenes[recordSceneId],
                        startTime: null,
                        endTime:null
                    }
                }
            })
        }
    }

    rangeEndChange = (recordSceneId) => (value) => {
        const { allScenes } = this.state;
        if(value){
            this.setState({
                allScenes: {
                    ...allScenes,
                    [recordSceneId]: {
                        ...allScenes[recordSceneId],
                        endTime: value.valueOf(),
                    }
                }
            })
        }else{
            this.setState({
                allScenes: {
                    ...allScenes,
                    [recordSceneId]: {
                        ...allScenes[recordSceneId],
                        endTime: null,
                        startTime:null
                    }
                }
            })
        }
    }

    onDateClear = (recordSceneId) => () => {
        const { allScenes } = this.state;
        this.setState({
            allScenes: {
                ...allScenes,
                [recordSceneId]: {
                    ...allScenes[recordSceneId],
                    endTime: null,
                    startTime:null
                }
            }
        })
    }

    /* ********************** */

    getEditRight = () => {
        const { data, projects } = this.props
        const { showWarning, obe, inCam, outCam, obeRequesting, camRequesting, obeList, camList, allScenes } = this.state
        return (
            <section className={styles.right}>
                <Tooltip placement="top" title='永久保存该测试过程，记录无法修改。注：建议在30天内保存为正式记录，以防止历史视频、报文过期无法永久保存。'>
                    <button disabled={data && data.planStatus !== 3} onClick={this.onSubmitClick} className={`${mainStyles.modalButton} ${styles.submitButton}`}>保存为正式记录</button>
                </Tooltip>

                <Tooltip placement="top" title='临时存储该测试过程，可随时修改'>
                    <button onClick={this.onSaveDraftClick} className={`${mainStyles.modalButton} ${styles.draftButton}`}>保存为草稿</button>
                </Tooltip>
                <header className={styles.header}>测试设备</header>
                <div style={{ width: '100%' }}>
                    <div className={styles.fieldCon}>
                        <span className={styles.inputHeader}>OBE（单选）:</span>
                        <Select loading={obeRequesting} disabled={obeRequesting} value={obe} className={styles.select} onChange={(value) => {
                            this.setState({
                                obe: value
                            })
                        }}>
                            {
                                obeList.map(obe => <Option key={obe.deviceId}>{obe.deviceId}</Option>)
                            }
                        </Select>
                        {showWarning.indexOf("OBE") !== -1 && <label className={styles.warning}>必须选择OBE</label>}
                    </div>
                    <div className={styles.fieldCon}>
                        <span className={styles.inputHeader}>车载车内摄像头（单选）:</span>
                        <Select loading={camRequesting} disabled={camRequesting} value={inCam} className={styles.select} onChange={(value) => {
                            this.setState({
                                inCam: value
                            })
                        }}>
                            {
                                camList.map(cam => <Option key={cam.deviceId}>{cam.deviceId}</Option>)
                            }
                        </Select>
                        {showWarning.indexOf("incam") !== -1 && <label className={styles.warning}>必须选择车内摄像头</label>}
                    </div>
                    <div className={styles.fieldCon}>
                        <span className={styles.inputHeader}>车载车外摄像头（多选&lt;=4个）:</span>
                        <Select loading={camRequesting} disabled={camRequesting} mode="multiple" value={outCam} className={styles.select} onChange={this.outCamSelect}>
                            {
                                camList.map((cam, index) => <Option key={cam.deviceId}>{cam.deviceId}</Option>)
                            }
                        </Select>
                        {showWarning.indexOf("outcam") !== -1 && <label className={styles.warning}>至少选择一个车外摄像头</label>}
                    </div>
                </div>
                <div className={styles.editBottom}>
                    <header className={styles.header}>
                        <span>测试方案</span>
                        <span className={styles.schemeSubTitle}>设置场景开始时间和结束时间</span>
                    </header>
                    <div className={styles.projectConfigCon}>
                        {
                            data && projects &&
                            <Fragment>
                                {
                                    projects.map((proj, index) =>
                                        <div key={index} className={styles.projectCon}>
                                            <header className={styles.projectTitle}>
                                                {proj.id} - {proj.name}
                                            </header>
                                            <div style={{ minHeight: 40 }}>
                                                {proj.scenes && proj.scenes.length && allScenes && proj.scenes.map((scene, _index) =>
                                                    <article key={_index} style={{ display: 'block', height: 'auto' }} className={styles.sceneCon}>
                                                        <span className={styles.inputHeader}>{scene.id} - {scene.name}</span>
                                                        {
                                                            /* <RangePicker
                                                                locale={locale}
                                                                showTime={{ format: 'HH:mm' }}
                                                                format="YYYY-MM-DD HH:mm"
                                                                placeholder={['选择开始时间', '选择结束时间']}
                                                                onChange={this.rangePickerSelect(scene.recordSceneId)}
                                                                value={[
                                                                    allScenes[scene.recordSceneId] && allScenes[scene.recordSceneId].startTime ? moment(allScenes[scene.recordSceneId].startTime) : null,
                                                                    allScenes[scene.recordSceneId] && allScenes[scene.recordSceneId].endTime ? moment(allScenes[scene.recordSceneId].endTime) : null
                                                                ]}
                                                            ></RangePicker> */
                                                        }

                                                        {
                                                            <CustomRangePicker
                                                                startValue = {allScenes[scene.recordSceneId] && allScenes[scene.recordSceneId].startTime ? moment(allScenes[scene.recordSceneId].startTime) : null}
                                                                endValue = {allScenes[scene.recordSceneId] && allScenes[scene.recordSceneId].endTime ? moment(allScenes[scene.recordSceneId].endTime) : null}
                                                                onStartChange = {this.rangeStartChange(scene.recordSceneId)}
                                                                onEndChange = {this.rangeEndChange(scene.recordSceneId)}
                                                                onDateClear = {this.onDateClear(scene.recordSceneId)}
                                                                disableEnd = {allScenes[scene.recordSceneId] && allScenes[scene.recordSceneId].startTime ? false : true}
                                                            >

                                                            </CustomRangePicker>
                                                        }
                                                    </article>
                                                )}
                                            </div>
                                        </div>
                                    )
                                }
                            </Fragment>
                        }
                    </div>
                </div>
            </section>
        )
    }

    getViewRight = () => {
        const { data, projects } = this.props;
        return (
            <section className={styles.right}>
                <div className={styles.leftTop}>
                    <header className={styles.header}>测试设备</header>
                    <article className={`${styles.content} ${styles.rightTopContent}`}>
                        {data && <Fragment>
                            <span className={styles.itemTitle}>OBE：</span>
                            <span className={styles.itemText}>{data.bindObe || '--'}</span>
                            <span className={styles.itemTitle}>车载车内摄像头：</span>
                            <span className={styles.itemText}>{data.bindInCamera || '--'}</span>
                            <span className={styles.itemTitle}>车载车外摄像头：</span>
                            <span className={styles.itemText}>{data.bindOutCamera || '--'}</span>
                        </Fragment>}
                    </article>
                </div>
                <div className={styles.leftBottom}>
                    <header className={styles.header}>测试方案</header>
                    <article className={`${styles.content} ${styles.rightBottomContent}`}>
                        {
                            data && projects &&
                            <Fragment>
                                {
                                    projects.map((proj, index) =>
                                        <div key={index} className={styles.projectCon}>
                                            <header className={styles.projectTitle}>
                                                {proj.id} - {proj.name}
                                            </header>
                                            <div style={{ minHeight: 40 }}>
                                                {proj.scenes && proj.scenes.length && proj.scenes.map((scene, _index) =>
                                                    <article key={_index} className={styles.sceneCon}>
                                                        <span className={styles.projectText}>{scene.id} - {scene.name}</span>
                                                        {
                                                            scene.startTime &&
                                                            scene.endTime &&
                                                            <span className={styles.projectTimeRange}>{moment(scene.startTime).format('YYYY-MM-DD HH:mm:ss')} - {moment(scene.endTime).format('YYYY-MM-DD HH:mm:ss')}</span>
                                                        }
                                                    </article>
                                                )}
                                            </div>
                                        </div>
                                    )
                                }
                            </Fragment>
                        }
                    </article>
                </div>
            </section>
        )
    }

    render() {
        const { isDetailEdit, data } = this.props
        return (
            <div className={styles.main}>
                <section className={styles.left}>
                    <div className={styles.leftTop}>
                        <header className={styles.header}>
                            <span>测试计划</span>
                            {data && <span className={statuClassMap[data.planStatus]}>{statuMap[data.planStatus]}</span>}
                        </header>
                        <article className={`${styles.content} ${styles.leftTopContent}`}>
                            {data && <Fragment>
                                <span className={styles.itemTitle}>测试编号：</span>
                                <span className={styles.itemText}>{data.id || '--'}</span>
                                <span className={styles.itemTitle}>测试方案：</span>
                                <span className={styles.itemText}>{data.schemeId || '--'}</span>
                                <span className={styles.itemTitle}>测试时间：</span>
                                <span className={styles.itemText}>{data.expectStartTime ? moment(data.expectStartTime).format('YYYY-MM-DD') : '--'} ~ {data.expectEndTime ? moment(data.expectStartTime).format('YYYY-MM-DD') : '--'}</span>
                            </Fragment>}
                        </article>
                    </div>
                    <div className={styles.leftBottom}>
                        <header className={styles.header}>测试车辆{data && data.bindVt ? data.bindVt : '--'}参数</header>
                        <article className={`${styles.content} ${styles.leftBottomContent}`}>
                            {data && <Fragment>
                                <span className={styles.itemTitle}>品牌：</span>
                                <span className={styles.itemText}>{data.brand || '--'}</span>
                                <span className={styles.itemTitle}>类型：</span>
                                <span className={styles.itemText}>{carTypeMap[data.type] || '--'}</span>
                                <span className={styles.itemTitle}>VIN码：</span>
                                <span className={styles.itemText}>{data.vinNumber || '--'}</span>
                                <span className={styles.itemTitle}>是否采用网联：</span>
                                <span className={styles.itemText}>{isV2XMap[data.isV2x]}</span>
                                <span className={styles.itemTitle}>型号：</span>
                                <span className={styles.itemText}>{data.model || '--'}</span>
                                <span className={styles.itemTitle}>车牌号码：</span>
                                <span className={styles.itemText}>{data.licensePlate || '--'}</span>
                                <span className={styles.itemTitle}>自动驾驶最高车速：</span>
                                <span className={styles.itemText}>{`${data.adMaxSpeed}km/h`}</span>
                                <span className={styles.itemTitle}>是否采用高精地图：</span>
                                <span className={styles.itemText}>{isPreciseMapMap[data.isPreciseMap]}</span>
                            </Fragment>}
                        </article>
                    </div>
                </section>
                {isDetailEdit ? this.getEditRight() : this.getViewRight()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { prjDetail } = state.projectRecord
    const { data, isFetching } = prjDetail
    const allScenes = {}
    if (JSON.stringify(data) === '{}') {
        return {
            isFetching,
        }
    }

    const projects = data.schemes && data.schemes.length && data.schemes[0] && data.schemes[0].projects && data.schemes[0].projects.length ? data.schemes[0].projects : null;

    if (!projects) {
        return {
            isFetching,
            data
        }
    } else {
        for (let i in projects) {
            if (projects[i].scenes && projects[i].scenes.length) {
                for (let j in projects[i].scenes) {
                    allScenes[projects[i].scenes[j].recordSceneId] = {
                        recordSceneId: projects[i].scenes[j].recordSceneId,
                        startTime: projects[i].scenes[j].startTime ? projects[i].scenes[j].startTime : null,
                        endTime: projects[i].scenes[j].endTime ? projects[i].scenes[j].endTime : null
                    }
                }
            }
        }

        return {
            isFetching,
            data,
            allScenes,
            projects
        }
    }

}

export default connect(mapStateToProps)(RecordDetail)