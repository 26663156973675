
/* 场地监控 */
//测试场监控 获取OBE和RSE列表
export const GET_OBE_LIST_SUCCESS = 'GET_OBE_LIST_SUCCESS'
export const GET_RSE_LIST_SUCCESS = 'GET_RSE_LIST_SUCCESS'

//测试场监控 清空OBE和RSE列表
export const CLEAR_OBE_LIST = 'CLEAR_OBE_LIST'
export const CLEAR_RSE_LIST = 'CLEAR_RSE_LIST'

//场地监控 摄像头相关
export const GET_CAM_LIST = 'GET_CAM_LIST'
export const CLEAR_CAM_LIST = 'CLEAR_CAM_LIST'

/* 场景监控 */
//获取所有正在测试的车辆
export const GET_ALL_TESTING_VUT = 'GET_ALL_TESTING_VUT';
export const CLEAR_ALL_TESTING_VUT = 'CLEAR_ALL_TESTING_VUT';

//获取车辆基本信息
export const GET_CAR_INFO = 'GET_CAR_INFO';
export const CLEAR_CAR_INFO = 'CLEAR_CAR_INFO';

//获取车辆实时数据
export const GET_REALTIME_DATA = 'GET_REALTIME_DATA';
export const CLEAR_REALTIME_DATA = 'CLEAR_REALTIME_DATA';

//VUT速度历史
export const UPDATE_VUT_HISTORY = 'UPDATE_VUT_HISTORY';
export const CLEAR_VUT_HISTORY = 'CLEAR_VUT_HISTORY';

//VT速度历史
export const UPDATE_VT_HISTORY = 'UPDATE_VT_HISTORY';
export const CLEAR_VT_HISTORY = 'CLEAR_VT_HISTORY';

//VUT加速度历史
export const UPDATE_VUT_ACC_HISTORY = 'UPDATE_VUT_ACC_HISTORY';
export const CLEAR_VUT_ACC_HISTORY = 'CLEAR_VUT_ACC_HISTORY';

//VT加速度历史
export const UPDATE_VT_ACC_HISTORY = 'UPDATE_VT_ACC_HISTORY';
export const CLEAR_VT_ACC_HISTORY = 'CLEAR_VT_ACC_HISTORY';

//VUT事件 
export const UPDATE_VUT_EVENT = 'UPDATE_VUT_EVENT';
export const CLEAR_VUT_EVENT = 'CLEAR_VUT_EVENT';

//清除所有实时信息
export const CLEAR_ALL_REALTIME_DATA = 'CLEAR_ALL_REALTIME_DATA';
export const CLEAR_REALTIME_DATA_EXCEPT_INFO = 'CLEAR_REALTIME_DATA_EXCEPT_INFO'
