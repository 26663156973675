import React from 'react'
import { Row,Col,Input, Icon,Button ,Form,Modal,DatePicker,Affix,message } from 'antd'
import styles from './style.module.css'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
const { TextArea } = Input;
import moment from 'moment';
const dateFormat = 'YYYY-MM-DD HH:mm:ss';
import faker from 'faker'
import { format } from '../../../utils/formatTime'
import { reportAPI } from "../../../api/reportAPI";
import Header from '../../public/component/header'

class TestResultReport extends React.Component{
  state={
      reportId:null,
      top: 60,
      previewCon:false,
      reportNum:'',
      charger:'',
      publishTime:null,
      testTime:null,
      resultCon:null,
      planInfo:[
        {
          mainScene:"P1-交通标志和标线的识别及响应",
          sceneItem:[{
            title:"T1-限速标志识别及响应",
            passFlag:-1,
            note:'',
            i:0
          },{
            title:"T2-停车让行标志标线识别及响应",
            passFlag:-1,
            note:'',
            i:0
          },{
            title:"T3-车道线识别及响应",
            passFlag:-1,
            note:'',
            i:0
          },{
            title:"T4-车道线识别及响应",
            passFlag:-1,
            note:'',
            i:0
          },
            {
              title:"T5-人行横道线识别及响应",
              passFlag:-1,
              note:'',
              i:0
            }]
        },
        {
          mainScene:"P2-交通信号灯识别及响应",
          sceneItem:[{
            title:"T1-机动车信号灯识别及响应",
            passFlag:-1,
            note:'',
            i:1
          },{
            title:"T2-方向指示信号灯识别及响应",
            passFlag:-1,
            note:'',
            i:1
          }]
        },
        {
          mainScene:"P3-前方车辆行驶状态识别及响应",
          sceneItem:[{
            title:"T1-车辆驶入识别及响应",
            passFlag:-1,
            note:'',
            i:2
          },{
            title:"T2-对向车辆借道本车车道行驶识别及响应",
            passFlag:-1,
            note:'',
            i:3
          }
          ]
        },
        {
          mainScene:"P4-障碍物识别及响应",
          sceneItem:[{
            title:"T1-障碍物测试",
            passFlag:-1,
            note:'',
            i:2
          },{
            title:"T2-误作用测试",
            passFlag:-1,
            note:'',
            i:3
          }
          ]
        },
        {
          mainScene:"P5-行人和非机动车识别及避让",
          sceneItem:[{
            title:"T1-行人横穿马路",
            passFlag:-1,
            note:'',
            i:2
          },{
            title:"T2-行人沿道路行走",
            passFlag:-1,
            note:'',
            i:3
          },{
            title:"T3-两轮车横穿马路",
            passFlag:-1,
            note:'',
            i:3
          },{
            title:"T4-两轮车沿道路骑行",
            passFlag:-1,
            note:'',
            i:3
          }
          ]
        },
        {
          mainScene:"P6-跟车行驶",
          sceneItem:[{
            title:"T1-稳定跟车行驶",
            passFlag:-1,
            note:'',
            i:2
          },{
            title:"T2-停-走功能",
            passFlag:-1,
            note:'',
            i:3
          }
          ]
        },
        {
          mainScene:"P7-靠路边停车",
          sceneItem:[{
            title:"T1-靠路边应急停车",
            passFlag:-1,
            note:'',
            i:2
          },{
            title:"T2-最右车道内靠边停车",
            passFlag:-1,
            note:'',
            i:3
          }
          ]
        },
        {
          mainScene:"P8-超车",
          sceneItem:[{
            title:"T1-超车",
            passFlag:-1,
            note:'',
            i:2
          }
          ]
        },
        {
          mainScene:"P9-并道",
          sceneItem:[{
            title:"T1-邻近车道无车并道",
            passFlag:-1,
            note:'',
            i:2
          },{
            title:"T2-邻近车道有车并道",
            passFlag:-1,
            note:'',
            i:3
          },{
            title:"T3-前方车道减少",
            passFlag:-1,
            note:'',
            i:3
          }
          ]
        },
        {
          mainScene:"P10-交叉路口通行",
          sceneItem:[{
            title:"T1-直行车辆冲突通行",
            passFlag:-1,
            note:'',
            i:2
          },{
            title:"T2-右转车辆冲突通行",
            passFlag:-1,
            note:'',
            i:3
          },{
            title:"T3-左转车辆冲突通行",
            passFlag:-1,
            note:'',
            i:3
          }
          ]
        },
        {
          mainScene:"P11-环形路口通行",
          sceneItem:[{
            title:"T1-环形路口通行",
            passFlag:-1,
            note:'',
            i:2
          }
          ]
        },
        {
          mainScene:"P12-自动紧急制动",
          sceneItem:[{
            title:"T1-前车静止",
            passFlag:-1,
            note:'',
            i:2
          },{
            title:"T2-前车制动",
            passFlag:-1,
            note:'',
            i:3
          },{
            title:"T3-行人横穿",
            passFlag:-1,
            note:'',
            i:3
          }
          ]
        }

      ],
      totalPassFlag:-1,
      reportInfo:null,
      description:null
  }

  componentDidMount(){
    let reportId = null
    if(this.props.history.location.reportId){
      reportId = this.props.history.location.reportId
    }else{
      reportId = localStorage.getItem("reportId")
    }
    // console.log(reportId,"页面传过来的对象")
    this.setState({reportId:reportId})
    this.getReportInfoById({id:reportId})
  }



  getReportInfoById=(data)=>{
    reportAPI.getRecordById(data).then(res=>{
      if(res.data.code === 200){
        // console.log(res.data.data,"一条报告")
        this.setState({reportInfo:res.data.data})
      }
    })
  }

  previewSubmit = (e) => {
    let that = this

    /* document.getElementById("previewCon").style.zIndex = "11"
    document.getElementById("previewCon").style.opacity = "1" */

    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      if (!err) {
        if(this.state.totalPassFlag === -1){
          message.warning("请选择最终结果是否通过!")
          return
        }
        that.setState({previewCon:true})
        that.setState({testPerson:values.testPerson,reportNo:values.reportNo,description:values.result})
        if(values.publishDate){
          that.setState({publishTime:values.publishDate.format('YYYY-MM-DD HH:mm:ss')})
        }
        if(values.testDate){
          that.setState({testTime:values.testDate.format('YYYY-MM-DD HH:mm:ss')})
        }

        const {reportInfo} = this.state
        let tempArray  = reportInfo
        for(let key in values.description){
          // console.log(key,values.description[key])
          const tempIndexArray = key.split("+")
          for(let a=0;a<tempArray.schemes[0].projects.length;a+=1){
            if(a === parseInt(tempIndexArray[0])){
              for(let b=0;b<tempArray.schemes[0].projects[a].scenes.length;b+=1){
                if(b === parseInt(tempIndexArray[1])){
                  tempArray.schemes[0].projects[a].scenes[b].description = values.description[key]
                }
              }
            }
          }
        }
        tempArray.description = values.result
        this.setState({planInfo:tempArray})

      }
    });
  }

  onStartChange = (value) => {
    this.setState({
      startTime:moment(new Date(this.momentToTimestap(value)),dateFormat)
    });

  }

  momentToTimestap(momentDate) {
    return Date.parse(new Date(moment(momentDate).format('YYYY-MM-DD HH:mm:ss')))
  }



  hiddenPreviewCon =() => {
   /* document.getElementById("previewCon").style.zIndex = "-1"
    document.getElementById("previewCon").style.opacity = "0"*/
     this.setState({previewCon:false})
  }

  changeTotalPassFlag = (passFlag) =>{
    this.setState({totalPassFlag:passFlag})
  }

  changePassFlag = (index,v,passFlag) => {
    // console.log(index,"index")
    // console.log(v,"v")
    // console.log(passFlag,"passFlag")
    const {planInfo,reportInfo} = this.state
    let tempArray  = []
    tempArray = reportInfo
    for(let i=0;i<tempArray.schemes[0].projects.length;i+=1){
      if(i === index){
        for(let j=0;j<tempArray.schemes[0].projects[i].scenes.length;j+=1){
          if(j === v){
            tempArray.schemes[0].projects[i].scenes[j].resultStatus = passFlag
          }
        }
      }
    }
    this.setState({planInfo:tempArray})


  }

  changeTotalPassFlag =(passFlag) =>{
    this.setState({totalPassFlag:passFlag})
  }

  changeNote=(index,i,e)=>{
    // console.log(index,i,e,"1111111111111")
  }

  generatePDF =(i) =>{
    let that = this

    this.props.form.validateFields((err, values) => {

      if (!err) {
        // console.log(values,"最后生成的")
        if(this.state.totalPassFlag === -1){
          message.warning("请选择最终结果是否通过!")
          return
        }
        that.setState({testPerson:values.testPerson,reportNo:values.reportNo,description:values.result})
        if(values.publishDate){
          that.setState({publishTime:values.publishDate.format('YYYY-MM-DD HH:mm:ss')})
        }
        if(values.testDate){
          that.setState({testTime:values.testDate.format('YYYY-MM-DD HH:mm:ss')})
        }

        const {reportInfo} = this.state
        const tempArray  = reportInfo
        if(tempArray.schemes.length <= 0){
          message.warning("此记录方案下面没有测试场景，暂时无法生成测试报告!")
          return
        }
        let scenesList = []
        for(let key in values.description){
          // console.log(key,values.description[key])
          const tempIndexArray = key.split("+")
          for(let a=0;a<tempArray.schemes[0].projects.length;a+=1){
            if(a === parseInt(tempIndexArray[0])){
              for(let b=0;b<tempArray.schemes[0].projects[a].scenes.length;b+=1){
                if(b === parseInt(tempIndexArray[1])){
                  scenesList.push({recordSceneId:tempArray.schemes[0].projects[a].scenes[b].recordSceneId,resultStatus:tempArray.schemes[0].projects[a].scenes[b].resultStatus,description:values.description[key]})
                }
              }
            }
          }
        }

        // console.log('Received values of form: ', values,scenesList);
        let data ={id:that.state.reportId,testPerson:values.testPerson,
                    reportNo:values.reportNo,description:values.result,
                    scenes:scenesList,publishTime:values.publishDate.format('YYYY-MM-DD HH:mm:ss'),
                    testTime:values.testDate.format('YYYY-MM-DD HH:mm:ss'),
                    resultStatus:this.state.totalPassFlag
                  }
        reportAPI.generateReport(data).then(res=>{
          if(res.data.code === 200){
            this.props.history.push({pathname:'/testReport'});
          }else{
            message.error(res.data.msg)
          }
        })
      }
    });

    
    
    

  }

  goBack=()=>{
    this.props.history.push({pathname:'/testReport'});
  }

  render(){
    const { planInfo,totalPassFlag,reportInfo} = this.state;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    return (
      <div >
        <Header title={'测试报告'}/>
        <Form >
          <Row >
            <Col  span={6}>
              <Row >
                <Col md={{span:24}}  className={styles.reportLeft}>
                  <Affix offsetTop={this.state.top}>
                      <div className={styles.reportLeftCon}>
                        <div className={styles.reportLeftTitle}>
                          <p>{reportInfo && reportInfo.vutId}车辆参数</p>
                        </div>
                        <div className={styles.reportLeftItem}>
                          <span className={styles.title}>品牌</span>
                          <span className={styles.content}>{reportInfo && reportInfo.brand}</span>
                        </div>
                        <div className={styles.reportLeftItem}>
                          <span className={styles.title}>类型</span>
                          <span className={styles.content}>{reportInfo && reportInfo.type}</span>
                        </div>
                        <div className={styles.reportLeftItem}>
                          <span className={styles.title}>VIN码</span>
                          <span className={styles.content}>{reportInfo && reportInfo.vinNumber}</span>
                        </div>
                        <div className={styles.reportLeftItem}>
                          <span className={styles.title}>是否采用网联</span>
                          <span className={styles.content}>{reportInfo && (reportInfo.isV2x?'是':'否')}</span>
                        </div>
                        <div className={styles.reportLeftItem}>
                          <span className={styles.title}>型号</span>
                          <span className={styles.content}>{reportInfo && reportInfo.model}</span>
                        </div>
                        <div className={styles.reportLeftItem}>
                          <span className={styles.title}>车牌号码</span>
                          <span className={styles.content}>{reportInfo && reportInfo.licensePlate}</span>
                        </div>
                        <div className={styles.reportLeftItem}>
                          <span className={styles.title}>自动驾驶最高车速</span>
                          <span className={styles.content}>{reportInfo && reportInfo.adMaxSpeed}</span>
                        </div>
                        <div className={styles.reportLeftItem}>
                          <span className={styles.title}>是否采用高精度地图</span>
                          <span className={styles.content}>{reportInfo && (reportInfo.isPreciseMap?'是':'否')}</span>
                        </div>
                      </div>
                  </Affix>
                </Col>
              </Row>
            </Col>


            <Col  span={11} style={{padding:'20px 20px'}}>
              <div className={styles.reportMiddleCon}>
                <div className={styles.reportBack}><span onClick={this.goBack}><Icon type="rollback"  />返回</span></div>

                <div className={styles.reportMiddleTitle}>
                  <p>测试方案</p>
                </div>
                <div className={styles.reportMiddleItem}>
                  <Row className={styles.caseTitle}>
                    <Col span={12}>S1方案</Col>
                    <Col span={12}>判定结果</Col>
                  </Row>
                    {reportInfo && reportInfo.schemes.length > 0 && reportInfo.schemes[0].projects.map((item,index) => {
                        return (
                          <div  key={item.id}>

                            <p className={styles.caseItemTitle}>{item.name}</p>

                            {
                              item.scenes.map((v,i) =>{
                                return (
                                  <Form.Item key={item.id+v.id}>
                                    <div className={styles.caseItemCon} >
                                      <div className={styles.caseItemLeft}>
                                        {v.name}
                                      </div>
                                      <div className={styles.caseItemMiddle}>
                                        <button type='button' className={styles.pass} style={{display:v.resultStatus === -1 || (!v.resultStatus && v.resultStatus !== 0)?'inline-block':'none'}} onClick={this.changePassFlag.bind(this,index,i,1)}>通过</button>
                                        <button type='button' className={styles.noPass} style={{display:v.resultStatus === -1 || (!v.resultStatus && v.resultStatus !== 0)?'inline-block':'none'}} onClick={this.changePassFlag.bind(this,index,i,0)}>不通过</button>
                                        <button type='button' className={styles.longPass}  style={{display:v.resultStatus === 1?'inline-block':'none'}} onClick={this.changePassFlag.bind(this,index,i,-1)}>测试通过</button>
                                        <button type='button' className={styles.longNoPass}  style={{display:v.resultStatus === 0?'inline-block':'none'}} onClick={this.changePassFlag.bind(this,index,i,-1)}>测试不通过</button>
                                      </div>
                                       <div className={styles.caseItemRight}>
                                            {getFieldDecorator(`description[${index}+${i}]`, {
                                              initialValue: v.description ? v.description:null,
                                              validateTrigger: ['onChange', 'onBlur'],
                                            })(
                                              <Input
                                                style={{width:'90',height:'38px'}}
                                                placeholder="选填"
                                                prefix={<Icon type="edit" size="large" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                              />
                                            )}

                                          </div>
                                    </div>
                                  </Form.Item>
                                )
                              })
                            }


                          </div>
                        )
                    })}
                </div>

              </div>
            </Col>


            <Col  span={7}>
              <Row >
                <Col md={{span:24}}>
                  <Affix offsetTop={this.state.top} >
                  <div className={styles.reportRightConUp}>
                    <div className={styles.reportRightConUpTitle}>
                      <p>基本信息</p>
                    </div>

                    <div className={styles.infoCon}>
                      <p>报告编号</p>
                      <Form.Item>
                        {getFieldDecorator('reportNo', {
                          validateTrigger: ['onChange', 'onBlur'],
                          rules: [{
                            required: true,
                            message: '请输入报告编号',
                          }]
                        })(
                          <Input
                            style={{width:'100%',height:'38px'}}
                            prefix={<Icon type="edit" size="large" style={{ color: 'rgba(0,0,0,.25)' }} />}
                          />
                        )}
                      </Form.Item>
                    </div>


                    <div className={styles.infoCon}>
                      <p>负责人</p>
                      <Form.Item>
                        {getFieldDecorator('testPerson', {
                          validateTrigger: ['onChange', 'onBlur'],
                          rules: [{
                            required: true,
                            message: '请输入负责人',
                          }]
                        })(
                          <Input
                            style={{width:'100%',height:'38px'}}
                            prefix={<Icon type="edit" size="large" style={{ color: 'rgba(0,0,0,.25)' }} />}
                          />
                        )}
                      </Form.Item>
                    </div>

                    <div className={styles.infoCon}>
                      <p>发布日期</p>
                      <Form.Item>
                        {getFieldDecorator('publishDate', {
                          rules: [{
                            required: true,
                            message: '请选择发布日期',
                          }]
                        })(
                          <DatePicker
                            showTime
                            style={{width:'100%',height:'38px'}}
                            format="YYYY-MM-DD HH:mm:ss"
                            // value={startValue}
                            placeholder="请填写发布日期"
                          />
                        )}

                      </Form.Item>



                    </div>
                    <div className={styles.infoCon}>
                      <p>测试日期</p>
                      <Form.Item>

                        {getFieldDecorator('testDate', {
                          rules: [{
                            required: true,
                            message: '请选择测试日期',
                          }]
                        })(
                          <DatePicker
                            showTime
                            style={{width:'100%',height:'38px'}}
                            format="YYYY-MM-DD HH:mm:ss"
                            // value={startValue}
                            placeholder="请填写测试日期"
                          />


                        )}

                      </Form.Item>


                    </div>


                  </div>

                  <div className={styles.reportRightConDown}>
                    <div className={styles.reportRightConDownTitle}>
                      <p>最终结果</p>
                    </div>
                    <div className={styles.reportRightConDownContent}>
                      <button type='button' className={styles.pass} style={{display:totalPassFlag === -1?'inline-block':'none'}} onClick={this.changeTotalPassFlag.bind(this,1)}>通过</button>
                      <button type='button' className={styles.noPass} style={{display:totalPassFlag === -1?'inline-block':'none'}} onClick={this.changeTotalPassFlag.bind(this,0)}>不通过</button>
                      <button type='button' className={styles.longPass}  style={{display:totalPassFlag === 1?'inline-block':'none'}} onClick={this.changeTotalPassFlag.bind(this,-1)}>测试通过</button>
                      <button type='button' className={styles.longNoPass}  style={{display:totalPassFlag === 0?'inline-block':'none'}} onClick={this.changeTotalPassFlag.bind(this,-1)}>测试不通过</button>
                    </div>
                    <div className={styles.reportRightConDownContent}>
                      <Form.Item>

                        {getFieldDecorator('result', {
                          validateTrigger: ['onChange', 'onBlur'],
                          rules: [{
                            required: true,
                            message: '请填写最终结果',
                          }],
                        })(
                          <TextArea rows={4}
                                    placeholder="请输入最终检测结论"
                            prefix={<Icon type="edit" size="large" style={{ color: 'rgba(0,0,0,.25)' }} />}
                          />
                        )}


                      </Form.Item>

                    </div>
                    <div className={styles.infoCon}>
                      <Button block style={{marginBottom:'18px'}} size='large' onClick={this.previewSubmit}>预览</Button>
                      <Button type="primary" block style={{marginBottom:'78px'}} size='large'  onClick={this.generatePDF.bind(this,0)}>生成测试结果报告</Button>
                    </div>
                  </div>
                  </Affix>

                </Col>



              </Row>
            </Col>



          </Row>
        </Form>


        <Modal visible={this.state.previewCon} width="55%" footer={null} onCancel={this.hiddenPreviewCon}>
          <div id="pdf" style={{fontVariant:'normal'}}>
            <p style={{textAlign:'center',fontWeight:'bold',fontSize:'24px',fontVariant:'normal'}}>智 能 网 联 测 试 场</p>
            <p style={{textAlign:'center',fontWeight:'bold',fontSize:'24px',fontVariant:'normal'}}>检 测 报 告</p>
            <p style={{textAlign:'center',fontWeight:'bold',fontSize:'18px',fontVariant:'normal'}}>编号：{reportInfo ? reportInfo.reportNo:"--"}</p>
            <p className={styles.previewTitle}>一.基本信息</p>
            <div>
              <Row>
                <Col span={24}><span className={styles.previewCarTitle}>委托单位名称:<span className={styles.previewCarData}>XXX汽车厂</span></span></Col>
                <Col span={24}><span className={styles.previewCarTitle}>检测方案:<span className={styles.previewCarData}>S4</span></span></Col>
                <Col span={24}><span className={styles.previewCarTitle}>测试时间：<span className={styles.previewCarData}>{reportInfo ? (format.formatTime(reportInfo.expectStartTime)).substring(0,10):'--'}~{reportInfo ? (format.formatTime(reportInfo.expectEndTime)).substring(0,10):'--'}</span></span></Col>
                <Col span={24}><span className={styles.previewCarTitle}>特别声明：</span></Col>
                <Col span={24}><span className={styles.previewCarTitle}><span className={styles.previewCarData}>(1) 检测依据：智能网联汽车自动驾驶功能测试规程（试行）<br/>
                (2) 评判依据：现场测试记录表以及场景测试实时监控记录
                </span></span></Col>
              </Row>
            </div>
            <p className={styles.previewTitle}>二.车辆参数</p>
            <div>
              <Row>
                <Col span={8}><span className={styles.previewCarTitle}>VUT编号:</span> <span className={styles.previewCarItemInfo}>{reportInfo ? reportInfo.vutId:"--"}</span></Col>
                <Col span={8}><span className={styles.previewCarTitle}>产品品牌:</span> <span className={styles.previewCarItemInfo}>{reportInfo ? reportInfo.brand:"--"}</span> </Col>
                <Col span={8}>
                  <span className={styles.previewCarTitle}>产品型号:</span> <span className={styles.previewCarItemInfo}>{reportInfo ? reportInfo.model:"--"}</span>
                </Col>

                <Col span={12}><span className={styles.previewCarTitle}>VIN码:</span> <span className={styles.previewCarItemInfo}>{reportInfo ? reportInfo.vinNumber:"--"}</span></Col>
                <Col span={12}><span className={styles.previewCarTitle}>自动驾驶模式设计最高车速：</span> <span className={styles.previewCarItemInfo}>{reportInfo ? reportInfo.adMaxSpeed:"--"} km/h</span></Col>
                <Col span={12}><span className={styles.previewCarTitle}>是否采用网联:</span> <span className={styles.previewCarItemInfo}>{reportInfo ? reportInfo.isV2x:"--"}</span></Col>
                <Col span={12}><span className={styles.previewCarTitle}>是否采用高精地图:</span> <span className={styles.previewCarItemInfo}>{reportInfo ? reportInfo.isPreciseMap:"--"}</span></Col>

                <Col span={24}><span className={styles.previewCarTitle}>VT编号:</span> <span className={styles.previewCarItemInfo}>{reportInfo ? reportInfo.bindVt:"--"}</span></Col>
                <Col span={24}><span className={styles.previewCarTitle}>OBE：</span> <span className={styles.previewCarItemInfo}>{reportInfo ? reportInfo.bindObe:"--"}</span></Col>
                <Col span={24}><span className={styles.previewCarTitle}>车载车内摄像头：</span> <span className={styles.previewCarItemInfo}>{reportInfo ? reportInfo.bindInCamera:"--"}</span></Col>
                <Col span={24}><span className={styles.previewCarTitle}>车载车外摄像头：</span> <span className={styles.previewCarItemInfo}>{reportInfo ? reportInfo.bindOutCamera:"--"}</span></Col>

              </Row>
            </div>
            <p className={styles.previewTitle}>三.检测结果</p>
            <div >
              <table className={styles.resultTable}>
                <thead>
                <tr>
                  <th>序号</th>
                  <th>测试项目</th>
                  <th>测试场景</th>
                  <th>测试结果</th>
                </tr>
                </thead>
                <tbody>
                {reportInfo && reportInfo.schemes.length>0 && reportInfo.schemes[0].projects.map((item,index) => {
                  return (<tr key={faker.name.findName()}>
                    <td>{index+1}</td>
                    <td>{item.name}</td>
                    <td style={{textAlign:'left'}}>
                      {item.scenes.map((scene,n)=>{
                        return (
                          <div key={faker.name.findName()}>
                            <span>{scene.name}</span><br/>
                          </div>
                        )
                      })}
                    </td>
                    <td style={{textAlign:'left'}}>
                      {item.scenes.map((scene,n)=>{
                        return (
                          <div key={faker.name.findName()}>
                            <span>{scene.resultStatus === 1?'合格':(scene.resultStatus === 0?'不合格':'--')}</span><br/>
                          </div>
                        )
                      })}
                    </td>
                  </tr>)
                })}

                </tbody>
              </table>

            </div>
            <p className={styles.previewTitle}>四.检测结论</p>
            <br/>
            <br/>
            <p style={{textAlign:'center'}}>{reportInfo ? reportInfo.description:'--'}</p>
            <br/>
            <br/>
            <br/>
            <p className={styles.previewTitle}>五.检测人</p>
            <p style={{textAlign:'center'}}>{reportInfo ? reportInfo.testPerson:'--'}</p>
            <br/>
            <br/>
            <br/>
            <p className={styles.shouwei}>测试机构：<span className={styles.shouweiSpace}>（名称和公章）</span></p>

            <p className={styles.shouwei}>发证日期：<span className={styles.shouweiSpace}>年</span><span className={styles.shouweiSpace}>月</span><span className={styles.shouweiSpace}>日</span></p>

          </div>
          <Row>

            <Col style={{marginBottom:'28px',marginTop:'20px',textAlign:'center'}} className={styles.space}><Button  style={{float:'right',marginLeft:'20px'}} onClick={this.hiddenPreviewCon}>取消</Button><Button type="primary" block style={{width:'25%',float:'right'}} onClick={this.generatePDF.bind(this,1)}>生成测试结果报告</Button></Col>
          </Row>

        </Modal>














      </div>
    )
  }
}
// export default TestResultReport

const WrapTestResultReport = Form.create()(TestResultReport)
export default WrapTestResultReport