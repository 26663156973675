import React from 'react'
import {Table,Select,Input,Button,Icon,Modal} from 'antd'
import { withRouter } from "react-router-dom";
import styles from './style.module.css'
import faker from "faker";
import { device } from "../../../api/deviceSearch";
import {cameraAPI} from "../../../api/cameraAPI";
import ImageView from './imageView'
import CameraInfoDialog from './cameraInfoDialog'
import VideoComponent from '../../../view/public/component/videoComponent'
import Header from "../../public/component/header";
const { Option } = Select;

@withRouter
class VehicleMountedManagement extends React.Component{
  state={
    nameList:[],
    testFieldId:undefined,
    cameraId:'',
    selectedNameValue:"",
    cameraList:[],
    viewType:1,  //1代表List 0 代表Image,
    dialogWidth:'35%',
    operaDialogVisible:false,
    cameraInfo:null,
    videoUrl:null,
    showVideoDialog:false,
    isOnline:0
  }

  componentDidMount (){
    this.getTestFieldNameList()
    this.getCameraInfoList({})
  }

  getCameraInfoList=(data)=>{
    cameraAPI.getCameraList(data).then(res=>{
      if(res.data.code === 200){
        this.setState({cameraList:res.data.data})
      }
    })
  }

  getTestFieldNameList=()=>{
    device.getTestFieldList({}).then(res=>{
      if(res.data.code === 200){
        this.setState({
          nameList:res.data.data
        })
      }
    })
  }

  inputText=(e)=>{
    this.setState({
      cameraId:e.target.value
    })
  }

  editHandle=(text)=>{
    this.setState({operaDialogVisible:true,cameraInfo:text})
  }

  changeListView=(type)=>{
    this.setState({viewType:type})
    this.searchCamera()
  }

  changeDialogWidth=(num)=>{
    this.setState({dialogWidth:num})
  }

  changModalVisible=(flag)=>{
    this.getCameraInfoList({})
    this.setState({operaDialogVisible:flag})

  }

  handleChangeName=(value)=>{
    this.setState({
      testFieldId:value
    })
  }

  searchCamera=()=>{
    let data = {}
    if(this.state.testFieldId){
      data.testFieldId=this.state.testFieldId
    }
   /* if(this.state.cameraId){
      data.devicesType=this.state.cameraId
    } */

    cameraAPI.getCameraList(data).then(res=>{
      if(res.data.code === 200){
        this.setState({
          cameraList:res.data.data
        })
      }

    })
  }

  playVideoHandle=(text)=>{
    // console.log(text)
    this.setState({showVideoDialog:true,videoUrl:text.url,isOnline:text.isOnline})
    /*if(text.isOnline)
        this.setState({showVideoDialog:true,videoUrl:text.url,isOnline:text.isOnline})
    else
        this.setState({showVideoDialog:true,isOnline:0})*/
    //console.log(text,"text")
  }

  clearSearch=()=>{
    this.setState({testFieldId:undefined})
  }



  render(){
    const columns = [{
      title: "摄像头编号",
      dataIndex: 'deviceSerial',
      key: 'deviceSerial',
    },
      {
        title:"所属区域",
        dataIndex: 'testFieldName',
        key: 'testFieldName'
      },
      {
        title:"位置",
        dataIndex: 'address',
        key: 'address'
      }/* ,{
        title:"在线状态",
        dataIndex: 'isOnline',
        key: 'isOnline',
        render:isOnline=>{
          if(isOnline)
            return (<span style={{color:'#41d824'}}>在线</span>)
          else
            return (<span style={{color:'red'}}>离线</span>)
        }
      } */, {
        title: "经度",
        dataIndex: 'longitude',
        key: 'longitude',
      },
      {
        title: "纬度",
        dataIndex: 'latitude',
        key: 'latitude'
      },
      {
        title:"操作",
        render:(text)=>{
          return (<div><span><Icon type="edit" className={styles.iconSize} onClick={this.editHandle.bind(this,text)}/>&nbsp;&nbsp;</span><span><Icon type="play-circle" className={styles.iconSize} onClick={this.playVideoHandle.bind(this,text)} />&nbsp;&nbsp;</span></div>)
        }
      }

    ];

    return (
      <div>
        <Header title={'车载设备管理'} />
        <div className={styles.pageCon}>
        <div className={styles.searchCon}>
          <div>
            <Select  onChange={this.handleChangeName}  className={styles.selectStyle} placeholder="选择区域" value={this.state.testFieldId}>
              {this.state.nameList.length>0 && this.state.nameList.map((item,index) => (
                <Option key={item.id} value={item.id} >
                  {item.testFieldName}
                </Option>
              ))}
            </Select>

            {/* <Input onChange={this.inputText} className={styles.selectStyle} placeholder="设备编号" value={this.state.cameraId} /> */}

            <Button className={styles.searchBtn} onClick={this.searchCamera}>查询</Button>
            <Button className={styles.resetBtn} onClick={this.clearSearch.bind(this)}>重置</Button>

          </div>
          <div className={styles.viewWay}>
            <span className={styles.viewTitle}>查看方式</span>
            <span className={this.state.viewType ===1?styles.listViewSelect:styles.listViewDisabled} onClick={()=>this.changeListView(1)}>列表视角</span>
            <span className={this.state.viewType ===0?styles.mapViewSelect:styles.mapViewDisabled} onClick={()=>this.changeListView(0)}>图片视角</span>
          </div>


        </div>

        <div className={styles.dataCon}>

          {this.state.viewType === 1 &&<div className={styles.dataTable} >
            <Table rowKey={record=>faker.name.findName()} dataSource={this.state.cameraList} columns={columns}  />
          </div>}



        </div>
        {this.state.viewType === 0 && this.state.cameraList.length>0 && <div ><ImageView getCameraInfoList={this.getCameraInfoList} playVideoHandle={this.playVideoHandle} cameraList={this.state.cameraList}></ImageView></div>}

        <Modal
          title="编辑摄像机"
          visible={this.state.operaDialogVisible}
          width={this.state.dialogWidth}
          footer={null}
          onCancel={()=>{this.setState({operaDialogVisible:false,dialogWidth:'35%'})}}
          destroyOnClose={true}
        >
          <CameraInfoDialog changeDialogWidth={this.changeDialogWidth} changModalVisible={this.changModalVisible} operaType={this.state.operaType} cameraInfo={this.state.cameraInfo}></CameraInfoDialog>
        </Modal>

        <Modal
          visible={this.state.showVideoDialog}
          footer={null}
          destroyOnClose={true}
          onCancel={()=>{this.setState({showVideoDialog:false})}}
        >
          <div style={{height:'15px'}}></div>
          {this.state.videoUrl && <VideoComponent url={this.state.videoUrl}></VideoComponent>}
          {/*{this.state.isOnline && this.state.videoUrl ? <VideoComponent url={this.state.videoUrl}></VideoComponent>:<div style={{textAlign:'center'}}>暂无视频</div>}*/}
        </Modal>
        </div>
      </div>
    )
  }
}
export default VehicleMountedManagement