import React from 'react'
import {Form,Row,Col,Input,Select,DatePicker,Button,message} from 'antd'
import styles from './style.module.css'
import faker from "faker";
import icon1 from './img/1.png'
import icon2 from './img/2.png'
import icon3 from './img/3.png'



class ViewBackgroundVehicle extends React.Component{
  state={
    vehicleInformation:null
  }

  componentDidMount(){

    const {testVehicleInfo} = this.props
    console.log(testVehicleInfo)
    this.setState({vehicleInformation:testVehicleInfo})

  }

  componentWillReceiveProps(nextProps){
    if(this.props.testVehicleInfo !== nextProps.testVehicleInfo){
      this.setState({vehicleInformation:nextProps.testVehicleInfo})
    }

  }




  render(){
    return (
      <div>
        {/* <div className={styles.editSceneBtnCon}>
          <Button onClick={()=>{this.props.editChange(true,this.props.testVehicleInfo)}} className={styles.editPlanBtn}>编辑</Button>
        </div> */}
          <div className={styles.planItemCon}>
            <div className={styles.planTitleCon}>
              <img src={icon1}/><span className={styles.planTitle}>基础信息</span>
            </div>
            <Row gutter={10}>
              <Col span={12} className={styles.viewColSpace}>
                <span className={styles.txtBold}>商标:</span><span>{this.state.vehicleInformation?this.state.vehicleInformation.brand:'--'}</span>
              </Col>
              <Col span={12} className={styles.viewColSpace}>
                <span className={styles.txtBold}>型号:</span><span>{this.state.vehicleInformation?this.state.vehicleInformation.model:'--'}</span>
              </Col>
              <Col span={24} className={styles.viewColSpace}>
                <span className={styles.txtBold}>VIN码(车辆识别代码):</span><span>{this.state.vehicleInformation?this.state.vehicleInformation.vinNumber:'--'}</span>
              </Col>

              <Col span={12} className={styles.viewColSpace}>
                <span className={styles.txtBold}>车牌号码:</span><span>{this.state.vehicleInformation?this.state.vehicleInformation.licensePlate:'--'}</span>
              </Col>
              <Col span={12} className={styles.viewColSpace}>
                <span className={styles.txtBold}>车辆类型:</span><span>
                {
                  this.state.vehicleInformation && this.state.vehicleInformation.type === 0 &&<span>乘用车</span>
                }
                {
                  this.state.vehicleInformation && this.state.vehicleInformation.type === 1 &&<span>商用车</span>
                }


                  </span>
              </Col>
            </Row>
          </div>

          <div className={styles.planItemCon}>
            <div className={styles.planTitleCon}>
              <img src={icon2}/><span className={styles.planTitle}>车身设备</span>
            </div>
            <Row gutter={10} >
              <Col span={24} className={styles.viewColSpace}>
                <span className={styles.txtBold}>OBE:</span><span>{this.state.vehicleInformation?(this.state.vehicleInformation.obeId?this.state.vehicleInformation.obeId:'--'):'--'}</span>
              </Col>
              <Col span={24} className={styles.viewColSpace}>
                <span className={styles.txtBold}>车载车内摄像头:</span><span>{this.state.vehicleInformation?(this.state.vehicleInformation.inCameras?this.state.vehicleInformation.inCameras:'--'):'--'}</span>
              </Col>
              <Col span={24} className={styles.viewColSpace}>
                <span className={styles.txtBold}>车载车外摄像头:</span><span>{this.state.vehicleInformation?(this.state.vehicleInformation.outCameras?this.state.vehicleInformation.outCameras:'--'):'--'}</span>
              </Col>


            </Row>
          </div>


    </div>)
  }

}

export default ViewBackgroundVehicle