import React from 'react'
import {Table,Form,Select,Button,Input,Row,Col,Checkbox,Modal,message} from 'antd'
import { withRouter } from "react-router-dom";
import styles from './style.module.css'
import BackgroundVehicleDialog from './backgroundVehicleDialog'
import ViewBackgroundVehicle from './viewBackgroundVehicle'
import {VTAPI} from "../../../api/VTAPI"
import searchIcon from './img/search.png'
import deleteIcon from './img/delete.png'
import axios from "axios/index";
import { format } from '../../../utils/formatTime'
import Header from '../../public/component/header'
import { VUTAPI } from "../../../api/VUTAPI";
import { sceneInfo } from "../../../api/sceneInfo";

const { Option } = Select;
const confirm = Modal.confirm;

@withRouter
class BackgroundVehicleManagement extends React.Component{
  state={
    VUTList:[],
    operaType:'',
    viewDialogVisible:false,
    updateTestVehicleInfo:null,
    addOrUpdateTitle:'创建背景车辆',
    operaDialogVisible:false,
    showExpandedColumn:false,
    car1:0
  }

  getVTInfoList=(data)=>{
    VTAPI.getVTList(data).then(res=>{
      if(res.data.code === 200){
        this.setState({VUTList:res.data.data.list})
      }
    })
  }

  searchBackgroundVehicle=()=>{
    this.props.form.validateFields((err, values) => {
        let data ={}
        if(values.id){
          data.id=values.id
        }
        if(values.licensePlate){
          data.licensePlate=values.licensePlate
        }
        this.getVTInfoList(data)

    })


  }

  componentDidMount (){
    this.getVTInfoList({})
    this.getTotalCarStatitus()
  }

  addTestVehicleHandle=()=>{
    this.setState({addOrUpdateTitle:'创建背景车辆',operaDialogVisible:true,operaType:'create'})
  }

  changModalVisible=(flag)=>{
    this.getVTInfoList({})
    this.getTotalCarStatitus()
    this.setState({operaDialogVisible:flag})
  }

  viewTestVehicleInfo=(text)=>{
    let data={id:text.id}
    this.getBackgroundVehicleInfoById(data)
    this.setState({viewDialogVisible:true})
  }

  getBackgroundVehicleInfoById=(data)=>{
    let that = this
    VTAPI.getVTById(data).then(res=>{
      if(res.data.code === 200){
        that.setState({updateTestVehicleInfo:res.data.data})
      }
    })
  }

  deleteTestVehicle=(text)=>{
    let that = this
    confirm({
      title: `确定要删除${text.id}这条记录吗?`,
      okText:'确定',
      cancelText:'取消',
      onOk() {
        const data ={id:text.id}
        VTAPI.deleteVT(data).then(res=>{
          if(res.data.code === 200){
            message.success("删除成功!")
            that.getTotalCarStatitus()
            that.getVTInfoList({})
          }else{
            message.error("删除失败!")
          }
        })
      },
      onCancel() {

      },
    });
  }

  editChange=(flag,testVehicleInfo)=>{
    this.setState({addOrUpdateTitle:'修改背景车辆',operaDialogVisible:flag,updateTestVehicleInfo:testVehicleInfo,operaType:'update'})
  }


  exportVTHandle=()=>{
    axios.post('/testSite/vehicle/export',{},{responseType:'blob'}).then(res=>{
      const content = res.data
      const blob = new Blob([content])// 构造一个blob对象来处理数据
      const fileName = '背景车辆信息.xlsx'

      if ('download' in document.createElement('a')) { // 支持a标签download的浏览器
        const link = document.createElement('a')// 创建a标签
        link.download = fileName// a标签添加属性
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        document.body.appendChild(link)
        link.click()// 执行下载
        URL.revokeObjectURL(link.href) // 释放url
        document.body.removeChild(link)// 释放标签
      } else { // 其他浏览器
        navigator.msSaveBlob(blob, fileName)
      }
    })

  }

  onChange=(e)=> {
    this.setState({showExpandedColumn:e.target.checked})
  }

  getTotalCarStatitus=()=>{
    let that = this
    VTAPI.statisticAll({}).then(res=>{
      if(res.data.code === 200){
        that.setState({car1:res.data.data})
      }
    })
  }

  clearSeach=()=>{
    this.props.form.setFieldsValue({"id":null,"licensePlate":null})
  }


  render(){
    let columns =[{
      title: "VT编号",
      dataIndex: 'id',
      key: 'id',
    },
      {
        title:"VIN码",
        dataIndex: 'vinNumber',
        key: 'vinNumber'
      },
      {
        title:"车牌号码",
        dataIndex: 'licensePlate',
        key: 'licensePlate'
      },
      {
        title:"商标",
        dataIndex: 'brand',
        key: 'brand'
      }, {
        title: "车辆类型",
        dataIndex: 'type',
        key: 'type',
        render:type=>{
          if(type === 0)
            return '乘用车'
          else if(type === 1)
            return '商用车'
          else if(type === 2)
            return '无人小巴'
          else if(type === 3)
            return '无人大巴'
          else if(type === 4)
            return '无人卡车'
          else if(type === 5)
            return '无人驾驶车辆'
        }
      },
      {
        title: "绑定设备数量",
        dataIndex: 'bindDevNum',
        key: 'bindDevNum'
      }]

    if(this.state.showExpandedColumn)
    {
      columns =columns.concat([{
        title:"创建人",
        dataIndex: 'creater',
        key: 'createPeople',
      }, {
        title: "创建时间",
        dataIndex: 'createTime',
        key: 'createTime',
        render: createTime => {
          if(createTime)
            return format.formatTime(createTime)
          else
            return '--'
        }
      },
        {
          title:"最后修改人",
          dataIndex: 'modifier',
          key: 'modifier',
        }, {
          title: "最后修改时间",
          dataIndex: 'modifyTime',
          key: 'modifyTime',
          render: modifyTime => {
            if(modifyTime)
              return format.formatTime(modifyTime)
            else
              return '--'

          }
        }])
    }

    columns = columns.concat([{
      title:"操作",
      render:(text)=>{
        return (<div><span ><img src={searchIcon} alt="" title="查看" className={styles.operaIcon} onClick={this.viewTestVehicleInfo.bind(this,text)} /></span>&nbsp;&nbsp;<span ><img src={deleteIcon} alt="" title="删除" onClick={this.deleteTestVehicle.bind(this,text)} className={styles.operaIcon} /></span></div>)
      }
    }])

    const { getFieldDecorator } = this.props.form;

    return (
      <div>
          <Header title="背景车辆管理" />
          <div className={styles.pageCon}>
            <div className={styles.searchCon}>
              <div>
                <Form style={{display:'inline-block'}} layout='inline'>
                  <Form.Item >
                    {getFieldDecorator('id', {
                      rules: [{
                        required: false,
                        message: '请输入背景车辆编号',
                      }],
                    })(
                      <Input placeholder="请输入背景车辆编号" className={styles.selectStyle} />
                    )}
                  </Form.Item>

                  <Form.Item >
                    {getFieldDecorator('licensePlate', {
                      rules: [{
                        required: false,
                        message: '请输入车牌号码',
                      }],
                    })(
                      <Input placeholder="请输入车牌号码" className={styles.selectStyle} />
                    )}
                  </Form.Item>





                  <Button className={styles.searchBtn} onClick={this.searchBackgroundVehicle}>查询</Button>
                  <Button className={styles.resetBtn} onClick={this.clearSeach}>重置</Button>
                </Form>
              </div>


            </div>

            <div className={styles.dataCon}>
              <div className={styles.operaCon}>
                <Row>
                  <Col md={{ span: 24 }} xl={{ span: 12 }}>
                    背景车辆总数<span className={styles.projectNum}>{this.state.car1}</span>
                  </Col>
                  <Col md={{ span: 24 }} xl={{ span: 12 }} style={{textAlign:'right'}}>
                    <Checkbox onChange={this.onChange} className={styles.showRecord}>显示操作记录</Checkbox>
                    <Button onClick={this.exportVTHandle}>导出Excel</Button>
                    <Button icon="plus" className={styles.btnStyle} onClick={this.addTestVehicleHandle}>新增背景车辆</Button>
                  </Col>
                </Row>

              </div>
              <div className={styles.dataTable} >
                <Table rowKey={record=>record.id} dataSource={this.state.VUTList} columns={columns}  />
              </div>

            </div>

            <Modal
              title={<div><span>查看背景车辆</span><span>  <Button className="editBtn" onClick={()=>{this.editChange(true,this.state.updateTestVehicleInfo)}}>编辑</Button></span></div>}
              visible={this.state.viewDialogVisible}
              footer={null}
              destroyOnClose={true}
              onCancel={()=>{this.setState({viewDialogVisible:false,updatePlanInfo:null})}}
              zIndex="10"
            >
              {this.state.updateTestVehicleInfo && <ViewBackgroundVehicle testVehicleInfo={this.state.updateTestVehicleInfo} editChange={this.editChange}></ViewBackgroundVehicle>}
            </Modal>

            <Modal
              bodyStyle={{paddingTop:'0px'}}
              title={this.state.addOrUpdateTitle}
              visible={this.state.operaDialogVisible}
              footer={null}
              width="35%"
              destroyOnClose={true}
              onCancel={()=>{this.setState({operaDialogVisible:false})}}

            >
              <BackgroundVehicleDialog operaType={this.state.operaType}  testVehicleInfo={this.state.updateTestVehicleInfo} getBackgroundVehicleInfoById={this.getBackgroundVehicleInfoById} changModalVisible={this.changModalVisible} ></BackgroundVehicleDialog>


            </Modal>

          </div>
      </div>)
  }
}

const WrapBackgroundVehicleManagement = Form.create()(BackgroundVehicleManagement)
export default WrapBackgroundVehicleManagement